import { Avatar, Grid, InputBase, makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import CancelIcon from "@material-ui/icons/Cancel";
import CloseIcon from "@material-ui/icons/Close";
import CropOriginalIcon from "@material-ui/icons/CropOriginal";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import ImageUploading from "react-images-uploading";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  createPost,
  refreshData
} from "../../../api/NoticeBoard/action";
import {
  collegeDetailSelectorFn,
  collegeIdFn,
  collegeListSelectorFn,
  isCollegeGroupFn,
  isEducationGroupFn,
  userRolesActionsFn
} from "../../../api/SignIn/selector";
import Attachfile from "../../../components/Cards/Feed/Attachfile";
import FeedEditor from "../../../pages/CampusFeed/FeedEditor";
import { groupViewCheck } from "../../../utility/groupViewCheck";
import CustomButtonCircularProgress from "../../CircularProgressBar/index2";
import OnlyCollegeList from "../../GroupCollegeList/onlyCollegeList";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles((theme) => ({
  root1: {
    borderRadius: "8px",
    border: ".5px solid #707070",
    boxShadow: "none",
  },
  post: {
    boxShadow: "none",
    borderRadius: "8px",
    paddingLeft: "40px",
    paddingRight: "40px",
  },
  paper: {
    width: "550px",
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ textAlign: "center" }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function AddFeedDialog({ open, handleClickOpen, handleClose, state,setNext, setCount }) {
  const classes = useStyles();

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [editor, setEditor] = useState("");
  const [file, setFile] = useState(null);
  const [file1, setFile1] = useState(null);
  const alert = useAlert();
  const [editImages, seteditImages] = useState([]);
  const [editImages1, seteditImages1] = useState([]);
  const hiddenFileInput = React.useRef(null);
  const [loadAgain, setLoadAgain] = useState(false);
  const [publishing, setPublishing] = useState(false);
  const [editData, setEditData] = useState(null);
  const [loader, setLoader] = useState(true);
  const [feed_id, setFeedid] = useState("");
  // const [next, setNext] = useState("");
  // const [count, setCount] = useState();
  const [deletedImages, setDeletedImages] = useState([]);
  const [deletedDoc, setDeleteddoc] = useState(false);
  const [images, setImages] = React.useState([]);
  const [posting, setPosting] = useState(false);
  const maxNumber = 3;
  const collegeDetail = useSelector(collegeDetailSelectorFn);
  const isEducationGroup = useSelector(isEducationGroupFn);
  const isCollegeGroup = useSelector(isCollegeGroupFn);
  const collegeId = useSelector(collegeIdFn);
  const mainCollegeList = useSelector(collegeListSelectorFn);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleFile = (event) => {
    setFile(event.target.files[0]);
  };

  const [companyDetails, setCompanyDetails] = useState({
    college_list: [],
  });

  const [companyDetailsError, setCompanyDetailsError] = useState({
    college_list: null,
  });

  useEffect(() => {
    if (loadAgain) {
      setEditor("");
      setImages([]);
      seteditImages([]);
      seteditImages1([]);
      setFile1(null);
      setFile(null);
      handleClose();
      refreshData(setLoader, alert, history, location, setLoadAgain, setNext, setCount, dispatch);
    }
  }, [loadAgain]);

  useEffect(() => {
    if (state) {
      setEditor(state?.content + String(state?.tags));
      seteditImages(state?.images);
      seteditImages1(state?.images);
      if (state?.documents.length >= 1) {
        setFile1(state?.documents);
      }
      setFeedid(state?.feed_id);
      handleClickOpen();
    }
  }, [state]);

  const publish = () => {
    if (editor !== "") {
      const hashtag = editor.match(/#[a-z]+/gi);
      const postText = editor;
      const regexp = new RegExp("#([^\\s]*)", "g");
      const content = postText.replace(regexp, "");
      let formData = new FormData();
      formData.append("content", content);
      hashtag !== null && formData.append("tags", hashtag);
      file !== null && formData.append("document_1", file);
      images[0] && formData.append("images_1", images[0].file);
      images[1] && formData.append("images_2", images[1].file);
      images[2] && formData.append("images_3", images[2].file);
      formData.append(
        "poster_type",
        groupViewCheck(isEducationGroup, isCollegeGroup, collegeId) ? "EDUGROUP" : "COLLEGE"
      );
      formData.append(
        "poster_id",
        groupViewCheck(isEducationGroup, isCollegeGroup, collegeId)
          ? collegeDetail?.education_group_id
          : collegeId
      );
      formData.append(
        "selected_college_ids",
        groupViewCheck(isEducationGroup, isCollegeGroup, collegeId)
          ? companyDetails?.college_list?.map((item) => item.id)
          : [collegeId]
      );

      dispatch(
        createPost(setPublishing, formData, alert, history, location, setLoadAgain, setPosting, dialogClose)
      );
    } else {
      alert.error("Textfield can not be empty");
    }
  };

  const editPost = () => {
    if (editor !== "") {
      const hashtag = editor.match(/#[a-z]+/gi);
      const postText = editor;
      const regexp = new RegExp("#([^\\s]*)", "g");
      const content = postText.replace(regexp, "");

      let formData = new FormData();
      formData.append("content", content);
      formData.append("feed_id", feed_id);
      hashtag !== null && formData.append("tags", hashtag);
      file !== null && formData.append("document_1", file);
      images[0] && formData.append("images_1", images[0].file);
      images[1] && formData.append("images_2", images[1].file);
      images[2] && formData.append("images_3", images[2].file);
      deletedImages.length >= 1 && formData.append("deleted_images", JSON.stringify(deletedImages));
      deletedDoc === true && formData.append("deleted_documents", JSON.stringify([0]));
      formData.append(
        "poster_type",
        groupViewCheck(isEducationGroup, isCollegeGroup, collegeId) ? "EDUGROUP" : "COLLEGE"
      );
      formData.append(
        "poster_id",
        groupViewCheck(isEducationGroup, isCollegeGroup, collegeId)
          ? collegeDetail?.education_group_id
          : collegeId
      );
      formData.append(
        "selected_college_ids",
        groupViewCheck(isEducationGroup, isCollegeGroup, collegeId)
          ? companyDetails?.college_list?.map((item) => item.id)
          : [collegeId]
      );
      dispatch(
        createPost(setPublishing, formData, alert, history, location, setLoadAgain, setPosting, dialogClose)
      );
      
    } else {
      alert.error("Nothing to Publish");
    }
  };

  const onChange = (imageList, addUpdateIndex) => {
    if (imageList.length <= maxNumber) {
      setImages(imageList);
    } else {
      alert.error("Max 3 images allowed");
    }
  };

  const removeImages = (item) => {
    const result = editImages1.findIndex((element) => element.url === item.url);
    const filter = editImages.filter((element) => element.url !== item.url);

    const array2 = deletedImages;
    array2.push(result);
    setDeletedImages(array2);
    seteditImages(filter);
  };
  const userRolesPermission = useSelector(userRolesActionsFn);

  useEffect(() => {
    if (!open) {
      setImages([]);
      seteditImages([]);
    }
  }, [open]);

  const dialogClose = () => {
    if(!posting){
    seteditImages([]);
    seteditImages1([]);
    setFile(null);
    setFile1(null);
    setEditor("");
    setDeletedImages([]);
    setDeleteddoc(null);
    setFeedid(null);
    handleClose();
    }
  }

  const [collegeList, setCollegeList] = useState([]);

  return (
    <div>
      {userRolesPermission?.some((item) => item.access_control_key === "create_feed") && (
        <Button
          variant="contained"
          color="primary"
          className={classes.post}
          onClick={handleClickOpen}
        >
          Post
        </Button>
      )}
      <Dialog
        PaperProps={{ classes: { root: classes.root1 } }}
        onClose={dialogClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={dialogClose}>
          Create Feed
        </DialogTitle>
        <DialogContent dividers style={{ paddingBottom: "0px" }}>
          <div className={classes.paper}>
            <div style={{ marginBottom: "15px" }}>
              {" "}
              <Grid container wrap="nowrap" spacing={2}>
                <Grid item>
                  {collegeDetail?.logo ? (
                    <Avatar src={collegeDetail?.logo} />
                  ) : (
                    <Avatar className={classes.large}>{collegeDetail?.name}</Avatar>
                  )}
                </Grid>
                <Grid item xs zeroMinWidth>
                  <Typography noWrap variant="body2" style={{ fontWeight: "bold" }}>
                    {collegeDetail?.name}
                  </Typography>
                  {groupViewCheck(isEducationGroup, isCollegeGroup, collegeId) ? (
                    <div style={{ marginTop: 10 }}>
                      <OnlyCollegeList
                        groupData={companyDetails}
                        setGroupData={setCompanyDetails}
                        groupDataError={companyDetailsError}
                        setGroupDataError={setCompanyDetailsError}
                        mainCollegeList={mainCollegeList}
                        collegeList={collegeList}
                        setCollegeList={setCollegeList}
                      />
                    </div>
                  ) : (
                    <Typography noWrap variant="body2">
                      Campus Wide
                    </Typography>
                  )}
                  {/* <CollegeMenu /> */}
                </Grid>
              </Grid>
            </div>
            <div>
              <FeedEditor
                state={editor}
                setState={setEditor}
                desc={state ? state?.content + String(state?.tags) : ""}
              />
            </div>
          </div>

          <div style={{ marginTop: "60px" }}>
            <ImageUploading
              multiple
              value={images}
              onChange={onChange}
              maxNumber={maxNumber}
              dataURLKey="data_url"
            >
              {({
                imageList,
                onImageUpload,
                onImageRemoveAll,
                onImageUpdate,
                onImageRemove,
                isDragging,
                dragProps,
                errors
              }) => (
                // write your building UI
                <>
                  <div style={{ display: "flex" }}>
                    {imageList.map((image, index) => (
                      <div key={index} className={classes.drop} style={{ display: "flex" }}>
                        <div className={classes.imagedata}>
                          <img
                            className={classes.imgdata}
                            src={image.data_url}
                            alt=""
                            style={{
                              borderRadius: "12px",
                              marginleft: "0px",
                              marginTop: "10px",
                              marginRight: "10px",
                              marginBottom: "10px",
                            }}
                            width="100"
                          />
                        </div>
                        <div>
                          <IconButton
                            aria-label="delete"
                            style={{
                              marginLeft: "-35px",
                              padding: "0px",
                              marginTop: "10px",
                            }}
                            onClick={() => onImageRemove(index)}
                          >
                            <CancelIcon style={{ color: "#000" }} />
                          </IconButton>
                        </div>
                      </div>
                    ))}
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      {editImages.length >= 1
                        ? editImages?.map((image, index) => {
                            return (
                              <div key={index} className={classes.drop}>
                                <div className={classes.imagedata}>
                                  <img
                                    className={classes.imgdata}
                                    src={image.data_url || image?.url}
                                    alt=""
                                    style={{
                                      borderRadius: "12px",
                                      // margin: "10px",
                                      marginleft: "0px",
                                      marginTop: "10px",
                                      marginRight: "10px",
                                      marginBottom: "10px",
                                    }}
                                    width="100"
                                  />
                                </div>
                                <div>
                                  <IconButton
                                    aria-label="delete"
                                    style={{
                                      marginLeft: "65px",
                                      marginTop: "-150px",
                                    }}
                                    onClick={() => removeImages(image)}
                                  >
                                    <CancelIcon style={{ color: "#000" }} />
                                  </IconButton>
                                </div>
                              </div>
                            );
                          })
                        : null}
                    </div>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Button
                      color="primary"
                      startIcon={<CropOriginalIcon />}
                      className={classes.addbtn}
                      onClick={onImageUpload}
                      style={{ display: "flex" }}
                      // {...dragProps}
                    >
                      Upload Image(max. 3 images)
                    </Button>
                    &nbsp;
                    
                      <Button
                        onClick={handleClick}
                        startIcon={<AttachFileIcon />}
                        color="primary"
                        style={{ display: "flex" }}
                        //   className={classes.button}
                      >
                        Attach File(only 1 file)
                      </Button>
                    
                  </div>
                  {errors && <div>
      {errors.maxNumber && <span style={{color: "Red"}}>You can upload maximum 3 images.</span>}
      {errors.acceptType && <span style={{color: "Red"}}>Your selected file type is not allow</span>}
      {errors.maxFileSize && <span style={{color: "Red"}}>Selected file size exceed maxFileSize</span>}
      {errors.resolution && <span style={{color: "Red"}}>Selected file is not match your desired resolution</span>}                 
            </div>
        }
                </>
              )}
            </ImageUploading>
          </div>
          <div
            style={{
              display: "flex",
              overflow: "auto",
              paddingTop: "5px",
            }}
          >
            {file !== null ? (
              <Attachfile message={file?.name} removeAttachmnet={() => setFile(null)} />
            ) : null}
            {file1 !== null ? (
              <Attachfile
                removeAttachmnet={() => {
                  setFile1(null);
                  setDeleteddoc(true);
                }}
                message={`${state?.documents[0]?.file_name ? state?.documents[0]?.file_name : "Attachment." + state?.documents[0].extension}`}
              />
            ) : null}
            {/* <Attachfile /> */}
          </div>
          <div>
            <div style={{ display: "none" }}>
              <input
                type="file"
                ref={hiddenFileInput}
                onChange={handleFile}
                style={{ display: "none" }}
              />
              <InputBase
                className={classes.input}
                placeholder={file ? "File Selected" : "No file selected"}
                style={{ fontSize: "14px" }}
              />
            </div>
            {/* </Paper> */}

            <div
              style={{
                marginleft: "25px",
                marginTop: "10px",
                marginBottom: "5px",
              }}
            ></div>
          </div>
        </DialogContent>
        <DialogActions>
          <div
            style={{
              width: "100%",
              display: "flex",
              padding: "10px",
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={6} style={{ display: "grid" }}>
                <Button
                  onClick={dialogClose}
                  variant="outlined"
                  color="primary"
                  style={{
                    borderRadius: "8px",
                    paddingLeft: "50px",
                    paddingRight: "50px",
                    boxShadow: "none",
                    minWidth: "170px",
                    height: "48px",
                    display: "flex",
                  }}
                >
                  Discard
                </Button>
              </Grid>
              <Grid item xs={6} style={{ display: "grid" }}>
                <Button
                  onClick={() => {
                    feed_id ? editPost() : publish();
                  }}
                  variant="contained"
                  color="primary"
                  style={{
                    borderRadius: "8px",
                    paddingLeft: "50px",
                    paddingRight: "50px",
                    boxShadow: "none",
                    minWidth: "170px",
                    height: "48px",
                    display: "flex",
                  }}
                >
                  {posting ? <CustomButtonCircularProgress /> : "Post"}
                </Button>
              </Grid>
            </Grid>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}
