import React, { useEffect, useState } from "react";
import ChooseCollegeJob from "./ChooseCollegeJob";
import JobPostFormPreviewDialog from "./JobPostFormPreviewDialog";

function AllChooseCollegeJob({
  formsData,
  setStep,
  step,
  selectedForm,
  setSelectedForm,
  jobType,
}) {
  const [collegeDetail, setCollegeDetail] = useState({
    name: null,
    id: null,
  });

  const [openJobFormPreview, setOpenJobFormPreview] = useState(false);

  const handleClose = () => {
    setOpenJobFormPreview(false);
  };
  const handleOpen = () => {
    setOpenJobFormPreview(true);
  };

  return (
    <div>
      {formsData.map((formDetails) => (
        <ChooseCollegeJob
          formDetails={formDetails}
          setStep={setStep}
          step={step}
          setSelectedForm={setSelectedForm}
          selectedForm={selectedForm}
          handleOpen={handleOpen}
          setCollegeDetail={setCollegeDetail}
        />
      ))}
      {openJobFormPreview && (
        <JobPostFormPreviewDialog
          open={openJobFormPreview}
          handleClose={handleClose}
          jobType={jobType}
          collegeDetail={collegeDetail}
        />
      )}
    </div>
  );
}

export default AllChooseCollegeJob;
