import {
  Box,
  Button,
  Grid,
  LinearProgress,
  makeStyles,
  Tab,
  Tabs,
  Typography,
  withStyles,
} from "@material-ui/core";
import axios from "axios";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { BillingDetails } from "../../api/Billing/action";
import {
  CreditInfoGetAPI,
  RechargeHistoryGetApi,
  TransactionistoryGetApi,
} from "../../api/Credits/action";
import {
  collegeIdFn,
  collegeUserDetailSelectorFn,
} from "../../api/SignIn/selector";
import RechargeSkelton from "../../components/Cards/Recharge/RechargeSkelton";
import AddCreditsDialog from "../../components/DialogSection/Recharge/AddCreditsDialog";
import PaymentDialog from "../../components/DialogSection/Recharge/PaymentDialog";
import PaymentProcessing from "../../components/DialogSection/Recharge/PaymentInProcessDialog";
import Cal from "../../components/Header/ReportTab/Cal";
import AllHistory from "../../components/Table/RechargeTable/AllHistory";
import PaymentHistoryTable from "../../components/Table/RechargeTable/PaymentHistoryTable";
import { BackendBaseApi } from "../../constants/constants";
// import {CREDIT_INFO} from "../../constants/svg";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={0}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const RechargeVactor = (
  <svg
    width="376"
    height="191"
    viewBox="0 0 376 191"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M372.833 157.399C385.435 192.372 356.822 228.379 319.906 224.001L-108.655 173.184L226.534 -248.61L372.833 157.399Z"
      fill="url(#paint0_linear_8197_10252)"
      fill-opacity="0.2"
    />
    <defs>
      <linearGradient
        id="paint0_linear_8197_10252"
        x1="400.266"
        y1="233.53"
        x2="-54.8363"
        y2="-128.127"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="0.750894" stop-color="white" stop-opacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

const token = (
  <svg
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 7.27397C4.02891 7.27397 0 5.64465 0 3.63698C0 1.62931 4.02891 0 9 0C13.9711 0 18 1.62931 18 3.63698C18 5.64465 13.9711 7.27397 9 7.27397ZM9 13.089C12.4348 13.089 15.4723 12.288 17.3883 11.0507C17.7785 11.4597 18 11.8994 18 12.363C18 14.3707 13.9711 16 9 16C4.02891 16 0 14.3707 0 12.363C0 11.8994 0.221483 11.4597 0.611717 11.0541C2.52773 12.288 5.56523 13.089 9 13.089ZM9 8.72603C12.4348 8.72603 15.4723 7.92501 17.3883 6.68769C17.7785 7.09672 18 7.53643 18 8C18 10.0077 13.9711 11.637 9 11.637C4.02891 11.637 0 10.0077 0 8C0 7.53643 0.221483 7.09672 0.611717 6.69109C2.52773 7.92501 5.56523 8.72603 9 8.72603Z"
      fill="white"
    />
  </svg>
);

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#029E73",
  },
}))(LinearProgress);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    borderRadius: "8px",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    padding: theme.spacing(2),
    border: "1px solid #e2e6ea",
    margin: "10px 10px 0px 0px",
  },
  button: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
    backgroundColor: theme.palette.primary.secondary,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.palette.primary.secondary,
    },
  },
  column: {
    marginTop: 5,
    marginBottom: 5,
  },
}));

function stringToBoolean(str) {
  return str ? str.toLowerCase() === "true" : false;
}

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

export default function NewBilling() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const alert = useAlert();
  const collegeId = useSelector(collegeIdFn);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  const collegeUserDetail = useSelector(collegeUserDetailSelectorFn);

  const searchParams = new URLSearchParams(location.search);
  const recharge = stringToBoolean(searchParams.get("recharge"));

  const [billingLoader, setBillingLoader] = useState(true);
  useEffect(() => {
    if (collegeId)
      dispatch(
        BillingDetails(alert, history, location, collegeId, setBillingLoader)
      );
  }, [collegeId]);

  const billing = useSelector((state) => state?.billingData?.billing);

  const [addCredit, setAddCredit] = React.useState(recharge);
  const [priceOverview, setPriceOverview] = useState(null);
  const [checkPaymentLoading, setCheckPaymentLoading] = useState(false);
  const [paymentFailedFlag, setPaymentFailedFlag] = useState(false);
  const [paymentFailedMessage, setPaymentFailedMessage] = useState(null);
  const [paymentCancelledMessage, setPaymentCancelledMessage] = useState(null);

  const handleClickOpenCredit = () => {
    setAddCredit(true);
  };

  const handleCloseCredit = () => {
    setAddCredit(false);
  };

  const [paymentInProcess, setPaymentInProcess] = useState(false);
  const [paymentSuccessful, setPaymentSuccessful] = useState(false);
  const [paymentFailed, setPaymentFailed] = useState(false);
  const [paymentPending, setPaymentPending] = useState(false);
  const [paymentStatusDetails, setPaymentStatusDetails] = useState(null);
  const [reloadOnPaymentSuccess, setReloadOnPaymentSuccess] = useState(false);

  const [paymentHistory, setPaymentHistory] = useState(false);

  const creatOrder = async (orderSummary) => {
    const createOrderNew = (orderSummary) => {
      if (true) {
        const body = {
          currency: "INR",
          request_cost: orderSummary[0].value,
          sgst: orderSummary[1].value,
          gst: orderSummary[2].value,
          college_id: collegeId,
        };
        axios
          .post(
            `${BackendBaseApi.PRAVESH}api/saas/college/wallet/recharge/`,
            body,
            { withCredentials: true }
          )
          .then((res) => {
            setLoading(false);
            if (res?.data?.success) {
              setPriceOverview(res?.data?.data);
              setAddCredit(false);
            } else {
              alert.error(res?.data?.error);
            }
          })
          .catch((err) => {
            alert.error("something went wrong");
            setLoading(false);
          });
      }
    };
    createOrderNew(orderSummary);
  };

  useEffect(() => {
    if (priceOverview?.razorpay_data) {
      handlePayNow();
    }
  }, [priceOverview]);

  const handlePayNow = async () => {
    displayRazorpay(priceOverview?.razorpay_data);
  };

  useEffect(() => {
    if (!paymentFailedFlag && paymentFailedMessage) {
      alert.error(paymentFailedMessage);
    }
  }, [paymentFailedFlag, paymentFailedMessage]);

  useEffect(() => {
    if (!paymentFailedMessage && paymentCancelledMessage) {
      alert.error(paymentCancelledMessage);
    }
  }, [paymentFailedMessage, paymentCancelledMessage]);

  async function displayRazorpay(data) {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert.error("Razorpay SDK failed to load. Are you online?");
      return;
    }
    const options = {
      key: data?.RAZORPAY_KEY,
      currency: data?.currency,
      amount: data?.amount,
      order_id: data?.id,
      name: "GetWork",
      description: "Thank you!",
      image:
        "https://s3.ap-south-1.amazonaws.com/getwork-store/prod/mobile/black_logo_payment_page",

      handler: function (response) {
        setPaymentInProcess(true);
        const dataSuccess = {
          razorpay_order_id: response?.razorpay_order_id,
          razorpay_payment_id: response?.razorpay_payment_id,
          razorpay_signature: response?.razorpay_signature,
          getwork_order_id: data?.getwork_order_id,
          college_id: collegeId,
        };
        checkPayment(dataSuccess);
      },
      modal: {
        ondismiss: function () {
          const dataCancelled = {
            code: "PAYMENT_CANCELLED",
            description: "payment cancelled",
            getwork_order_id: data?.getwork_order_id,
            college_id: collegeId,
          };
          if (!paymentFailedFlag) {
            checkPayment(dataCancelled);
          }
          setPaymentFailedFlag(false);
          setPaymentFailed(true);
        },
      },
      prefill: {
        name: collegeUserDetail?.name,
        email: collegeUserDetail?.email,
        contact: collegeUserDetail?.phone,
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.on("payment.failed", function (response) {
      const dataFailed = {
        code: "PAYMENT_FAILED", //response?.error?.code,
        description: response?.error?.description,
        getwork_order_id: data?.getwork_order_id,
        college_id: collegeId,
      };
      setPaymentInProcess(true);
      checkPayment(dataFailed);
    });

    paymentObject.open();
  }

  // const checkPayment = async (data) => {
  //   let res = null;
  //   setCheckPaymentLoading(true);
  //   try {
  //     res = await axios.post(
  //       `${BackendBaseApi.PRAVESH}api/saas/college/wallet/pay/status/`,
  //       data,
  //       {
  //         withCredentials: true,
  //       }
  //     );
  //     if (res?.data?.success) {
  //       if (res?.data?.data?.order_status === "PENDING") {
  //         setPaymentInProcess(false);
  //         alert.success(res?.data?.data?.message);
  //         // setPaymentPendingOpen(true);
  //       } else if (res?.data?.data?.order_status === "PAYMENT_INITIATED") {
  //         setPaymentInProcess(false);
  //         setPaymentSuccessful(true);
  //         alert.success(res?.data?.data?.message);
  //       } else if (res?.data?.data?.order_status === "PAYMENT_SUCCESS") {
  //         setPaymentInProcess(false);
  //         setPaymentSuccessful(true);
  //         alert.success(res?.data?.data?.message);
  //       }
  //     } else {
  //       if (res?.data?.data?.order_status === "PAYMENT_FAILED") {
  //         setPaymentInProcess(false);
  //         setPaymentFailedFlag(true);
  //         setPaymentFailedMessage(res?.data?.data?.message);
  //       } else if (res?.data?.data?.order_status === "PAYMENT_CANCELLED") {
  //         setPaymentInProcess(false);
  //         setPaymentFailed(true);
  //         setPaymentCancelledMessage(res?.data?.data?.message);
  //       } else {
  //         setPaymentInProcess(false);
  //         setPaymentFailed(true);
  //         alert.error(res?.data?.data?.message);
  //       }
  //     }
  //     setCheckPaymentLoading(false);
  //   } catch (err) {
  //     if (err?.response?.status === 401) {
  //       alert.error("Please Try Again");
  //     }
  //     setPaymentInProcess(false);
  //     setPaymentFailed(true);

  //     setCheckPaymentLoading(false);
  //   }
  // };

  //dashboard

  const checkPayment = async (data, retries = 3) => {
    setCheckPaymentLoading(true);

    const pollPaymentStatus = async (retryCount) => {
      try {
        const res = await axios.post(
          `${BackendBaseApi.PRAVESH}api/saas/college/wallet/pay/status/`,
          data,
          {
            withCredentials: true,
          }
        );

        if (res?.data?.success) {
          setPaymentStatusDetails(res?.data?.data);
          const orderStatus = res?.data?.data?.order_status;

          if (
            orderStatus === "PAYMENT_INITIATED" ||
            orderStatus === "PENDING"
          ) {
            if (retryCount > 1) {
              setTimeout(() => pollPaymentStatus(retryCount - 1), 2000);
            } else {
              setPaymentInProcess(false);
              setPaymentPending(true);
              alert.error(res?.data?.data?.message);
              setReloadOnPaymentSuccess(true);
              setPage(0);
              setPage2(0);
              setTransactionData([]);
              setRechargeData([]);
              setCheckPaymentLoading(false);
            }
          } else if (orderStatus === "PAYMENT_SUCCESS") {
            setPaymentInProcess(false);
            setPaymentSuccessful(true);
            setReloadOnPaymentSuccess(true);
            setPage(0);
            setPage2(0);
            setTransactionData([]);
            setRechargeData([]);
            alert.success(res?.data?.data?.message);
          } else {
            setPaymentInProcess(false);
            setPaymentFailed(true);

            alert.error(res?.data?.data?.message);
          }
          setCheckPaymentLoading(false);
        } else {
          setPaymentStatusDetails(res?.data?.data);

          const orderStatus = res?.data?.data?.order_status;

          if (orderStatus === "PAYMENT_FAILED") {
            setPaymentInProcess(false);
            setPaymentFailedFlag(true);
            setReloadOnPaymentSuccess(true);
            setPage(0);
            setPage2(0);
            setTransactionData([]);
            setRechargeData([]);
            setPaymentFailedMessage(res?.data?.data?.message);
          } else if (orderStatus === "PAYMENT_CANCELLED") {
            setPaymentInProcess(false);
            setPaymentFailed(true);

            setPaymentCancelledMessage(res?.data?.data?.message);
          } else {
            setPaymentInProcess(false);
            setPaymentFailed(true);

            alert.error(res?.data?.data?.message);
          }
          setCheckPaymentLoading(false);
        }
      } catch (err) {
        if (err?.response?.status === 401) {
          alert.error("Please Try Again");
        }
        setPaymentInProcess(false);
        setPaymentFailed(true);
        setCheckPaymentLoading(false);
      }
    };

    await pollPaymentStatus(retries);
  };

  useEffect(() => {
    if (reloadOnPaymentSuccess) {
    }
  }, [reloadOnPaymentSuccess]);

  const [loading, setLoading] = useState(true);

  const [date, setDate] = useState({
    startDate: moment(new Date().setDate(new Date().getDate() - 7)).format(
      "yyyy-MM-DD"
    ),
    endDate: moment(new Date().setDate(new Date().getDate())).format(
      "yyyy-MM-DD"
    ),
  });

  const [transactionData, setTransactionData] = useState([]);

  const [totalCount, setTotalCount] = useState(0);
  const [totalCount2, setTotalCount2] = useState(0);
  const [rechargeData, setRechargeData] = useState([]);

  const creditData = useSelector((state) => state?.creditData?.creditData);
  useEffect(() => {
    if (collegeId || reloadOnPaymentSuccess) {
      dispatch(
        CreditInfoGetAPI(alert, history, location, setLoading, collegeId)
      );
      setReloadOnPaymentSuccess(false);
    }
  }, [collegeId, reloadOnPaymentSuccess]);

  const [page, setPage] = useState(0);

  const [page2, setPage2] = useState(0);

  const [tableLoader, setTableLoader] = useState(true);
  useEffect(() => {
    if (date && (collegeId || reloadOnPaymentSuccess)) {
      if (
        transactionData.length <= (page + 1) * 5 - 5 ||
        transactionData.length === 0
      )
        dispatch(
          TransactionistoryGetApi(
            alert,
            history,
            location,
            setTableLoader,
            date,
            collegeId,
            page === 0 ? 1 : page + 1
          )
        );

      if (
        rechargeData.length <= (page2 + 1) * 5 - 5 ||
        rechargeData.length === 0
      )
        dispatch(
          RechargeHistoryGetApi(
            alert,
            history,
            location,
            setTableLoader,
            date,
            collegeId,
            page2 === 0 ? 1 : page2 + 1
          )
        );
      setReloadOnPaymentSuccess(false);
    }
  }, [
    collegeId,
    reloadOnPaymentSuccess,
    page,
    page2,
    transactionData,
    rechargeData,
  ]);

  const tableData = useSelector(
    (state) => state?.creditData?.transactionHistoryData
  );
  const tableRechargeData = useSelector(
    (state) => state?.creditData?.rechargeHistoryData
  );
  useEffect(() => {
    if (tableData?.results && tableData?.results.length) {
      if (
        transactionData.length <= (page + 1) * 5 - 5 ||
        transactionData.length === 0
      ) {
        setTransactionData([...transactionData, ...tableData?.results]);
        setTotalCount(tableData?.count);
      }
    }
  }, [tableData]);
  useEffect(() => {
    if (tableRechargeData?.results && tableRechargeData?.results.length) {
      if (
        rechargeData.length <= (page2 + 1) * 5 - 5 ||
        rechargeData.length === 0
      ) {
        setRechargeData([...rechargeData, ...[tableRechargeData?.results]]);
        setTotalCount2(tableRechargeData?.count);
      }
    }
  }, [tableRechargeData]);

  // const [filterLoader, setFilterLoader] = useState(false);

  const handleDateFilter = () => {
    if (date && collegeId) {
      if (date.endDate == "Invalid date" || date.startDate == "Invalid date") {
        alert.error("Select valid date range");
      } else if (date.startDate > date.endDate) {
        alert.error("Start date can't be greater than end date.");
      } else {
        setPage(0);
        setPage2(0);
        setTransactionData([]);
        setRechargeData([]);
        dispatch(
          TransactionistoryGetApi(
            alert,
            history,
            location,
            setTableLoader,
            date,
            collegeId,
            1
          )
        );

        dispatch(
          RechargeHistoryGetApi(
            alert,
            history,
            location,
            setTableLoader,
            date,
            collegeId,
            1
          )
        );
      }
    }
  };

  const data = [
    {
      title: "Available",
      info: "Free Credits",
      color: "#029E73",
      number: creditData?.free_credits,
    },
    {
      title: "Available",
      info: "Paid Credits",
      color: "#0B57D0",
      number: creditData?.paid_credits,
    },
    {
      title: "Free",
      info: "Expired Credits",
      color: "#EE2C3C",
      number: creditData?.expired_credits,
    },
    {
      title: "Your",
      info: "Burned Credits",
      color: "#E0A800",
      number: creditData?.burned_credits,
    },
  ];

  return (
    <>
      <Box style={{ width: "100%" }}>
        <Grid container spacing={2} style={{ width: "100%", margin: 0 }}>
          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              <div>
                <Typography variant="h2">Dashboard Subscription</Typography>
                <Typography variant="body2" color="secondary">
                  View your License Details
                </Typography>
              </div>
            </div>
          </Grid>

          <Grid item xs={8}>
            {!billingLoader ? (
              <Box
                style={{
                  background:
                    "linear-gradient(90deg, #0B57D0 0%, #2D81F7 100%)",
                  height: "164px",
                  borderRadius: "10px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  padding: 15,
                }}
              >
                <Typography
                  style={{ fontSize: "20px", fontWeight: "600", color: "#fff" }}
                >
                  Annual plan
                </Typography>
                <Typography
                  style={{
                    fontWeight: "16px",
                    fontWeight: "600",
                    color: "#fff",
                  }}
                >
                  This is your annual plane which are valid from{" "}
                  {billing?.start_date} to {billing?.end_date}
                </Typography>
                <Typography
                  style={{
                    fontSize: "32px",
                    fontWeight: "600",
                    color: "#fff",
                    marginTop: "10px",
                  }}
                >
                  ₹ {billing?.amount} INR
                  <span style={{ fontWeight: 400, fontSize: "16px" }}>
                    {" "}
                    (Yearly Bill)
                  </span>
                </Typography>
              </Box>
            ) : (
              <RechargeSkelton height={164} />
            )}
          </Grid>
          <Grid item xs={4}>
            {!billingLoader ? (
              <Box
                style={{
                  border: " 1px solid #CBD5E1",
                  height: "164px",
                  borderRadius: "10px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  padding: 15,
                  backgroundColor: "#fff",
                }}
              >
                <Typography style={{ fontSize: "20px", fontWeight: "600" }}>
                  Registered Users
                </Typography>
                <Typography
                  style={{
                    fontWeight: "16px",
                    fontWeight: "600",
                    color: "#6C757D",
                  }}
                >
                  Your total user registration license is up to{" "}
                  {billing?.license_purchased} students.
                </Typography>
                <div style={{ width: "100%", marginTop: 10 }}>
                  <Typography style={{ fontSize: "14px" }}>
                    {billing?.license_used} / {billing?.license_purchased} users
                  </Typography>
                  <BorderLinearProgress
                    variant="determinate"
                    value={
                      (billing?.license_used / billing?.license_purchased) * 100
                    }
                  />
                </div>
              </Box>
            ) : (
              <RechargeSkelton height={164} />
            )}
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          style={{ marginTop: "20px", width: "100%", margin: 0 }}
        >
          <Grid item xs={12}>
            <div>
              <Typography variant="h2">Credits information</Typography>
              <Typography variant="body2" color="secondary">
                View your Credits Details
              </Typography>
            </div>
          </Grid>
          <Grid item xs={5}>
            {!loading ? (
              <Box
                style={{
                  background:
                    "linear-gradient(101.82deg, #000000 2.87%, #343435 92.72%)",
                  height: "190px",
                  borderRadius: "10px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  padding: 15,
                  position: "relative",
                }}
              >
                <Box style={{ position: "absolute" }}>{RechargeVactor}</Box>
                <Typography
                  style={{
                    fontWeight: "16px",
                    fontWeight: "600",
                    color: "#fff",
                  }}
                >
                  Hi {collegeUserDetail?.name}, here is your Credits Info:
                </Typography>
                <Typography
                  style={{
                    fontSize: "32px",
                    fontWeight: "600",
                    color: "#fff",
                  }}
                >
                  {token} {creditData?.available_credits}
                </Typography>
                <Button
                  variant="contained"
                  color="inherit"
                  style={{
                    backgroundColor: "#fff",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    boxShadow: "none",
                    marginTop: "10px",
                  }}
                  onClick={handleClickOpenCredit}
                >
                  Add Credits
                </Button>

                <AddCreditsDialog
                  open={addCredit}
                  handleClose={handleCloseCredit}
                  handleClickOpenCredit={handleClickOpenCredit}
                  creatOrder={creatOrder}
                />
              </Box>
            ) : (
              <RechargeSkelton height={190} />
            )}
          </Grid>
          <Grid item xs={7}>
            <Box>
              <Grid container spacing={2}>
                {!loading ? (
                  <>
                    {data.map((item) => (
                      <Grid item md={6}>
                        <Box
                          style={{
                            border: "1px solid  #CBD5E1",
                            backgroundColor: "#fff",
                            height: "86px",
                            borderRadius: "10px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "flex-start",
                            padding: 15,
                          }}
                        >
                          <Grid container wrap="nowrap" spacing={2}>
                            <Grid item xs>
                              <div>
                                <Typography
                                  style={{ fontSize: "14px", color: "#6C757D" }}
                                >
                                  {item.title}
                                </Typography>
                                <Typography
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "600",
                                  }}
                                >
                                  {item.info}
                                </Typography>
                              </div>
                            </Grid>
                            <Grid
                              item
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Typography
                                variant="h3"
                                style={{
                                  fontSize: "32px",
                                  fontWeight: "600",
                                  color: item.color,
                                }}
                              >
                                {item.number}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    ))}
                  </>
                ) : (
                  <>
                    {[1, 2, 3, 4]?.map((item) => (
                      <Grid item md={6}>
                        <RechargeSkelton height={86} />
                      </Grid>
                    ))}
                  </>
                )}
              </Grid>
            </Box>
          </Grid>

          <Grid item md={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "15px",
                marginTop: 10,
              }}
            >
              <div>
                {!loading ? (
                  <div>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      indicatorColor="primary"
                      textColor="primary"
                      variant="standard"
                      aria-label="full width tabs example"
                    >
                      <Tab label="Credit History" {...a11yProps(0)} />
                      <Tab label="Payment History" {...a11yProps(1)} />
                    </Tabs>
                  </div>
                ) : (
                  <div style={{ width: "150px" }}>
                    <RechargeSkelton height={40} />
                  </div>
                )}
              </div>
              <div>
                {!loading ? (
                  <Cal
                    startDate={(event) =>
                      setDate({
                        ...date,

                        startDate: moment(new Date(event.target.value)).format(
                          "yyyy-MM-DD"
                        ),
                      })
                    }
                    endDate={(event) => {
                      setDate({
                        ...date,

                        endDate: moment(new Date(event.target.value)).format(
                          "yyyy-MM-DD"
                        ),
                      });
                      // setDateFilter(true);
                    }}
                    initialDate={date?.startDate}
                    lastDate={date?.endDate}
                    handleDateFilter={handleDateFilter}
                  />
                ) : (
                  <div style={{ width: "250px" }}>
                    <RechargeSkelton height={40} />
                  </div>
                )}
              </div>
            </div>
            {!tableLoader && !loading ? (
              <>
                <div style={{ marginTop: 15 }}>
                  <TabPanel value={value} index={0}>
                    <AllHistory
                      transactionData={transactionData}
                      totalCount={totalCount}
                      page={page}
                      setPage={setPage}
                    />
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <PaymentHistoryTable
                      rechargeData={rechargeData}
                      totalCount={totalCount2}
                      page={page2}
                      setPage={setPage2}
                    />
                  </TabPanel>
                </div>{" "}
              </>
            ) : (
              <RechargeSkelton height={300} />
            )}
          </Grid>
        </Grid>
      </Box>
      {paymentInProcess && <PaymentProcessing open={paymentInProcess} />}
      {(paymentSuccessful || paymentFailed || paymentPending) && (
        <PaymentDialog
          payment={
            paymentSuccessful ? "SUCCESS" : paymentFailed ? "FAILED" : "PENDING"
          }
          dialogOpen={paymentSuccessful || paymentFailed || paymentPending}
          setPaymentFailed={setPaymentFailed}
          setPaymentCancelledMessage={setPaymentCancelledMessage}
          setPaymentFailedMessage={setPaymentFailedMessage}
          setPaymentFailedFlag={setPaymentFailedFlag}
          setPaymentSuccessful={setPaymentSuccessful}
          setPaymentPending={setPaymentPending}
          paymentStatusDetails={paymentStatusDetails}
          setPaymentStatusDetails={setPaymentStatusDetails}
        />
      )}
      {/* <PaymentPending open={true} /> */}
    </>
  );
}
