import { Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { collegeGroupListSelectorFn, isCollegeGroupFn, isEducationGroupFn } from "../../api/SignIn/selector";
import { EDGPlacementCyclesAPI } from "../../api/University/APIs/action";
import UplacementCycle from "../../components/Cards/UniversityGraph/UPlcementCycle";
import CustomCircularProgress from "../../components/CircularProgressBar";
// import GroupListDropdown from "../../components/Input/DropDownButton/GroupListDropdown";
import GroupListDropdown from "../../components/Input/DropDownButton/GroupListDropdown";
import { BackendBaseApi } from "../../constants/constants";
import { generateCsvForParams } from "../../utility/commaSeparatedStrings";

function UniversityPlacementCycle() {
  const dispatch = useDispatch();
  const alert = useAlert();
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const isEducationGroup = useSelector(isEducationGroupFn);
  const isCollegeGroup = useSelector(isCollegeGroupFn);
  
  const checkSelectedGroup = () => {
    if (isCollegeGroup) {
      if (!selectedGroup && collegeGroupList?.length === 1) return `&group_id=${collegeGroupList[0]?.id}`;
      else if (selectedGroup && selectedGroup.id !== "All") return `&group_id=${selectedGroup.id}`;
      else return `&group_id=${generateCsvForParams(collegeGroupList?.map((item) => item.id))}`;
    } else {
      if (selectedGroup && selectedGroup.id !== "All") return `&group_id=${selectedGroup.id}`;
      else return ``;
    }
  };

  useEffect(() => {
    var url = `${BackendBaseApi.SANCHALAN}api/university/university_count/?query=placement_cycle${checkSelectedGroup()}`;
    dispatch(EDGPlacementCyclesAPI(setLoading, alert, history, location, url));
  }, []);

  useEffect(() => {
    if (selectedGroup) {
      var url = `${BackendBaseApi.SANCHALAN}api/university/university_count/?query=placement_cycle${checkSelectedGroup()}`;
      dispatch(EDGPlacementCyclesAPI(setLoading, alert, history, location, url));
    }
  }, [selectedGroup]);

  const collegePlacementList = useSelector((state) => state?.EDGCommon?.edgPlacementList);
  const collegeGroupList = useSelector(collegeGroupListSelectorFn);
  const [newCollegeGroupList, setNewCollegeGroupList] = useState([]); 

useEffect(() => {
    if (collegeGroupList?.length > 0 && isCollegeGroup) {
      setNewCollegeGroupList([{ id: "All", name: "All Groups" }].concat(collegeGroupList));
    }
    else
    setNewCollegeGroupList(collegeGroupList);
  }, [collegeGroupList?.length]);
  return (
        <>
          {collegeGroupList?.length > 0 && (
            <div style={{ marginTop: "5px", marginBottom: "5px" }}>
              <GroupListDropdown title="Group" state={selectedGroup} setState={setSelectedGroup} groupList={newCollegeGroupList} />
            </div>
          )}
          <br />
          {loading ? (
        <CustomCircularProgress />
      ) : 
        <>
          <Typography variant="h2">Placement Cycles</Typography>
          <div style={{ marginTop: "20px" }}>
            {" "}
            <Grid container spacing={5}>
              {collegePlacementList?.length > 0 &&
                collegePlacementList?.map((item) => (
                  <Grid item xs={4}>
                    <UplacementCycle
                      item={item}
                      countTitle={"Active Placement Cycles"}
                      count={item?.count}
                      link={`/placement-cycles`}
                      linkTitle={"View All Active Placement Cycles"}
                      from={"Placement"}
                    />
                  </Grid>
                ))}
            </Grid>
          </div>
        </>
      }
      {/* </UniversityLayout> */}
    </>
  )
}

export default UniversityPlacementCycle;
