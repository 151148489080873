import pSBC from "shade-blend-color";
// import { Hidden } from "@material-ui/core";
// import Chip from "@material-ui/core/Chip";
// import { makeStyles } from "@material-ui/core/styles";
// import React, { useState } from "react";

// const useStyles = makeStyles((theme) => ({
//   chip: {
//     margin: theme.spacing(0.5),
//     color: theme.palette.primary.main,
//     backgroundColor: pSBC(0.8, theme.palette.primary.main),
//     boxShadow: "0 1px 2px 0 rgba(44, 39, 56, 0.08)",
//     border: `solid 0.5px ${theme.palette.primary.main}`,
//     borderRadius: "8px",
//   },
//   showing: {
//     color: theme.palette.primary.main,
//     fontSize: 14,
//     cursor: "pointer",
//     paddingTop: 2,
//   },
// }));

// export default function ChipCourses({ courseArray, displayLimit }) {
//   const classes = useStyles();
//   const [showMore, setShowMore] = useState(false);
//   console.log("zxcvbnm", courseArray);

//   const selectedSpecializations = () => {
//     let selectedSpecs = [];
//     let allDegreesSelected = true;

//     courseArray?.forEach((education) => {
//       education.degrees.forEach((degree) => {
//         if (degree.select_all) {
//           selectedSpecs.push({
//             degreeName: degree.degree_name,
//             specName: "All Courses",
//           });
//         } else {
//           allDegreesSelected = false;
//           degree.specialization.forEach((spec) => {
//             if (spec.selected) {
//               selectedSpecs.push({
//                 degreeName: degree.degree_name,
//                 specName: spec.specialization_name,
//               });
//             }
//           });
//         }
//       });
//     });

//     if (courseArray && courseArray.length && allDegreesSelected) {
//       return [{ degreeName: "", specName: "Open to All Courses" }];
//     }

//     return selectedSpecs;
//   };

//   const selectedSpecs = selectedSpecializations();

//   return (
//     <>
//       <div className={classes.chipsection}>
//         <>
//           {!showMore && (
//             <>
//               {selectedSpecs.length > 5 ? (
//                 <>
//                   {selectedSpecs.slice(0, 5).map((item, index) => (
//                     <Chip
//                       key={index}
//                       variant="outlined"
//                       size="small"
//                       label={
//                         item.degreeName
//                           ? `${item.degreeName} / ${item.specName}`
//                           : item.specName
//                       }
//                       className={classes.chip}
//                     />
//                   ))}
//                   {selectedSpecs.length > 5 && (
//                     <span
//                       onClick={() => setShowMore(true)}
//                       className={classes.showing}
//                     >
//                       Show More...
//                     </span>
//                   )}
//                 </>
//               ) : (
//                 selectedSpecs.map((item, index) => (
//                   <Chip
//                     key={index}
//                     variant="outlined"
//                     size="small"
//                     label={
//                       item.degreeName
//                         ? `${item.degreeName} / ${item.specName}`
//                         : item.specName
//                     }
//                     className={classes.chip}
//                   />
//                 ))
//               )}
//             </>
//           )}

//           {showMore && (
//             <>
//               {selectedSpecs.map((item, index) => (
//                 <Chip
//                   key={index}
//                   variant="outlined"
//                   size="small"
//                   label={
//                     item.degreeName
//                       ? `${item.degreeName} / ${item.specName}`
//                       : item.specName
//                   }
//                   className={classes.chip}
//                 />
//               ))}
//               <span
//                 onClick={() => setShowMore(false)}
//                 className={classes.showing}
//               >
//                 Show less...
//               </span>
//             </>
//           )}
//         </>
//       </div>
//     </>
//   );
// }

import { Hidden, Button } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5),
    color: theme.palette.primary.main,
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    boxShadow: "0 1px 2px 0 rgba(44, 39, 56, 0.08)",
    border: `solid 0.5px ${theme.palette.primary.main}`,
    borderRadius: "8px",
  },
  showing: {
    color: theme.palette.primary.main,
    fontSize: 14,
    cursor: "pointer",
    paddingTop: 2,
  },
}));

export default function ChipCourses({
  courseArray,
  displayLimit = "all",
  openDialog,
  selectCoursesArray,
}) {
  const classes = useStyles();

  const selectedSpecializations = () => {
    let selectedSpecs = [];
    let allDegreesSelected = true;

    courseArray?.forEach((education) => {
      education.degrees.forEach((degree) => {
        if (degree.select_all) {
          selectedSpecs.push({
            degreeName: degree.degree_name,
            specName: "All Courses",
          });
        } else {
          allDegreesSelected = false;
          degree.specialization.forEach((spec) => {
            if (spec.selected) {
              selectedSpecs.push({
                degreeName: degree.degree_name,
                specName: spec.specialization_name,
              });
            }
          });
        }
      });
    });

    if (courseArray && courseArray.length && allDegreesSelected) {
      return [{ degreeName: "", specName: "Open to All Courses" }];
    }

    return selectedSpecs;
  };

  const selectedSpecs = selectedSpecializations();

  const chipsToDisplay =
    displayLimit === "all"
      ? selectedSpecs
      : selectedSpecs.slice(0, displayLimit);

  const isShowAllButtonVisible =
    displayLimit !== "all" && selectedSpecs.length > chipsToDisplay.length;

  return (
    <div className={classes.chipsection}>
      {chipsToDisplay.map((item, index) => (
        <Chip
          key={index}
          variant="outlined"
          size="small"
          label={
            item.degreeName
              ? `${item.degreeName} / ${item.specName}`
              : item.specName
          }
          className={classes.chip}
        />
      ))}
      {isShowAllButtonVisible && (
        <Button
          onClick={() => {
            openDialog();
            selectCoursesArray(courseArray);
          }}
          style={{ width: "90px" }}
        >
          Show All
        </Button>
      )}
    </div>
  );
}
