import { Box, Grid, Button } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import DegreeSpecializationCustom from "../../DegreeSpecializationCustom";
import AddEligible from "./AddEligible";
import DegreeSpecializationMultipleCollege from "../../DegreeSpecializationCustom/DegreeSpecializationMultipleCollege";
import ChipCourses from "../../Form/ChipCourses/index2";
import DegreeSpecializationDialog from "./DegreeSpecializationDialog";
import { useState } from "react";

function AllCourseGrid({
  allCollegeCoursesSet,
  setallCollegeCoursesSet,
  collegesList,
  loadingFromData,
  loading,
  setGlobalCoursesList,
}) {
  const [selectedCoursesArray, setSelectedCoursesArray] = useState([]);
  const selectCoursesArray = (coursesArray) => {
    setSelectedCoursesArray(coursesArray);
  };

  const [open, setOpen] = useState(false);
  const openDialog = () => {
    setOpen(true);
  };
  const closeDialog = () => {
    setOpen(false);
  };
  return (
    <>
      <Box>
        <Grid container spacing={2} style={{ margin: 0, width: "100%" }}>
          {collegesList
            ?.filter((college) => college.checked)
            ?.map((college, index) => (
              <>
                {!loadingFromData ? (
                  <Grid
                    item
                    md={6}
                    style={{
                      borderBottom: "1px solid #E0E0E0",
                      borderRight: "1px solid #E0E0E0",
                    }}
                  >
                    <AddEligible details={college?.details} />
                  </Grid>
                ) : (
                  <Grid
                    item
                    md={6}
                    style={{
                      borderBottom: "1px solid #E0E0E0",
                      borderRight: "1px solid #E0E0E0",
                    }}
                  >
                    <Skeleton
                      variant="rect"
                      width={560}
                      height={108}
                      style={{
                        borderTopRightRadius: "5px",
                        borderTopLeftRadius: "5px",
                        marginLeft: 20,
                      }}
                    />
                  </Grid>
                )}
                {!loadingFromData ? (
                  <Grid
                    item
                    md={6}
                    style={{ borderBottom: "1px solid #E0E0E0" }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        height: "100%",
                        padding: "0px 20px 0px 20px",
                        flexDirection: "column",
                      }}
                    >
                      <DegreeSpecializationMultipleCollege
                        collegeId={college?.id}
                        allCollegeCoursesSet={allCollegeCoursesSet}
                        coursesArray={allCollegeCoursesSet[college?.id]}
                        setCoursesArray={setallCollegeCoursesSet}
                        errorMessage={null}
                        loading={loading}
                        setGlobalCoursesList={setGlobalCoursesList}
                      />
                      <div style={{ marginBottom: "10px" }}>
                        <ChipCourses
                          courseArray={allCollegeCoursesSet[college?.id]}
                          displayLimit={3}
                          openDialog={openDialog}
                          selectCoursesArray={selectCoursesArray}
                        />
                        {/* {data?.length > 0 && ( */}
                        {/* <Button
                          onClick={() => {
                            openDialog();
                            selectCoursesArray(
                              allCollegeCoursesSet[college?.id]
                            );
                          }}
                          style={{ width: "90px" }}
                        >
                          Show All
                        </Button> */}
                      </div>
                    </Box>
                  </Grid>
                ) : (
                  <Grid
                    item
                    md={6}
                    style={{
                      borderBottom: "1px solid #E0E0E0",
                      borderRight: "1px solid #E0E0E0",
                    }}
                  >
                    <Skeleton
                      variant="rect"
                      width={560}
                      height={108}
                      style={{
                        borderTopRightRadius: "5px",
                        borderTopLeftRadius: "5px",
                        marginLeft: 20,
                      }}
                    />
                  </Grid>
                )}
              </>
            ))}
        </Grid>
      </Box>
      <DegreeSpecializationDialog
        open={open}
        coursesArray={selectedCoursesArray}
        handleClose={closeDialog}
      />
    </>
  );
}

export default AllCourseGrid;
