import { Button, CircularProgress, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import GetAppIcon from "@material-ui/icons/GetApp";
import PublishIcon from "@material-ui/icons/Publish";
import SendIcon from "@material-ui/icons/Send";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import CopyToClipboard from "react-copy-to-clipboard";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import {
  InviteStudentLinkAPI,
  PlacementListAPI,
} from "../../api/Common/action";
import { isGradeSystemFn } from "../../api/Domain/selector";
import {
  ExcelStudentPostAPI,
  StudentNavbarAPI,
  StudentNavbarAPII,
  StudentsGetAPI,
  StudentsGetAPI2,
} from "../../api/ManageStudents/action";
import { collegeIdFn, userRolesActionsFn } from "../../api/SignIn/selector";
import sampleExcel from "../../assets/excel/Sample_Student.xlsx";
import CustomCircularProgress from "../../components/CircularProgressBar";
import ShortlistEligible from "../../components/DialogSection/AddCompanyPlacement/AddStudentPlacement copy";
import AddNewStudent from "../../components/DialogSection/AddNewCompany/AddNewStudent";
import ImportData from "../../components/DialogSection/ImportData";
import UploadExcelFile from "../../components/DialogSection/UploadExcelFile";
import StudentTab from "../../components/Header/StudentTab";
import DropDownButton from "../../components/Input/DropDownButton";
import NoDataFuncNew from "../../components/NoDataSVG/index2";
import { generateCsv } from "../../utility/commaSeparatedStrings";
import { dataRow, headerSampleFile } from "./sampleFIle";
import { STUDENT_DATA } from "../../api/ManageStudents/action";
import ShareStudentDetails from "../../components/DialogSection/ShareApplicant/shareStudent";
import TrackShareSuccessfully from "../../components/DialogSection/TrackShareSuccessfull";
import axios from "axios";
import { replace } from "formik";
import {
  praveshAxiosGetReq,
  sanchalanAxiosGetReq,
} from "../../api/BaseApi/apiBase";
import { checkForToken } from "../../constants/ApiCall";
import StudentTabNew from "../../components/Header/StudentTabNew";

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    minWidth: "150px",
    display: "flex",
    backgroundColor: theme.palette.primary.secondary,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.palette.primary.secondary,
    },
  },
  grid: {
    display: "flex",
    marginTop: "10px",
    marginBottom: "20px",
    justifyContent: "space-between",
    //  alignItems: "center",
    flexDirection: "row",
  },
  buttonBase: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
  },
  primebtn: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    marginRight: "10px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
  },
}));

export default function StudentTrack() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const alert = useAlert();
  const dispatch = useDispatch();

  const studentList1 = useSelector((state) => state?.StudentData?.details);
  const filterList = useSelector((state) => state?.StudentData?.filterData);

  const [loading, setLoading] = useState(true);
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  useEffect(
    () => console.log("selectedRowsData", selectedRowsData),
    [selectedRowsData]
  );
  const [value, setValue] = React.useState("all");
  const [firstApiCall, setFirstApiCall] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const [openImport, setOpenImport] = useState(false);
  const [loadingExcel, setLoadingExcel] = useState(false);
  const [fileNames, setFileNames] = useState([]);
  const [progress, setProgress] = useState(0);
  const [dataLoaded, setDataLoaded] = useState(0);
  const [totalLoad, setTotalLoad] = useState(0);
  const [applyClickedScore, setApplyClickedScore] = useState(false);
  const [placementSession, setPlacementSession] = useState(null);
  const collegeId = useSelector(collegeIdFn);
  const [callNavbar, setCallNavbar] = useState(false);
  const [callNavbarHigherStudies, setCallNavbarHigherStudies] = useState(false);
  const [filters, setFilters] = useState({
    search: null,
    course: null,
    degree: null,
    batch: null,
    cgpa_min: null,
    cgpa_max: null,
    status_active: true,
    status_blocked: true,
    status: "ALL",
    current_tab: null,
    placement_status: null,
    gender: null,
  });
  const [clickSearchButton, setClickSearchButton] = useState(false);
  const isGradeSystem = useSelector(isGradeSystemFn);
  const [open, setOpen] = React.useState(false);
  const [selectedID, setSelectedID] = React.useState([]);
  const [validationData, setValidationData] = useState({});
  const [imported, setImported] = useState(false);

  const initialPageData = { currentPage: 0, pageSize: 20 };
  const [pageData, setPageData] = useState(initialPageData);

  const [studentList, setStudentList] = useState([]);
  const [studentListTotalCount, setStudentListTotalCount] = useState(0);

  useEffect(() => {
    if (
      studentList.length / pageData.pageSize !== pageData.currentPage + 1 &&
      !loading
    ) {
      studentList1.forEach((item) => {
        item?.education_info &&
          Object?.keys(item?.education_info)?.map((key) => {
            //console.log(typeof key, key);
            if (key === "1") {
              item.secondarySchool = item?.education_info[key]?.college_name;
              item.secondaryBoard = item?.education_info[key]?.board;
              item.secondaryPercentage = item?.education_info[key]?.percent;
            } else if (key === "2") {
              item.seniorSecondarySchool =
                item?.education_info[key]?.college_name;
              item.seniorSecondaryBoard = item?.education_info[key]?.board;
              item.seniorSecondaryPercentage =
                item?.education_info[key]?.percent;
            } else if (key === "3") {
              item.ugCollege = item?.education_info[key]?.college_name;
              item.ugDegree = item?.education_info[key]?.degree;
              item.ugSpecialization = item?.education_info[key]?.specialization;
              item.ugPercentage = item?.education_info[key]?.percent;
            } else if (key === "4") {
              item.pgCollege = item?.education_info[key]?.college_name;
              item.pgDegree = item?.education_info[key]?.degree;
              item.pgSpecialization = item?.education_info[key]?.specialization;
              item.pgPercentage = item?.education_info[key]?.percent;
            } else if (key === "5") {
              item.phdCollege = item?.education_info[key]?.college_name;
              item.phdDegree = item?.education_info[key]?.degree;
              item.phdSpecialization =
                item?.education_info[key]?.specialization;
              item.phdPercentage = item?.education_info[key]?.percent;
            } else if (key === "gender") {
              item.gender = item?.education_info[key];
            } else if (key === "resume") {
              item.student_resume = item?.education_info[key];
            } else if (key === "college_resume") {
              item.college_resume = item?.education_info[key];
            }
          });
      });
      setStudentList((prev) => {
        return [...prev, ...(studentList1 || [])];
      });
    }
  }, [studentList1]);

  const initialBackendDrivenFilter = {
    outerFilters: {
      search: null,
    },
    innerFilters: {},
  };
  const [backendDrivenFilter, setBackendDrivenFilter] = useState(
    initialBackendDrivenFilter
  );

  const [filterData, setFilterData] = useState([]);
  const [filterLoading, setFilterLoader] = useState(true);

  useEffect(() => {
    const getFilters = async () => {
      setFilterLoader(true);
      let endPoint = `api/manage_student/get/filters/?college_id=${collegeId}${checkValue()}`;
      endPoint = endPoint.replace(/^&+/, "");
      endPoint = endPoint.replace(/&+$/, "");
      try {
        const res = await sanchalanAxiosGetReq(endPoint);
        const data = res?.data?.data;
        if (res?.data?.success) {
          setFilterData(data);
        }
        setFilterLoader(false);
      } catch (error) {
        throw error;
        setFilterLoader(false);
      }
    };
    if (collegeId) {
      getFilters();
      setBackendDrivenFilter(initialBackendDrivenFilter);
    }
  }, [filters.status]);

  const createQueryStringOuterFilter = (paramsObject) => {
    let queryParams = "";
    Object.keys(paramsObject).forEach((key) => {
      const value = paramsObject[key];

      if (value != null && value != undefined && value != "") {
        if (value instanceof Date) {
          queryParams += `${key}=${value.getDate()}/${
            value.getMonth() + 1
          }/${value.getFullYear()}&`;
        } else {
          if (Array.isArray(value)) {
            queryParams += `${key}=${value.join(",")}&`;
          } else {
            queryParams += `${key}=${value}&`;
          }
        }
      }
    });
    return queryParams;
  };

  const formatDate = (date) => {
    if (date instanceof Date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-based, so add 1
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    }
    return "";
  };

  const createQueryStringInnerFilter = (paramsObject) => {
    let queryParams = "";
    Object.keys(paramsObject).forEach((key) => {
      const items = paramsObject[key];
      if (items) {
        const validItems = items.filter(
          (item) =>
            item.value != null &&
            item.value !== undefined &&
            item.value !== "" &&
            !(Array.isArray(item.value) && item.value.length === 0) &&
            !(
              typeof item.value === "object" &&
              !Array.isArray(item.value) &&
              !(item.value instanceof Date) &&
              Object.keys(item.value).length === 0
            )
        );
        if (validItems.length) {
          if (key == "cgpa") {
            const { min, max } = validItems[0]?.value;

            if (min && max) {
              queryParams += `min_percentage=${Number(
                min
              )}&max_percentage=${Number(max)}`;
            } else if (min) {
              queryParams += `min_percentage=${Number(min)}`;
            } else if (max) {
              queryParams += `max_percentage=${Number(max)}`;
            }
          } else {
            let values = validItems
              .map((item) => {
                if (item.value instanceof Date) {
                  return formatDate(item.value);
                } else if (Array.isArray(item.value)) {
                  return item.value.join(",");
                } else {
                  return item.value;
                }
              })
              .join(",");

            if (values) {
              queryParams += `${key}=` + values;
            }
          }

          queryParams += "&";
        }
      }
    });

    queryParams = queryParams.slice(0, -1);

    return queryParams;
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const checkHigherStudies = () => {
    return filters?.current_tab?.params === "higher_studies";
  };

  const [resData, setresData] = useState({
    total_entries: null,
    user_created_count: null,
    email_already_exist: null,
    failed_entries: null,
    flag: null,
    error_list: [],
    already_exist_list: [],
    flagCloseImport: false,
  });

  const checkValue = () => {
    if (
      filters?.status === "" ||
      filters?.status === "ALL" ||
      filters?.status === "all"
    )
      return ``;
    else if (checkForPendingRejected())
      return `&key=${filters?.current_tab?.params}`;
    else if (checkHigherStudies())
      return `&key=${filters?.current_tab?.params}`;
    else return `&placement_status=${filters?.status}`;
  };
  const checkForKey = () => {
    if (
      filters?.status === "" ||
      filters?.status === "ALL" ||
      filters?.status === "all"
    )
      return ``;
    else if (checkForPendingRejected())
      return `&key=${filters?.current_tab?.params}`;
    // else if(checkHigherStudies()) return `&key=${filters?.current_tab?.params}`;
    else return `&placement_status=${filters?.status}`;
  };

  const searchFilter = () => {
    if (filters.search) return `&search=${filters.search}`;
    else return ``;
  };
  const departmentFilter = () => {
    if (filters.course) return `&course=${filters.course.id}`;
    else return ``;
  };
  const degreeFilter = () => {
    if (filters.degree) return `&degree=${filters.degree.id}`;
    else return ``;
  };
  const batchFilter = () => {
    if (filters.batch) return `&batch=${filters.batch.id}`;
    else return ``;
  };
  const statusFilter = () => {
    if (filters.status_active && filters.status_blocked) return ``;
    else if (filters.status_active) return `&status=ACTIVE`;
    else if (filters.status_blocked) return `&status=INACTIVE`;
    else return ``;
  };

  const checkPlacementSesion = () => {
    if (placementSession && placementSession.id !== "All")
      return `&placement_session=${placementSession.id}`;
    else return ``;
  };

  const genderFilter = () => {
    if (filters?.gender?.id === "Male") return `&gender=Male`;
    else if (filters?.gender?.id === "Female") return `&gender=Female`;
    else return ``;
  };

  const scoreFilter = () => {
    if (isGradeSystem) {
      if (filters.cgpa_min && filters?.cgpa_max)
        return `&min_cgpa=${Number(filters.cgpa_min)}&max_cgpa=${Number(
          filters.cgpa_max
        )}`;
      else if (filters.cgpa_min) return `&min_cgpa=${Number(filters.cgpa_min)}`;
      else if (filters.cgpa_max) return `&max_cgpa=${Number(filters.cgpa_max)}`;
      else return ``;
    } else {
      if (filters.cgpa_min && filters?.cgpa_max)
        return `&min_percentage=${Number(
          filters.cgpa_min
        )}&max_percentage=${Number(filters.cgpa_max)}`;
      else if (filters.cgpa_min)
        return `&min_percentage=${Number(filters.cgpa_min)}`;
      else if (filters.cgpa_max)
        return `&max_percentage=${Number(filters.cgpa_max)}`;
      else return ``;
    }
  };

  // useEffect(() => {
  //   let CancelToken = axios.CancelToken;
  //   let source = CancelToken.source();

  //   // api/manage_student/view/paginated/?college_id=50054&page=2&page_size=20
  //   if (
  //     collegeId
  //     // &&firstApiCall
  //   ) {
  //     var url = `api/manage_student/view/paginated/?&page=1&page_size=20&college_id=${collegeId}${checkValue()}${searchFilter()}${departmentFilter()}${batchFilter()}${statusFilter()}${degreeFilter()}${scoreFilter()}${checkPlacementSesion()}${genderFilter()}`;
  //     dispatch(
  //       StudentsGetAPI2(
  //         setLoading,
  //         alert,
  //         history,
  //         location,
  //         url,
  //         setClickSearchButton,
  //         setFirstApiCall,
  //         setApplyClickedScore,
  //         source
  //       )
  //     );

  //     var url_filter = `api/manage_student/navbar/?current_tab=${
  //       filters.status
  //     }&college_id=${collegeId}${checkValue()}${searchFilter()}${departmentFilter()}${batchFilter()}${statusFilter()}${degreeFilter()}${scoreFilter()}${checkPlacementSesion()}`;

  //     dispatch(StudentNavbarAPI(url_filter, source));
  //   }

  //   return () => {
  //     source.cancel("Cancelled due to stale request");
  //   };
  // }, [
  //   filters?.status_active,
  //   filters?.status_blocked,
  //   filters?.gender,
  //   filters?.status,
  // ]);

  useEffect(() => {
    if (firstCallDone && collegeId && imported) {
      setPageData(initialPageData);
      setStudentList([]);
    }
    // let CancelToken = axios.CancelToken;
    // let source = CancelToken.source();

    // if (imported) {
    //   var url = `api/manage_student/view/?college_id=${collegeId}${checkValue()}${searchFilter()}${departmentFilter()}${batchFilter()}${statusFilter()}${degreeFilter()}${scoreFilter()}${checkPlacementSesion()}${genderFilter()}`;
    //   dispatch(
    //     StudentsGetAPI(
    //       setLoading,
    //       alert,
    //       history,
    //       location,
    //       url,
    //       setClickSearchButton,
    //       setFirstApiCall,
    //       setApplyClickedScore,
    //       source
    //     )
    //   );

    //   var url_filter = `api/manage_student/navbar/?college_id=${collegeId}${checkValue()}${searchFilter()}${departmentFilter()}${batchFilter()}${statusFilter()}${degreeFilter()}${scoreFilter()}${checkPlacementSesion()}`;

    //   dispatch(StudentNavbarAPI(url_filter, source));
    // }

    // return () => {
    //   source.cancel("Cancelled due to stale request");
    // };
  }, [imported]);

  useEffect(() => {
    if (firstCallDone && collegeId && placementSession) {
      setPageData(initialPageData);
      setStudentList([]);
    }
    // let CancelToken = axios.CancelToken;
    // let source = CancelToken.source();

    // if (placementSession) {
    //   var url = `api/manage_student/view/?college_id=${collegeId}${checkValue()}${searchFilter()}${departmentFilter()}${batchFilter()}${statusFilter()}${degreeFilter()}${scoreFilter()}${checkPlacementSesion()}`;
    //   dispatch(
    //     StudentsGetAPI(
    //       setLoading,
    //       alert,
    //       history,
    //       location,
    //       url,
    //       setClickSearchButton,
    //       setFirstApiCall,
    //       setApplyClickedScore,
    //       source
    //     )
    //   );

    //   var url_filter = `api/manage_student/navbar/?college_id=${collegeId}${checkValue()}${searchFilter()}${departmentFilter()}${batchFilter()}${statusFilter()}${degreeFilter()}${scoreFilter()}${checkPlacementSesion()}`;

    //   dispatch(StudentNavbarAPI(url_filter, source));
    // }
    // return () => {
    //   source.cancel("Cancelled due to stale request");
    // };
  }, [placementSession]);

  // useEffect(() => {
  //   let CancelToken = axios.CancelToken;
  //   let source = CancelToken.source();
  //   if (firstApiCall) {
  //     var url = `api/manage_student/view/?college_id=${collegeId}${checkValue()}${searchFilter()}${departmentFilter()}${batchFilter()}${statusFilter()}${degreeFilter()}${scoreFilter()}${checkPlacementSesion()}`;
  //     dispatch(
  //       StudentsGetAPI(
  //         setLoading,
  //         alert,
  //         history,
  //         location,
  //         url,
  //         setClickSearchButton,
  //         setFirstApiCall,
  //         setApplyClickedScore,
  //         source
  //       )
  //     );

  //     var url_filter = `api/manage_student/navbar/?college_id=${collegeId}${checkValue()}${searchFilter()}${departmentFilter()}${batchFilter()}${statusFilter()}${degreeFilter()}${scoreFilter()}${checkPlacementSesion()}`;

  //     dispatch(StudentNavbarAPI(url_filter, source));
  //   }
  //   return () => {
  //     source.cancel("Cancelled due to stale request");
  //   };
  // }, [
  //   filters.status_blocked,
  //   filters.status_active,
  //   filters.batch,
  //   filters.course,
  //   filters.degree,
  // ]);

  useEffect(() => {
    if (firstCallDone && collegeId) {
      setPageData(initialPageData);
      setStudentList([]);
    }
  }, [backendDrivenFilter.innerFilters]);

  useEffect(() => {
    if (firstCallDone && collegeId && clickSearchButton) {
      setPageData(initialPageData);
      setStudentList([]);
    }
  }, [clickSearchButton]);

  const [addStudentClicked, setAddStudentClicked] = useState(false);
  useEffect(() => {
    if (firstCallDone && collegeId && addStudentClicked) {
      setPageData(initialPageData);
      setStudentList([]);
    }
  }, [addStudentClicked]);

  // useEffect(() => {
  //   if (applyClickedScore) {
  //     var url = `api/manage_student/view/?college_id=${collegeId}${checkValue()}${searchFilter()}${departmentFilter()}${batchFilter()}${statusFilter()}${degreeFilter()}${scoreFilter()}${checkPlacementSesion()}`;
  //     dispatch(
  //       StudentsGetAPI(
  //         setLoading,
  //         alert,
  //         history,
  //         location,
  //         url,
  //         setClickSearchButton,
  //         setFirstApiCall,
  //         setApplyClickedScore
  //       )
  //     );
  //     var url_filter = `api/manage_student/navbar/?college_id=${collegeId}${checkValue()}${searchFilter()}${departmentFilter()}${batchFilter()}${statusFilter()}${degreeFilter()}${scoreFilter()}${checkPlacementSesion()}`;

  //     dispatch(StudentNavbarAPI(url_filter));
  //   }
  // }, [applyClickedScore]);

  const [firstCallDone, setFirstCallDone] = useState(false);
  useEffect(() => {
    let CancelToken = axios.CancelToken;
    let source = CancelToken.source();

    if (
      placementSession &&
      collegeId &&
      studentList.length / pageData.pageSize === pageData.currentPage
    ) {
      let params = `${createQueryStringOuterFilter(
        backendDrivenFilter?.outerFilters
      )}${createQueryStringInnerFilter(
        backendDrivenFilter?.innerFilters
      )}${checkValue()}${checkPlacementSesion()}`;
      params = params.replace(/^&+/, "");
      params = params.replace(/&+$/, "");

      let url = `api/manage_student/view/paginated/?college_id=${collegeId}&page=${
        pageData.currentPage + 1
      }&page_size=${pageData.pageSize}&${params}`;
      url = url.replace(/&+$/, "");

      // var url = `api/manage_student/view/paginated/?&page=1&page_size=20&college_id=${collegeId}${checkValue()}${searchFilter()}${departmentFilter()}${batchFilter()}${statusFilter()}${degreeFilter()}${scoreFilter()}${checkPlacementSesion()}${genderFilter()}`;
      dispatch(
        StudentsGetAPI2(
          setLoading,
          alert,
          history,
          location,
          url,
          setClickSearchButton,
          setFirstApiCall,
          setApplyClickedScore,
          setStudentListTotalCount,
          setAddStudentClicked,
          source
        )
      )
        .then(() => {
          setFirstCallDone(true);
        })
        .catch((error) => {
          console.error("Fetch data failed:", error);
        });

      if (!studentList.length) {
        // var url_filter = `api/manage_student/navbar/?current_tab=${
        //   filters.status
        // }&college_id=${collegeId}${checkValue()}${searchFilter()}${departmentFilter()}${batchFilter()}${statusFilter()}${degreeFilter()}${scoreFilter()}${checkPlacementSesion()}`;
        var url_filter = `api/manage_student/navbar/?current_tab=${filters.status}&college_id=${collegeId}&${params}`;
        dispatch(StudentNavbarAPI(url_filter, source));
      }
    }
    return () => {
      source.cancel("Cancelled due to stale request");
    };
  }, [pageData, placementSession]);

  const clearStudentData = () => {
    dispatch({
      type: STUDENT_DATA,
      payload: [],
    });
  };

  const handleClickOpenUpload = () => {
    setOpenUpload(true);
  };

  const handleCloseUpload = () => {
    setOpenUpload(false);
    setFileNames([]);
  };

  const handleCancelImport = () => {
    setOpenImport(false);
    setresData((resData) => ({ ...resData, flag: false }));
  };

  const handleOpenImport = () => {
    setOpenUpload(false);
    setOpenImport(true);
    if (fileNames?.length > 0) {
      var formData = new FormData();
      formData.append("file", fileNames[0]);
      formData.append("college_id", [collegeId]);
      dispatch(
        ExcelStudentPostAPI(
          formData,
          setLoadingExcel,
          alert,
          history,
          location,
          handleCloseImport,
          setProgress,
          setDataLoaded,
          setTotalLoad,
          setresData,
          setImported
        )
      );
    }
  };

  useEffect(() => {
    if (resData?.flagCloseImport) {
      setOpenImport(false);
    }
  }, [resData?.flagCloseImport, openImport]);

  const handleCloseImport = () => {
    setOpenImport(true);
    setFileNames([]);
  };

  const navbarData = useSelector((state) => state?.StudentData?.navbar);
  const placementSessionList = useSelector(
    (state) => state.commonData.placementList
  );
  const [loadingPlacement, setLoadingPlacement] = useState(false);
  const userRolesPermission = useSelector(userRolesActionsFn);

  useEffect(() => {
    if (collegeId)
      dispatch(
        PlacementListAPI(
          alert,
          history,
          location,
          setLoadingPlacement,
          collegeId
        )
      );
  }, [collegeId]);

  useEffect(() => {
    if (placementSessionList?.length > 0) {
      if (location.search.includes("placement_cycle")) {
        var placement_name = checkPlacementName(
          location?.search.replace("?placement_cycle=", "")
        );
        setPlacementSession({
          id: location?.search.replace("?placement_cycle=", ""),
          name: placement_name,
        });
      } else {
        setPlacementSession({ id: "All", name: "All Placement Cycles" });
      }
    }
  }, [location, placementSessionList?.length]);

  const checkPlacementName = (id) => {
    var name = "";
    placementSessionList.map((item) => {
      if (item.id === id) name = item.name;
    });
    return name;
  };

  const inviteLink = useSelector((state) => state.commonData?.studentLink);

  useEffect(() => {
    dispatch(InviteStudentLinkAPI(alert, history, location));
  }, [collegeId]);

  const [tempStudentList, setTempStudentList] = useState([]);
  const [studentListMain, setTempStudentListMain] = useState([]);
  const [selectedColumnFields, setSelectedColumnFields] = useState([]);

  const [exportLoading, setExportLoading] = useState(false);

  const sendExportLink = async (columnStrings) => {
    // const url = `/api/manage_student/export_student_data/?college_id=${collegeId}${checkValue()}${searchFilter()}${departmentFilter()}${batchFilter()}${statusFilter()}${degreeFilter()}${scoreFilter()}${checkPlacementSesion()}&column_name=${columnStrings}`;

    let params = `${createQueryStringOuterFilter(
      backendDrivenFilter?.outerFilters
    )}${createQueryStringInnerFilter(
      backendDrivenFilter?.innerFilters
    )}${checkValue()}${checkPlacementSesion()}`;
    params = params.replace(/^&+/, "");
    params = params.replace(/&+$/, "");

    let url = `/api/manage_student/export_student_data/?college_id=${collegeId}&column_name=${columnStrings}&${params}`;
    url = url.replace(/&+$/, "");

    setExportLoading(true);
    try {
      const res = await sanchalanAxiosGetReq(url);

      if (res.data.success) {
        const successMessage = res?.data?.data?.message;
        alert?.success(successMessage);
        setExportLoading(false);
      } else {
        console.log("error ocuured");
      }
    } catch (error) {
      setExportLoading(false);
      checkForToken(error, history, location, alert);
      if (error?.response?.status !== 401) {
        alert.error(error?.response?.data?.error);
      }
      throw error;
    }
  };

  const handleExport = () => {
    let str = "";
    if (selectedColumnFields?.length > 0) {
      selectedColumnFields?.map((item) => {
        str += `${item.replace(/ /g, "_").toLowerCase()},`;
      });
      sendExportLink(str?.replace(/,*$/, ""));
    } else {
      alert.error("Select Columns to Export");
    }
  };

  const [filterDataText, setFilterDataText] = useState(null);

  // useEffect(() => {
  //   if (
  //     (filters.status_blocked ||
  //       filters.status_active ||
  //       filters.batch ||
  //       filters.course ||
  //       filterDataText.gender ||
  //       filters.degree ||
  //       applyClickedScore ||
  //       clickSearchButton) &&
  //     studentList?.length === 0
  //   ) {
  //     setFilterDataText("No Results Found");
  //   }
  // }, [
  //   filters.status_blocked,
  //   filters.status_active,
  //   filters.batch,
  //   filters.course,
  //   filters.degree,
  //   applyClickedScore,
  //   clickSearchButton,
  //   filters.gender,
  // ]);

  useEffect(() => {
    if (collegeId && callNavbarHigherStudies) {
      let params = `${createQueryStringOuterFilter(
        backendDrivenFilter?.outerFilters
      )}${createQueryStringInnerFilter(
        backendDrivenFilter?.innerFilters
      )}${checkValue()}${checkPlacementSesion()}`;
      params = params.replace(/^&+/, "");
      params = params.replace(/&+$/, "");

      var url_filter = `api/manage_student/navbar/?current_tab=${filters.status}&college_id=${collegeId}&${params}`;
      url_filter = url_filter.replace(/&+$/, "");

      // var url_filter = `api/manage_student/navbar/?college_id=${collegeId}${checkValue()}${searchFilter()}${departmentFilter()}${batchFilter()}${statusFilter()}${degreeFilter()}${scoreFilter()}${checkPlacementSesion()}`;
      dispatch(StudentNavbarAPI(url_filter));
    }
  }, [callNavbarHigherStudies]);
  // , studentList?.length

  useEffect(() => {
    if (checkForPendingRejected()) {
      setPlacementSession({ id: "All", name: "All Placement Cycles" });
      // setPlacementSession({});
    }
  }, [filters?.current_tab]);

  useEffect(() => {
    if (collegeId && callNavbar) {
      // var url_filter = `api/manage_student/navbar/?college_id=${collegeId}${checkValue()}${searchFilter()}${departmentFilter()}${batchFilter()}${statusFilter()}${degreeFilter()}${scoreFilter()}${checkPlacementSesion()}`;
      // dispatch(StudentNavbarAPI(url_filter));

      let params = `${createQueryStringOuterFilter(
        backendDrivenFilter?.outerFilters
      )}${createQueryStringInnerFilter(
        backendDrivenFilter?.innerFilters
      )}${checkValue()}${checkPlacementSesion()}`;
      params = params.replace(/^&+/, "");
      params = params.replace(/&+$/, "");

      var url_filter = `api/manage_student/navbar/?current_tab=${filters.status}&college_id=${collegeId}&${params}`;
      url_filter = url_filter.replace(/&+$/, "");

      // var url_filter = `api/manage_student/navbar/?college_id=${collegeId}${checkValue()}${searchFilter()}${departmentFilter()}${batchFilter()}${statusFilter()}${degreeFilter()}${scoreFilter()}${checkPlacementSesion()}`;
      dispatch(StudentNavbarAPI(url_filter));
    }
  }, [callNavbar]);

  const licenceData = useSelector((state) => state?.commonData?.licenceData);

  const checkForPendingRejected = () => {
    return (
      filters?.current_tab?.params === "pending_students" ||
      filters?.current_tab?.params === "rejected_students"
    );
  };

  return (
    <>
      {/* <Layout> */}
      {placementSessionList?.length > 0 && !checkForPendingRejected() && (
        <div style={{ marginTop: "5px", marginBottom: "5px" }}>
          <DropDownButton
            title="Placement Session 2021"
            placementSession={placementSession}
            setPlacementSession={setPlacementSession}
            setFilterDataText={setFilterDataText}
            show={true}
          />
        </div>
      )}
      {loadingPlacement ? (
        <CustomCircularProgress />
      ) : placementSessionList?.length > 0 ? (
        <>
          <div className={classes.grid}>
            <div>
              <Typography variant="h2">Manage Students</Typography>
              <Typography
                variant="body2"
                color="secondary"
                // style={{ marginTop: "10px" }}
              >
                Manage your all Students in one place
              </Typography>
              {/* <FormControlLabel
              value="end"
              control={<Checkbox color="primary" size="small" />}
              label={
                <Typography variant="body2" style={{ fontWeight: "bold" }}>
                  Show Data of All Active Placement Sessions
                </Typography>
              }
              labelPlacement="end"
            /> */}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              {userRolesPermission?.some(
                (item) => item.access_control_key === "manage_students"
              ) && (
                <>
                  <div style={{ display: "flex" }}>
                    {!checkForPendingRejected() && (
                      <>
                        <ImportData
                          open={openImport}
                          handleClickOpen={handleOpenImport}
                          handleClose={handleCloseImport}
                          dataLoaded={dataLoaded}
                          totalLoad={totalLoad}
                          progress={progress}
                          fileName={fileNames[0]?.name}
                          handleCancelImport={handleCancelImport}
                          resData={resData}
                        />

                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={() => {
                            if (!licenceData?.display) handleClickOpenUpload();
                            else
                              alert.error(
                                "Student license reaches its limit so you cannot import Students. Kindly connect with your Key Account Manager"
                              );
                            // handleClickOpenUpload();
                          }}
                          className={classes.buttonBase}
                          startIcon={<PublishIcon />}
                        >
                          Import
                        </Button>
                        <UploadExcelFile
                          handleClickOpen={handleClickOpenUpload}
                          handleClose={handleCloseUpload}
                          open={openUpload}
                          fileNames={fileNames}
                          setFileNames={setFileNames}
                          handleOpenImport={handleOpenImport}
                          dataRow={dataRow}
                          headerSampleFile={headerSampleFile}
                          downloadText={"Sample_Student.csv"}
                          sampleFile={sampleExcel}
                        />
                        <AddNewStudent
                          studentList={studentList}
                          currentTab={filters?.status}
                          placementSession={placementSession}
                          collegeId={collegeId}
                          setAddStudentClicked={setAddStudentClicked}
                        />
                      </>
                    )}
                    {/* <InviteCompany link={inviteLink} /> */}
                    <CopyToClipboard
                      // text={!licenceData?.display ? inviteLink : ""}
                      text={inviteLink}
                      onCopy={() =>
                        inviteLink
                          ? !licenceData?.display
                            ? alert.success("Link Copied!")
                            : alert.error(
                                "Student license reaches its limit so you cannot invite Students. Kindly connect with your Key Account Manager"
                              )
                          : alert.error(
                              "Please Add Fields from Student ERF to generate your Link"
                            )
                      }
                      // onCopy={() => (inviteLink ? alert.success("Link Copied!") : alert.error("Please Add Fields from Student ERF to generate your Link"))}
                    >
                      <Button
                        color="primary"
                        size="small"
                        variant="contained"
                        className={classes.primebtn}
                        startIcon={<SendIcon />}
                      >
                        Invite
                      </Button>
                    </CopyToClipboard>

                    {/* <CSVLink
                      data={tempStudentList}
                      style={{
                        cursor: "pointer",
                        textDecoration: "none",
                      }}
                      filename={"Students.csv"}
                    >
                      <Button
                        variant="contained"
                        size="small"
                        style={{ marginRight: "0px" }}
                        className={classes.button}
                        startIcon={<GetAppIcon />}
                      >
                        Export
                      </Button>
                    </CSVLink> */}
                    <Button
                      variant="contained"
                      size="small"
                      style={{ marginRight: "0px" }}
                      className={classes.button}
                      startIcon={<GetAppIcon />}
                      onClick={handleExport}
                    >
                      {exportLoading ? (
                        <CircularProgress
                          size={18}
                          style={{ color: "white" }}
                        />
                      ) : (
                        "Export"
                      )}
                    </Button>
                  </div>
                  {!checkForPendingRejected() && (
                    <div
                      style={{
                        marginTop: "10px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <ShareStudentDetails
                        // data={data}
                        filters={filters}
                        handleOpenShareSuccess={handleClickOpen}
                        // shareSuccessData={shareSuccessData}
                        // setShareSuccessData={setShareSuccessData}
                        selectedRowsData={selectedRowsData}
                        selectedID={selectedID}
                        setValidationData={setValidationData}
                        validationData={validationData}
                      />

                      <ShortlistEligible collegeId={collegeId} />
                      <TrackShareSuccessfully
                        open={open}
                        handleClickOpen={handleClickOpen}
                        handleClose={handleClose}
                        data={validationData}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <div style={{ marginTop: "-25px" }}>
            <StudentTabNew
              loading={loading}
              filters={filters}
              setFilters={setFilters}
              setClickSearchButton={setClickSearchButton}
              setSelectedRowsData={setSelectedRowsData}
              selectedRowsData={selectedRowsData}
              clickSearchButton={clickSearchButton}
              value={value}
              setValue={setValue}
              navbarData={navbarData}
              filterList={filterList}
              applyClickedScore={applyClickedScore}
              setApplyClickedScore={setApplyClickedScore}
              filterDataText={filterDataText}
              setFilterDataText={setFilterDataText}
              collegeId={collegeId}
              userRolesPermission={userRolesPermission}
              setCallNavbar={setCallNavbar}
              clearData={clearStudentData}
              selectedID={selectedID}
              setSelectedID={setSelectedID}
              selectedColumnFields={selectedColumnFields}
              setSelectedColumnFields={setSelectedColumnFields}
              callNavbarHigherStudies={callNavbarHigherStudies}
              setCallNavbarHigherStudies={setCallNavbarHigherStudies}
              backendDrivenFilter={backendDrivenFilter}
              setBackendDrivenFilter={setBackendDrivenFilter}
              filterData={filterData}
              filterLoading={filterLoading}
              studentList={studentList}
              setStudentList={setStudentList}
              studentListTotalCount={studentListTotalCount}
              pageData={pageData}
              setPageData={setPageData}
            />
          </div>
        </>
      ) : (
        <>
          <NoDataFuncNew
            title={"Please Add Placement Cycle First"}
            subtitle={"Click the below Button to add placement Cycle"}
            buttonText={"Add Placement Cycle"}
            height={"320px"}
            width={"320px"}
            link={"/placement-cycles"}
          />
        </>
      )}
      {/* </Layout> */}
    </>
  );
}
