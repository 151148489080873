import { Button, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DropzoneArea } from "material-ui-dropzone";
import React, { useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { ContactUs } from "../../api/ContactUs/action";
import { isEducationGroupFn } from "../../api/Domain/selector";
import { collegeDetailSelectorFn } from "../../api/SignIn/selector";
import CustomButtonCircularProgress from "../../components/CircularProgressBar/index2";
import Editor from "../../components/Editor";

const useStyles = makeStyles((_) => ({
  DropzoneArea: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    margin: 0,
    padding: 0,
  },
  upload: {
    "@media only screen and (max-width: 2000px)": {
      marginTop: "100px",
    },
    "@media only screen and (max-width: 1700px)": {
      marginTop: "70px",
    },
    "@media only screen and (max-width: 1300px)": {
      marginTop: "100px",
    },
  },
}));

export default function Contact() {
  const classes = useStyles();

  const [attachment, setAttachment] = useState([]);
  const [query, setQuery] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const alert = useAlert();
  const [loader, SetLoader] = React.useState(false);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const collegeDetail = useSelector(collegeDetailSelectorFn);
  const isEducationGroup = useSelector(isEducationGroupFn);

  const SendEmail = (e) => {
    e.preventDefault();
    if (attachment.length < 1 && !query) {
      alert.error("Fields should not be empty");
      return;
    }

    SetLoader(true);
    const formData = new FormData();

    formData.append("description", query ? query : null);
    attachment[0] && formData.append("image_1", attachment[0]);
    attachment[1] && formData.append("image_2", attachment[1]);
    attachment[2] && formData.append("image_3", attachment[2]);
    collegeDetail ? formData.append("submitted_by", collegeDetail.user_id) : alert.error("Something went wrong, Try again!!");
    if (isEducationGroup) collegeDetail ? formData.append("education_group_id", collegeDetail.education_group_id) : alert.error("Something went wrong, Try again!!");
    else collegeDetail ? formData.append("college_id", collegeDetail.college_id) : alert.error("Something went wrong, Try again!!");

    dispatch(ContactUs(SetLoader, formData, history, location, alert, setFormSubmitted));

    setQuery("");
    setAttachment([]);
    // setInterval(function () {
    //   window.location.reload(false);
    // }, 2000);
  };

  return (
    <>
      {/* <Layout> */}
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Typography variant="h2">Contact us</Typography>
          {/* <br /> */}
          <Typography variant="body2" color="secondary">
            Have any questions? We'd love to hear from you
          </Typography>
        </Grid>
        <br />

        <Grid item xs={12} sm={8}>
          <Typography variant="h6" color="secondary">
            Enter your query
          </Typography>
          <br />
          <Editor
            state={query}
            setState={setQuery}
            formSubmitted={formSubmitted}
            setFormSubmitted={setFormSubmitted}
            placeholderText="Write your Query.."
            removeAttachments={true}
          />
          <div className={classes.upload}>
            <Typography variant="h6" color="secondary">
              Upload Images
            </Typography>
            <br />
            <DropzoneArea
              key={formSubmitted ? "contact_us_1" : "contact_us_2"}
              dropzoneClass={classes.DropzoneArea}
              acceptedFiles={["image/*"]}
              dropzoneText={"Max upload up to 3 image"}
              onChange={(files) => setAttachment(files)}
            />
          </div>
          <br />
          <Button
            variant="contained"
            color="primary"
            size="medium"
            style={{
              boxShadow: "none",
              borderRadius: "8px",
              paddingLeft: "70px",
              height: "40px",
              display: "flex",
              paddingRight: "70px",
            }}
            disabled={loader}
            onClick={(e) => SendEmail(e)}
          >
            {loader ? <CustomButtonCircularProgress /> : <> Submit</>}{" "}
          </Button>
        </Grid>
      </Grid>
      {/* </Layout> */}
    </>
  );
}
