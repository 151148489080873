import axios from "axios";
import { checkForToken } from "../../constants/ApiCall";
import { BackendBaseApi } from "../../constants/constants";

export const INDUSTRY_DATA = "INDUSTRY_DATA";
export const COURSE_DATA = "COURSE_DATA";
export const SELECTED_COURSE_DATA = "SELECTED_COURSE_DATA";
export const STUDENT_LINK = "STUDENT_LINK";
export const PLACEMENT_LIST = "PLACEMENT_LIST";
export const PLACEMENT_LIST_ENDED = "PLACEMENT_LIST_ENDED";
export const TEAM_MEMBER_LIST_API = "TEAM_MEMBER_LIST_API";
export const DEGREE_DATA = "DEGREE_DATA";
export const SPEC_DATA = "SPEC_DATA";
export const NAVBAR_LIST_DATA = "NAVBAR_LIST_DATA";
export const NAVBAR_EJD_LIST = "NAVBAR_EJD_LIST";
export const LICENCE_CHECK = "LICENCE_CHECK";
export const GROUP_VIEW = "GROUP_VIEW";

export const IndustryGetAPI = (alert, history, location) => {
  return async (dispatch) => {
    const res = await axios
      .get(`${BackendBaseApi.SANCHALAN}public/company/industry/`, {
        withCredentials: true,
      })
      .catch((error) => {
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (res.data.success) {
    } else {
    }

    dispatch({
      type: INDUSTRY_DATA,
      payload: res.data.data,
    });
  };
};

export const AllCoursesApi = (alert, history, location, setLoading) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .get(`${BackendBaseApi.PRAVESH}api/saas/all_courses/`, {
        withCredentials: true,
      })
      .catch((error) => {
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (res.data.success) {
    } else {
    }
    setLoading(false);

    dispatch({
      type: COURSE_DATA,
      payload: res.data.data,
    });
  };
};

export const CoursesPostAPI = (
  data,
  setLoading,
  alert,
  history,
  location,
  college_id
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .post(
        BackendBaseApi.SANCHALAN +
          "api/chat/department/?college_id=" +
          college_id,
        data,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (res.data.success) {
      alert.success("Updated Successfully");
    } else {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
  };
};

export const CoursesGetAPI = (
  setLoading,
  alert,
  history,
  location,
  college_id
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .get(
        BackendBaseApi.SANCHALAN +
          "api/chat/department/?college_id=" +
          college_id,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);
        throw error;
      });

    if (res.data.success) {
    } else {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
    dispatch({
      type: SELECTED_COURSE_DATA,
      payload: res.data.data ? res.data.data : [],
    });
  };
};

export const CoursesGetAPI2 = (
  setLoading,
  alert,
  history,
  location,
  groupId
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .get(BackendBaseApi.SANCHALAN + "api/chat/department/?" + groupId, {
        withCredentials: true,
      })
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);
        throw error;
      });

    if (res.data.success) {
    } else {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
    dispatch({
      type: SELECTED_COURSE_DATA,
      payload: res.data.data ? res.data.data : [],
    });
  };
};

export const CoursesGetPublicAPI = (
  setLoading,
  alert,
  history,
  location,
  college_id
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .get(
        BackendBaseApi.SANCHALAN +
          "public/college_course/?college_id=" +
          college_id,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (res.data.success) {
    } else {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
    dispatch({
      type: SELECTED_COURSE_DATA,
      payload: res.data.data ? res.data.data : [],
    });
  };
};

export const InviteStudentLinkAPI = (alert, history, location) => {
  return async (dispatch) => {
    const res = await axios
      .get(
        BackendBaseApi.SANCHALAN + "api/manage_student/invite_link/",

        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (res.data.success) {
      dispatch({
        type: STUDENT_LINK,
        payload: res.data.data,
      });
    } else {
    }
  };
};

export const PlacementListAPI = (
  alert,
  history,
  location,
  setLoading,
  collegeId,
  source
) => {
  let cancelTokenObj = {};

  if (source && source?.token) {
    cancelTokenObj = {
      withCredentials: true,
      cancelToken: source?.token,
    };
  } else {
    cancelTokenObj = {
      withCredentials: true,
    };
  }
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .get(
        BackendBaseApi.SANCHALAN +
          "api/placement_cycles/placements/?single_placement=true&college_param=" +
          collegeId,
        cancelTokenObj
      )
      .catch((error) => {
        checkForToken(error, history, location, alert);
        setLoading(false);

        throw error;
      });

    if (res.data.success) {
      dispatch({
        type: PLACEMENT_LIST,
        payload: res.data.data ? res.data.data : [],
      });
    } else {
    }
    setLoading(false);
  };
};

export const PlacementListEndedAPI = (alert, history, location, collegeId) => {
  return async (dispatch) => {
    const res = await axios
      .get(
        `${BackendBaseApi.SANCHALAN}api/placement_cycles/placements/?single_placement=true&college_param=${collegeId}&is_ended=true`,

        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (res.data.success) {
      dispatch({
        type: PLACEMENT_LIST_ENDED,
        payload: res.data.data ? res.data.data : [],
      });
    } else {
    }
  };
};

export const TeamMemberListAPI = (
  alert,
  history,
  location,
  collegeId,
  userId
) => {
  return async (dispatch) => {
    const res = await axios
      .get(
        `${BackendBaseApi.SANCHALAN}api/manage_job/team_member_dropdown/?college_id=${collegeId}&user_id=${userId}`,

        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (res.data.success) {
      dispatch({
        type: TEAM_MEMBER_LIST_API,
        payload: res.data.data ? res.data.data : [],
      });
    } else {
    }
  };
};

export const DegreesGetAPI = (setLoading, collegeId) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .get(
        `${BackendBaseApi.SANCHALAN}public/degree_list/?college_id=${collegeId}`,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        setLoading(false);

        throw error;
      });

    if (res.data.success) {
      dispatch({
        type: DEGREE_DATA,
        payload: res.data.data ? res.data.data : [],
      });
    } else {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
  };
};

export const SpecGetAPI = (degId, collegeId) => {
  return async (dispatch) => {
    const res = await axios
      .get(
        `${BackendBaseApi.SANCHALAN}public/specialization_list/?college_id=${collegeId}&degree_id=${degId}`,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        throw error;
      });

    if (res.data.success) {
      dispatch({
        type: SPEC_DATA,
        payload: res.data.data ? res.data.data : [],
      });
    } else {
      alert.error("Some Error Occurred!!!");
    }
  };
};

export const NavbarSearchListAPI = (str) => {
  return async (dispatch) => {
    const res = await axios
      .get(
        `${BackendBaseApi.SANCHALAN}/api/layout/search/?search_term=${str}`,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        throw error;
      });

    if (res.data.success) {
      dispatch({
        type: NAVBAR_LIST_DATA,
        payload: res.data.data ? res.data.data : [],
      });
    } else {
    }
  };
};

export const CheckUserAPI = (search, setTeamDetailsError, teamDetailsError) => {
  var data = { email: search };

  return async (dispatch) => {
    const res = await axios
      .post(`${BackendBaseApi.PRAVESH}api/check_user/`, data, {
        withCredentials: true,
      })
      .catch((error) => {
        setTeamDetailsError({
          ...teamDetailsError,
          email: "User Already Exist",
        });

        throw error;
      });

    if (res.data.success) {
      if (res.data.data.college_user_exist || res.data.data.is_student_user) {
        setTeamDetailsError({
          ...teamDetailsError,
          email: "User Already Exist",
        });
      }
    } else {
      setTeamDetailsError({ ...teamDetailsError, email: "User Already Exist" });
    }
  };
};

export const EJDListAPI = (alert, history, location) => {
  return async (dispatch) => {
    const res = await axios
      .get(
        BackendBaseApi.SANCHALAN + "api/layout/ejd/",

        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (res.data.success) {
      dispatch({
        type: NAVBAR_EJD_LIST,
        payload: res.data.data,
      });
    } else {
    }
  };
};

export const LicenceCheckAPI = (alert, history, location) => {
  return async (dispatch) => {
    const res = await axios
      .get(
        BackendBaseApi.SANCHALAN + "api/layout/billing/",

        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (res.data.success) {
      dispatch({
        type: LICENCE_CHECK,
        payload: res.data.data,
      });
    } else {
    }
  };
};

export const GroupViewCheck = (payload) => {
  return async (dispatch) => {
    dispatch({
      type: GROUP_VIEW,
      payload: payload,
    });
  };
};
