import axios from "axios";
import { checkForToken } from "../../constants/ApiCall";
import { BackendBaseApi } from "../../constants/constants";

export const TEAMS_DATA = "TEAMS_DATA";
export const NAVBAR = "NAVBAR";
export const DESIGNATIONS = "DESIGNATIONS";
export const DESIGNATION_TEAM_LIST = "DESIGNATION_TEAM_LIST";
export const SINGLE_TEAM_MEMBER = "SINGLE_TEAM_MEMBER";
export const JOB_LIST = "JOB_LIST";
export const TEAM_INVITE_LINK = "TEAM_INVITE_LINK";
export const PUBLIC_USER_ROLE = "PUBLIC_USER_ROLE";

export const TeamsGetAPI = (
  setLoading,
  alert,
  history,
  location,
  url,
  setClickSearchButton,
  setFirstApiCall,
  setTeamList = () => {},
  source
) => {
  let cancelTokenObj = {};

  if (source && source?.token) {
    cancelTokenObj = {
      withCredentials: true,
      cancelToken: source?.token,
    };
  } else {
    cancelTokenObj = {
      withCredentials: true,
    };
  }

  return async (dispatch) => {
    setTeamList([]);
    setLoading(true);
    const res = await axios
      .get(`${BackendBaseApi.SANCHALAN}${url}`, cancelTokenObj)
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (res.data.success) {
      res.data.data.designation_list.forEach((item, index) => {
        item["id"] = index + 1;
      });
      setTeamList(res.data.data.data);
      dispatch({
        type: TEAMS_DATA,
        payload: res.data.data.data,
      });
      dispatch({
        type: DESIGNATION_TEAM_LIST,
        payload: res.data.data.designation_list,
      });
    } else {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
    setClickSearchButton(false);
    setFirstApiCall(true);
  };
};

export const TeamsGetAPI1 = (
  setLoading,
  alert,
  history,
  location,
  url,
  setClickSearchButton,
  setFirstApiCall,
  setTeamList = () => {},
  source
) => {
  let cancelTokenObj = {};

  if (source && source?.token) {
    cancelTokenObj = {
      withCredentials: true,
      cancelToken: source?.token,
    };
  } else {
    cancelTokenObj = {
      withCredentials: true,
    };
  }

  return async (dispatch) => {
    setTeamList([]);
    setLoading(true);
    try {
      const res = await axios.get(
        `${BackendBaseApi.SANCHALAN}${url}`,
        cancelTokenObj
      );

      if (res.data.success) {
      } else {
        alert.error("Some Error Occurred!!!");
      }

      return res; // Return the response here
    } catch (error) {
      setLoading(false);
      checkForToken(error, history, location, alert);
      throw error; // Rethrow the error so it can be handled later
    } finally {
      setLoading(false);
      setClickSearchButton(false);
      setFirstApiCall(true);
    }
  };
};

export const InviteLinkGetAPI = (alert, history, location) => {
  return async (dispatch) => {
    const res = await axios
      .get(`${BackendBaseApi.SANCHALAN}api/manage_team/invite_link/`, {
        withCredentials: true,
      })
      .catch((error) => {
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (res.data.success) {
      dispatch({
        type: TEAM_INVITE_LINK,
        payload: res.data.data,
      });
    } else {
      // alert.error("Some Error Occurred!!!");
    }
  };
};

export const PublicUserRoleAPI = (alert, history, location, link) => {
  return async (dispatch) => {
    const res = await axios
      .get(`${BackendBaseApi.SANCHALAN}public/user_role/?link=${link}`, {
        withCredentials: true,
      })
      .catch((error) => {
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (res.data.success) {
      dispatch({
        type: PUBLIC_USER_ROLE,
        payload: res.data.data,
      });
    } else {
      // alert.error("Some Error Occurred!!!");
    }
  };
};

export const SingleTeamsGetAPI = (
  setLoading,
  alert,
  history,
  location,
  team_member_id
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .get(
        `${BackendBaseApi.SANCHALAN}api/manage_team/view/single/?team_member_id=${team_member_id}`,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (res.data.success) {
    } else {
    }
    setLoading(false);

    dispatch({
      type: SINGLE_TEAM_MEMBER,
      payload: res.data.data,
    });
  };
};

export const TeamsPostAPI = (
  data,
  setLoading,
  alert,
  history,
  location,
  teamList,
  handleClose,
  userRoleId,
  setTeamDetails,
  setApiCalled,
  collegeId
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .post(
        BackendBaseApi.SANCHALAN +
          "api/manage_team/view/?college_id=" +
          collegeId,
        data,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);
        if (error?.response?.status !== 401) {
          alert.error("Unable to Add a student. Some Error Occurred");
        }
        throw error;
      });

    if (res.data.success) {
      alert.success("Team Member Added Successfully!!");
      if (data.user_role_id === userRoleId) {
        var arr = [...teamList];

        arr.push(res.data.data);
        dispatch({
          type: TEAMS_DATA,
          payload: arr,
        });
      }
      setTeamDetails({
        name: "",
        email: "",
        phone: "",
        designation: null,
        batch: "",
        degree: "",
        course: "",
        user_role_id: "",
      });
      setApiCalled(true);
      handleClose();
    } else {
      // alert.error("Some Error Occurred!!!");
      alert.error(res.data.error);
    }
    setLoading(false);
  };
};

export const TeamsPostPublicAPI = (
  data,
  setLoading,
  alert,
  history,
  location,
  college_id,
  setStepNo
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .post(
        BackendBaseApi.SANCHALAN + "public/add_team/?college_id=" + college_id,
        data,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);
        if (error?.response?.status !== 401) {
          alert.error("Unable to Create your Team. Some Error Occurred");
        }
        throw error;
      });

    if (res.data.success) {
      setStepNo(1);
    } else {
      alert.error(res.data.error);
    }
    setLoading(false);
  };
};

export const BlockTeamAPI = (
  data,
  alert,
  history,
  location,
  teamList,
  handleClose,
  setSelectedRowsData
) => {
  return async (dispatch) => {
    const res = await axios
      .post(`${BackendBaseApi.SANCHALAN}api/manage_team/block_team/`, data, {
        withCredentials: true,
      })
      .catch((error) => {
        checkForToken(error, history, location, alert);
        handleClose();
        throw error;
      });

    if (res.data.success) {
      alert.success(res.data.data.message);
      var arr = [...teamList];
      data.object_id.map((item) => {
        arr.forEach((item2) => {
          if (item2.id === item) item2["current_status"] = data.status;
        });
      });
      setSelectedRowsData([]);
    } else {
      alert.error("Some Error Occurred!!!");
    }
    handleClose();

    dispatch({
      type: TEAMS_DATA,
      payload: arr,
    });
  };
};

export const BlockSingleTeamAPI = (data, alert, history, location, teamObj) => {
  return async (dispatch) => {
    const res = await axios
      .post(`${BackendBaseApi.SANCHALAN}api/manage_team/block_team/`, data, {
        withCredentials: true,
      })
      .catch((error) => {
        checkForToken(error, history, location, alert);
        throw error;
      });

    if (res.data.success) {
      var obj = { ...teamObj };
      if (res.data?.data?.blocked_count > 0) obj["status"] = data?.status;

      alert.success(res.data.data.message);
      dispatch({
        type: SINGLE_TEAM_MEMBER,
        payload: obj,
      });
    } else {
      alert.error(res.data.error);
    }
  };
};

export const DeleteTeamAPI = (
  data,
  alert,
  history,
  location,
  teamList,
  handleClose,
  setCallNavbar,
  setLoader
) => {
  return async (dispatch) => {
    setLoader(true);
    const res = await axios
      .post(`${BackendBaseApi.SANCHALAN}api/manage_team/delete_team/`, data, {
        withCredentials: true,
      })
      .catch((error) => {
        checkForToken(error, history, location, alert);
        handleClose();

        throw error;
      });

    if (res.data.success) {
      alert.success(res.data.data.message);
      var arr = [...teamList];
      data.object_id.map((item) => {
        var index = arr.findIndex((item2) => item2.id === item);
        arr.splice(index, 1);
      });
      setCallNavbar(true);
    } else {
      alert.error("Some Error Occurred!!!");
    }
    handleClose();
    setLoader(false);
    dispatch({
      type: TEAMS_DATA,
      payload: arr,
    });
  };
};

export const NavbarTeamGetAPI = (
  setLoading,
  alert,
  history,
  location,
  url,
  source
) => {
  let cancelTokenObj = {};

  if (source && source?.token) {
    cancelTokenObj = {
      withCredentials: true,
      cancelToken: source?.token,
    };
  } else {
    cancelTokenObj = {
      withCredentials: true,
    };
  }
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .get(`${BackendBaseApi.SANCHALAN}${url}`, cancelTokenObj)
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);
        throw error;
      });

    if (!res.data.success) {
      alert.error("Some Error Occurred!!!");
    } else {
      res.data.data.forEach((item, index) => {
        item["tab_id"] = index;
      });

      dispatch({
        type: NAVBAR,
        payload: res.data.data,
      });
    }
    setLoading(false);
  };
};

export const DesignationTeamGetAPI = (alert, history, location) => {
  return async (dispatch) => {
    const res = await axios
      .get(`${BackendBaseApi.SANCHALAN}api/manage_team/designation_dropdown/`, {
        withCredentials: true,
      })
      .catch((error) => {
        checkForToken(error, history, location, alert);
        throw error;
      });

    if (!res.data.success) {
      alert.error("Some Error Occurred!!!");
    } else {
      dispatch({
        type: DESIGNATIONS,
        payload: res.data.data,
      });
    }
  };
};

export const TeamPlacementCycleChangeAPI = (
  data,
  alert,
  history,
  location,
  jobList,
  handleClose,
  setLoader,
  collegeId,
  setSelectedRowsData
) => {
  return async (dispatch) => {
    setLoader(true);
    const res = await axios
      .post(
        `${BackendBaseApi.SANCHALAN}api/manage_team/add_placement_cycle/?college_id=${collegeId}`,
        data,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        checkForToken(error, history, location, alert);
        handleClose();
        if (error.response.status !== 401) {
          alert.error("Some Error Occurred! Cant add to placement Cycle");
        }
        setLoader(false);

        throw error;
      });

    if (res.data.success) {
      alert.success(res.data.data.message);
      setSelectedRowsData([]);
    } else {
      alert.error("Some Error Occurred!!!");
    }
    handleClose();

    setLoader(false);
  };
};

export const TeamPlacementCycleSingleChangeAPI = (
  data,
  alert,
  history,
  location,
  teamObj,
  setLoader,
  collegeId
) => {
  return async (dispatch) => {
    setLoader(true);
    const res = await axios
      .post(
        `${BackendBaseApi.SANCHALAN}api/manage_team/add_placement_cycle/?college_id=${collegeId}`,
        data,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        checkForToken(error, history, location, alert);

        if (error.response.status !== 401) {
          alert.error("Some Error Occurred! Cant add to placement Cycle");
        }
        setLoader(false);

        throw error;
      });

    if (res.data.success) {
      alert.success(res.data.data.message);
    } else {
      alert.error("Some Error Occurred!!!");
    }

    setLoader(false);
  };
};

export const ExcelTeamPostAPI = (
  data,
  setLoading,
  alert,
  history,
  location,
  handleClose,
  setProgress,
  setDataLoaded,
  setTotalLoad,
  setresData
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .post(BackendBaseApi.SANCHALAN + "api/manage_team/upload_excel/", data, {
        withCredentials: true,
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setDataLoaded(Math.round(data.loaded / 1000000));
          setTotalLoad(Math.round(data.total / 1000000));

          setProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .catch((error) => {
        setLoading(false);
        alert.error("Upload Failed. Enter Correct File!!!");
        handleClose();

        checkForToken(error, history, location, alert);
        throw error;
      });

    if (res.data.success) {
      // alert.success(res.data.data.message);

      const resdata = res?.data?.data;
      setresData({
        total_entries: resdata?.total_entries,
        user_created_count: resdata?.user_created_count,
        email_already_exist: resdata?.email_already_exist,
        failed_entries: resdata?.error_list_count,
        flag: res?.data?.success,
        error_list: [
          ...res?.data?.data?.error_list,
          ...res?.data?.data?.already_exist_data,
        ],
      });
    } else {
      alert.error("Some Error Occurred!!!");
    }
    handleClose();

    setLoading(false);
  };
};

export const changeUserRoleAPI = (data, alert, teamObj, setLoader, rcvObj) => {
  return async (dispatch) => {
    setLoader(true);
    const res = await axios
      .post(
        `${BackendBaseApi.SANCHALAN}api/manage_team/change_user_role/`,
        data,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        if (error.response.status !== 401) {
          alert.error("Some Error Occurred! Cant add to placement Cycle");
        }
        setLoader(false);

        throw error;
      });

    if (res.data.success) {
      alert.success(res.data.data.message);
      var obj = { ...teamObj };
      obj["user_role_name"] = rcvObj?.name;
      obj["user_role_id"] = rcvObj?.id;

      dispatch({
        type: SINGLE_TEAM_MEMBER,
        payload: obj,
      });
    } else {
      alert.error("Some Error Occurred!!!");
    }

    setLoader(false);
  };
};

export const JobListDropdownAPI = (collegeId) => {
  return async (dispatch) => {
    const res = await axios
      .get(`${BackendBaseApi.SANCHALAN}api/manage_team/jobs_dropdown/`, {
        withCredentials: true,
      })
      .catch((error) => {
        throw error;
      });

    if (res.data.success) {
      dispatch({
        type: JOB_LIST,
        payload: res.data.data,
      });
    }
  };
};

export const ApproveTeamAPI = (
  data,
  alert,
  history,
  location,
  companyList,
  handleClose,
  setCallNavbar,
  setLoader
) => {
  return async (dispatch) => {
    setLoader(true);
    const res = await axios
      .post(`${BackendBaseApi.SANCHALAN}api/manage_team/verify_member/`, data, {
        withCredentials: true,
      })
      .catch((error) => {
        checkForToken(error, history, location, alert);
        handleClose();
        setLoader(false);
        throw error;
      });

    if (res.data.success) {
      alert.success(res.data.data.message);
      var arr = [...companyList];
      data.team_id.map((item) => {
        var index = arr.findIndex((item2) => item2.id === item);
        arr.splice(index, 1);
      });
      setCallNavbar(true);
    } else {
      alert.error("Some Error Occurred!!!");
    }
    handleClose();
    setLoader(false);
    dispatch({
      type: TEAMS_DATA,
      payload: arr,
    });
  };
};

export const ApproveSingleTeamAPI = (
  data,
  alert,
  history,
  location,
  setLoading
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .post(`${BackendBaseApi.SANCHALAN}api/manage_team/verify_member/`, data, {
        withCredentials: true,
      })
      .catch((error) => {
        checkForToken(error, history, location, alert);
        throw error;
      });

    if (res.data.success) {
      alert.success(res.data.data.message);
      setLoading(true);
      history.push("/manage-team");
    } else {
      alert.error("Some Error Occurred!!!");
      setLoading(true);
    }
    setLoading(true);
  };
};
