import {
  Button,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { themeSelectorFn } from "../../api/Domain/selector";
import { getmoreData, refreshData } from "../../api/NoticeBoard/action";
import { overviewGet } from "../../api/Overview/action";
import {
  collegeDetailSelectorFn,
  collegeGroupListSelectorFn,
  isCollegeGroupFn,
  userRolesActionsFn,
} from "../../api/SignIn/selector";
import { ReactComponent as NoFeed } from "../../assets/svg/blogpost.svg";
import Feed from "../../components/Cards/Feed";
import SearchFeed from "../../components/Cards/Feed/SearchFeed";
import SmallCards from "../../components/Cards/SmallCards";
import CustomCircularProgress from "../../components/CircularProgressBar";
import CustomButtonCircularProgress from "../../components/CircularProgressBar/index2";
import AddFeedDialog from "../../components/DialogSection/AddFeedDialog";
import GroupListDropdown from "../../components/Input/DropDownButton/GroupListDropdown";
import { generateCsvForParams } from "../../utility/commaSeparatedStrings";
import checkSelectedGroup from "../../utility/getGroupIdParam";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    boxShadow: "none",
    width: "auto",
    height: "50px",
    borderRadius: "8px",
    border: ".5px solid #e2e6ea",
  },
  button: {
    display: "flex",
    borderRadius: "8px",
    height: "50px",
    color: "#fff",
    paddingLeft: "50px",
    paddingRight: "50px",
    boxShadow: "none",
  },
  input: {
    marginLeft: theme.spacing(5),
    flex: 1,
  },
  DropzoneArea: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    margin: 0,
    padding: 0,
    height: "50%",
  },
  drop: {
    display: "flex",
    margin: "10px",
  },
  addbtn: {
    display: "flex",
    border: "dashed",
  },
  imagedata: {
    display: "flex",
    width: 150,
    backgroundColor: "#fff",
    height: 80,
    borderRadius: "8px",
    justifyContent: "center",
  },
  imgdata: {
    margin: "auto",
    maxWidth: "100%",
    maxHeight: "100%",
  },

  fixedPosition: {
    position: "fixed",
    left: "66%",
    maxHeight: 650,
    overflowY: "scroll",
    display: "flex",
    alignItems: "flex-end",
    flexDirection: "column",
    width: "28%",
  },
  "@media only screen and (min-width: 1700px)": {
    fixedPosition: {
      position: "fixed",
      left: "66%",
      maxHeight: 900,
      overflowY: "scroll",
      width: "28%",
    },
  },
  lay: {
    width: "100%",
    "@media only screen and (max-width: 2000px)": {
      height: "250px",
    },
    "@media only screen and (max-width: 1700px)": {
      height: "150px",
    },
    "@media only screen and (max-width: 1300px)": {
      height: "100px",
    },
  },
  feeddiv: {
    overflowX: "hidden",
    position: "relative",
    "@media only screen and (max-width: 2000px)": {
      height: "700px",
    },
    "@media only screen and (max-width: 1700px)": {
      height: "550px",
    },
    "@media only screen and (max-width: 1300px)": {
      height: "400px",
    },
  },
  feeddata: {
    padding: "30px",
    borderRadius: "8px",
    overflowX: "hidden",
    position: "relative",
    boxShadow: "0 1px 2px 0 rgba(44, 39, 56, 0.08)",
  },
  feeddatatwo: {
    padding: "30px",
    borderRadius: "8px",
    overflowX: "hidden",
    position: "relative",
    boxShadow: "0 1px 2px 0 rgba(44, 39, 56, 0.08)",
    "@media only screen and (max-width: 2000px)": {
      height: "600px",
    },
    "@media only screen and (max-width: 1400px)": {
      height: "510px",
    },
    "@media only screen and (max-width: 1300px)": {
      height: "400px",
    },
  },

  viewgrid: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
}));

function NewsFeedGroup() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [editor, setEditor] = useState("");
  const [file, setFile] = useState(null);
  const [file1, setFile1] = useState(null);
  const alert = useAlert();
  const [editImages, seteditImages] = useState([]);
  const [editImages1, seteditImages1] = useState([]);
  const hiddenFileInput = React.useRef(null);
  const [attachment, setAttachment] = useState(null);
  const [loadAgain, setLoadAgain] = useState(false);
  const [publishing, setPublishing] = useState(false);
  const [editData, setEditData] = useState(null);
  const [loader, setLoader] = useState(true);
  const [feed_id, setFeedid] = useState("");
  const [next, setNext] = useState("");
  const [count, setCount] = useState(null);
  const [deletedImages, setDeletedImages] = useState([]);
  const [deletedDoc, setDeleteddoc] = useState(false);
  const [images, setImages] = React.useState([]);
  const feed = useSelector((state) => state?.feed.feed);
  const collegeDetail = useSelector(collegeDetailSelectorFn);
  const theme_change = useSelector(themeSelectorFn);
  const userRolesPermission = useSelector(userRolesActionsFn);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const collegeGroupList = useSelector(collegeGroupListSelectorFn);
  const isCollegeGroup = useSelector(isCollegeGroupFn);
  const collegeDetails = useSelector(collegeDetailSelectorFn);

  useEffect(() => {
    if (loadAgain) {
      setEditor("");
      setImages([]);
      seteditImages([]);
      seteditImages1([]);
      setFile1(null);
      setFile(null);
      refreshData(
        setLoader,
        alert,
        history,
        location,
        setLoadAgain,
        setNext,
        setCount,
        dispatch
      );
    }
  }, [loadAgain]);

  useEffect(() => {
    if (editData !== null) {
      setEditor(editData?.content + String(editData?.tags));
      seteditImages(editData?.images);
      seteditImages1(editData?.images);
      if (editData?.documents.length >= 1) {
        setFile1(editData?.documents);
      }
      setFeedid(editData?.feed_id);
      handleClickOpen();
    }
  }, [editData]);

  useEffect(() => {
    if (selectedGroup) {
      var url = `api/campus_feed/feed/?${checkSelectedGroup(
        isCollegeGroup,
        selectedGroup,
        collegeGroupList
      )}`;
      refreshData(
        setLoader,
        alert,
        history,
        location,
        setLoadAgain,
        setNext,
        setCount,
        dispatch,
        url
      );
      dispatch(
        overviewGet(alert, history, location, collegeDetail?.college_id, null)
      );
    }
  }, [selectedGroup]);

  useEffect(() => {
    var url = `api/campus_feed/feed/?${checkSelectedGroup(
      isCollegeGroup,
      selectedGroup,
      collegeGroupList
    )}`;
    refreshData(
      setLoader,
      alert,
      history,
      location,
      setLoadAgain,
      setNext,
      setCount,
      dispatch,
      url
    );
    dispatch(
      overviewGet(alert, history, location, collegeDetail?.college_id, null)
    );
  }, []);

  const [newCollegeGroupList, setNewCollegeGroupList] = useState([]);
  const [loadmore, setLoadmore] = useState(false);

  useEffect(() => {
    if (collegeGroupList?.length > 1 && isCollegeGroup) {
      setNewCollegeGroupList(
        [{ id: "All", name: "All Groups" }].concat(collegeGroupList)
      );
    } else setNewCollegeGroupList(collegeGroupList);
  }, [collegeGroupList?.length]);

  const loadMoredata = () => {
    getmoreData(
      next,
      alert,
      history,
      location,
      setNext,
      setCount,
      feed,
      dispatch,
      setLoadmore
    );
  };

  const overview = useSelector((state) => state.overview.overviewdata);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      {collegeGroupList?.length > 0 && (
        <div style={{ marginTop: "5px", marginBottom: "5px" }}>
          <GroupListDropdown
            title="Group"
            state={selectedGroup}
            setState={setSelectedGroup}
            groupList={newCollegeGroupList}
          />
        </div>
      )}
      {loader ? (
        <CustomCircularProgress />
      ) : (
        <div>
          <Grid container spacing={5}>
            <Grid item xs={7}>
              <div id="scrollableDiv" style={{ minHeight: "500px" }}>
                <div>
                  <Typography variant="h2">Campus Feed</Typography>
                  <Typography
                    variant="body2"
                    color="secondary"
                    style={{
                      //   marginTop: "7px",
                      marginBottom: "20px",
                      fontWeight: "bold",
                    }}
                  >
                    Inform your Students all at once
                  </Typography>
                </div>
                <Paper className={classes.feeddata}>
                  {userRolesPermission?.some(
                    (item) => item.access_control_key === "create_feed"
                  ) && (
                    <SearchFeed
                      open={open}
                      handleClickOpen={handleClickOpen}
                      handleClose={handleClose}
                    />
                  )}

                  <Feed
                    data={feed?.length ? feed : []}
                    state={editData}
                    setState={setEditData}
                    userRolesPermission={userRolesPermission}
                    dialogOpen={handleClickOpen}
                  />
                  {feed && feed.length === 0 ? (
                    <div style={{ textAlign: "center" }}>
                      <NoFeed
                        height="320px"
                        width="320px"
                        alt="404 Error"
                        fill={
                          theme_change?.main ? theme_change?.main : "#007BFF"
                        }
                      />
                      <Typography> No Feed Available</Typography>
                    </div>
                  ) : feed.length === count ? (
                    <p style={{ textAlign: "center" }}>
                      <h4> Yay! You have seen it all</h4>
                    </p>
                  ) : (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        onClick={loadMoredata}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        color="primary"
                      >
                        {loadmore ? (
                          <CustomButtonCircularProgress isWhite={false} />
                        ) : (
                          "Load More"
                        )}
                      </Button>
                    </div>
                  )}
                  {/* </InfiniteScroll> */}
                </Paper>
              </div>
            </Grid>
            <Grid item xs={4} className={classes.fixedPosition}>
              <div style={{ padding: "12px", marginBottom: "15px" }}>
                <AddFeedDialog
                  open={open}
                  handleClickOpen={handleClickOpen}
                  handleClose={handleClose}
                  data={feed}
                  state={editData}
                  setState={setEditData}
                  setNext={setNext}
                  setCount={setCount}
                />
              </div>
              <Paper
                className={classes.feeddatatwo}
                style={{
                  padding: "20px",
                  width: "100%",
                  borderRadius: "8px",
                  overflowX: "hidden",
                  position: "relative",
                  boxShadow: "0 1px 2px 0 rgba(44, 39, 56, 0.08)",
                }}
              >
                <Grid container wrap="nowrap" spacing={2}>
                  <Grid item>
                    <Typography variant="h6">On-Campus Jobs</Typography>
                    <Typography
                      variant="body1"
                      style={{ color: "#181818", marginTop: "5px" }}
                    >
                      {overview?.ongoing?.count}/
                      {overview?.ongoing?.total_count}
                    </Typography>
                  </Grid>
                  {overview?.ongoing?.count ? (
                    <Grid item xs className={classes.viewgrid}>
                      <Button
                        color="primary"
                        size="small"
                        className={classes.view}
                        onClick={() => history.push("/manage-jobs")}
                      >
                        View All
                      </Button>
                    </Grid>
                  ) : null}
                </Grid>
                <Divider style={{ marginTop: "10px", marginBottom: "20px" }} />
                <SmallCards data={overview?.ongoing?.jobs} />
                {/* </InfiniteScroll> */}
              </Paper>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
}

export default NewsFeedGroup;
