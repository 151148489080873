import { Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { EDGCoursesAPI } from "../../../api/University/APIs/action";
import UplacementCycle from "../../../components/Cards/UniversityGraph/UPlcementCycle";
import CustomCircularProgress from "../../../components/CircularProgressBar";
import { collegeGroupListSelectorFn } from "../../../api/SignIn/selector";
import GroupListDropdown from "../../../components/Input/DropDownButton/GroupListDropdown";

function UniversityCourse() {
  const dispatch = useDispatch();
  const alert = useAlert();
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const collegeGroupList = useSelector(collegeGroupListSelectorFn);
  const [filteredCourses, setFilteredCourses] = useState([]);

  useEffect(() => {
    dispatch(EDGCoursesAPI(setLoading, alert, history, location));
  }, []);

  const collegeCoursesList = useSelector(
    (state) => state?.EDGCommon?.edgCourses
  );

  useEffect(() => {
    if (collegeGroupList && collegeGroupList.length) {
      setSelectedGroup(collegeGroupList[0]);
    }
  }, [collegeGroupList]);

  useEffect(() => {
    if (selectedGroup) {
      Object.keys(selectedGroup).map((item) => {
        if (item === "college_id_list") {
          let newCourses = [];

          selectedGroup[item]?.forEach((item) => {
            collegeCoursesList?.forEach((item2) => {
              if (item?.id === item2?.id) {
                newCourses.push(item2);
              }
            });
          });
          setFilteredCourses(newCourses);
        }
      });
    }
  }, [selectedGroup, collegeCoursesList]);

  return (
    <>
      {/* <UniversityLayout> */}
      {collegeGroupList?.length > 0 && (
        <div style={{ marginTop: "5px", marginBottom: "5px" }}>
          <GroupListDropdown
            title="Group"
            state={selectedGroup}
            setState={setSelectedGroup}
            groupList={collegeGroupList}
          />
        </div>
      )}
      {loading ? (
        <CustomCircularProgress />
      ) : (
        <>
          <Typography variant="h2">Courses</Typography>
          <div style={{ marginTop: "20px" }}>
            <Grid container spacing={5}>
              {/* {collegeCoursesList?.length > 0 &&
                collegeCoursesList?.map((item) => ( */}
              {filteredCourses?.length > 0 &&
                filteredCourses?.map((item) => (
                  <Grid item xs={4}>
                    <UplacementCycle
                      item={item}
                      countTitle={"Degrees"}
                      count={item?.degree_count}
                      link={`/college-courses`}
                      linkTitle={"View All Courses"}
                      from={"Courses"}
                      secondaryCountTitle={"Courses"}
                      secondaryCount={item?.course_count}
                    />{" "}
                  </Grid>
                ))}
            </Grid>
          </div>
        </>
      )}
      {/* </UniversityLayout> */}
    </>
  );
}

export default UniversityCourse;
