import {
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AddIcon from "@material-ui/icons/Add";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CloseIcon from "@material-ui/icons/Close";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import pSBC from "shade-blend-color";
import { collegeDetailSelectorFn, entityInfoSelectorFn } from "../../../api/SignIn/selector";
import {
  EDGCollegeGroupAddAPI,
  EDGCollegeGroupsAPI,
} from "../../../api/University/APIs/action";

const useStyles = makeStyles((theme) => ({
  root1: {
    borderRadius: "8px",
    border: ".5px solid #707070",
    boxShadow: "none",
  },
  button: {
    display: "flex",
    borderRadius: "8px",
    boxShadow: "none",
    height: "48px",
    fontSize: "16px",
    paddingLeft: "50px",
    paddingRight: "50px",
  },
  btngrid: {
    // paddingRight: "25px",
    display: "flex",
    //paddingLeft: "25px",
    justifyContent: "space-around",
    paddingBottom: "35px",
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "15px",
  },
  add: {
    //padding: "20px",
    paddingTop: "20px",
    paddingLeft: "30px",
    paddingRight: "30px",
    width: "500px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  circularProgress: {
    color: theme.palette.common.white,
  },
  checkedIcon: {
    color: theme.palette.primary.main,
    fontSize: 14,
  },
  chip: {
    margin: theme.spacing(0.7),
    color: theme.palette.primary.main,
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    boxShadow: "0 1px 2px 0 rgba(44, 39, 56, 0.08)",
    border: `solid 0.5px ${theme.palette.primary.main}`,
    borderRadius: "8px",
  },
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
}));

export default function AddGroups({
  open,
  handleClickOpen,
  handleClose,
  selectedCurrentItem,
  setSelectedCurrentItem,
  collegeList1,
  adminList1,
  groupList,
  collegeDetails,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const icon = <CheckBoxOutlineBlankIcon style={{ fontSize: 14 }} />;
  const checkedIcon = <CheckBoxIcon className={classes.checkedIcon} />;
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const collegeDetail = useSelector(collegeDetailSelectorFn);
  const entityInfo = useSelector(entityInfoSelectorFn);
 
  const [showMore, setShowMore] = React.useState(false);
  const [showMoreAdmins, setShowMoreAdmins] = React.useState(false);

  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);

  const alert = useAlert();
  const history = useHistory();
  const location = useLocation();
  const placementList = useSelector((state) => state.PlacementCycles.details);
  const dispatch = useDispatch();
  const initialGroupData = {
    group_name: null,
    college_list: [],
    admin_list: [],
    is_member_present: false,
    new_member_data: {
      name: null,
      email: null,
      phone: null,
      designation: null,
    },
  };

  const [collegeList, setCollegeList] = useState([]);
  const [adminList, setAdminList] = useState([]);

  useEffect(() => {
    setCollegeList(collegeList1);
    setAdminList(adminList1);
  }, []);

  const [groupData, setGroupData] = useState({ ...initialGroupData });
  const [groupDataError, setGroupDataError] = useState({
    group_name: null,
    college_list: null,
    admin_list: null,
    name: null,
    phone: null,
    email: null,
    designation: null,
  });

  const validateData = () => {
    var is_valid = true;
    var group_name_error = null;
    var college_error = null;
    var admin_error = null;
    var name_error = null;
    var email_error = null;
    var phone_error = null;
    var designation_error = null;
    if (!groupData?.group_name) {
      group_name_error = "Enter Group Name";
      is_valid = false;
    }
    if (groupData?.college_list?.length === 0) {
      college_error = `Please Select at least one ${entityInfo?.college}`;
      is_valid = false;
    }
    if (groupData?.admin_list?.length === 0 && !groupData?.is_member_present) {
      admin_error = "Please Select at least one Admin List";
      is_valid = false;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\d{10}$/;
    if (groupData?.is_member_present) {
      if (!groupData?.new_member_data?.name) {
        name_error = "Please Enter Name";
        is_valid = false;
      }
      if (!groupData?.new_member_data?.email) {
        email_error = "Please Enter Name";
        is_valid = false;
      }
      if (!emailRegex.test(groupData.new_member_data.email)) {
        email_error = "Please Enter a Valid Email Address";
        is_valid = false;
      }
      if (!groupData?.new_member_data?.phone) {
        phone_error = "Please Enter Phone Number";
        is_valid = false;
      }
      if (!emailRegex.test(groupData.new_member_data.email)) {
        email_error = "Please Enter a Valid Email Address";
        is_valid = false;
      }
      if (!groupData?.new_member_data?.designation) {
        designation_error = "Please Enter Designation";
        is_valid = false;
      }
    }

    setGroupDataError({
      ...groupDataError,
      group_name: group_name_error,
      college_list: college_error,
      admin_list: admin_error,
      name: name_error,
      email: email_error,
      phone: phone_error,
      designation: designation_error,
    });

    return is_valid;
  };

  const handleSubmit = () => {
    if (validateData()) {
      var college_arr = [];
      var admin_arr = [];
      groupData?.college_list?.forEach((item) => {
        college_arr.push({ id: item.id, is_active: true });
      });
      groupData?.admin_list?.forEach((item) => {
        admin_arr.push(item.id);
      });
      var data = {
        name: groupData?.group_name,
        college_ids: college_arr,
        college_group_admin_list: admin_arr,
        education_group_id: collegeDetail?.education_group_id,
        checkbox: groupData?.is_member_present,
      };
      if (groupData?.is_member_present) {
        data.member_name = groupData?.new_member_data?.name;
        data.email = groupData?.new_member_data?.email;
        data.phone = groupData?.new_member_data?.phone;
        data.designation = groupData?.new_member_data?.designation;
      }
      dispatch(
        EDGCollegeGroupAddAPI(
          setLoading,
          alert,
          history,
          handleClose,
          location,
          data,
          groupList
        )
      )
        .then(() => {
          dispatch(
            EDGCollegeGroupsAPI(
              setLoading1,
              alert,
              history,
              location,
              collegeDetails?.education_group_id
            )
          );
          setGroupData({
            group_name: null,
            college_list: [],
            admin_list: [],
            is_member_present: false,
            new_member_data: {
              name: null,
              email: null,
              phone: null,
              designation: null,
            },
          });
          setAdminList([]);
          setCollegeList([]);
          const resetCollege = () => {
            var arr = [...collegeList];
            arr.map((item) => {
              item.is_selected = false;
            });
            setCollegeList(arr);
          };
          resetCollege();

          const resetAdmins = () => {
            let arr = [...adminList];
            arr.map((item) => {
              item.is_selected = false;
            });

            setAdminList(arr);
          };
          resetAdmins();
        })
        .catch((err) => {
          const errorMessage = err.response.data.message || "An error occurred";
          alert.error(errorMessage);
          console.log("Error :", err);
        });
    }
  };

  const handleDeleteCollege = (id, index) => {
    var arr = [...collegeList];
    if (index !== -1) {
      arr[index].is_selected = !arr[index].is_selected;
      setCollegeList(arr);
    }
  };
  const handleDeleteAdmins = (id, index) => {
    var arr = [...adminList];
    if (index !== -1) {
      arr[index].is_selected = !arr[index].is_selected;
      setAdminList(arr);
    }
  };

  useEffect(() => {
    if (collegeList?.length) {
      var new_list = collegeList?.filter((i) => i.is_selected);
      setGroupData({ ...groupData, college_list: new_list });
    }
  }, [collegeList]);

  useEffect(() => {
    if (adminList?.length) {
      var new_list = adminList?.filter((i) => i.is_selected);
      setGroupData({ ...groupData, admin_list: new_list });
    }
  }, [adminList]);

  return (
    <div>
      <Button
        onClick={() => {
          handleClickOpen();
          setSelectedCurrentItem(null);
        }}
        variant="contained"
        color="primary"
        style={{
          borderRadius: "8px",
          boxShadow: "none",
          height: "40px",
          fontSize: "16px",
          display: "flex",
          fontWeight: "normal",
          paddingLeft: "50px",
          paddingRight: "50px",
        }}
        startIcon={<AddIcon />}
      >
        New Group
      </Button>

      <Dialog
        fullScreen={fullScreen}
        maxWidth="md"
        PaperProps={{ classes: { root: classes.root1 } }}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div className={classes.add}>
          <div
            style={{
              // padding: "10px",
              //   paddingLeft: "20px",
              display: "flex",
              marginBottom: "30px",
              marginLeft: "60px",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Typography variant="h6">
              {selectedCurrentItem ? "Edit" : "Add"} {entityInfo?.college} Group
            </Typography>
          </div>
          <TextField
            variant="outlined"
            InputLabelProps={{
              className: classes.floatingLabelFocusStyle,
            }}
            inputProps={{
              style: { padding: "16px" },
            }}
            value={groupData?.group_name}
            onChange={(e) => {
              setGroupData({ ...groupData, group_name: e.target.value });
              setGroupDataError({ ...groupDataError, group_name: null });
            }}
            error={groupDataError?.group_name ? true : false}
            helperText={<span>{groupDataError?.group_name}</span>}
            InputProps={{ classes: { root: classes.inputRoot } }}
            style={{ width: "100%", marginBottom: 30 }}
            label={`${entityInfo?.college} Group Name`}
            name={`${entityInfo?.college} Group Name`}
          />

          <>
            <Autocomplete
              multiple
              id="checkboxes-tags-demo"
              disableCloseOnSelect
              options={collegeList ? collegeList : []}
              getOptionLabel={(option) => option?.name}
              style={{ width: "100%" }}
              // value={placementCycle}

              renderTags={(tagValue, getTagProps) => {
                return tagValue?.map((option, index) => <></>);
              }}
              renderOption={(option, { selected }) => (
                <React.Fragment>
                  <FormGroup>
                    <FormControlLabel
                      style={{ width: 600 }}
                      control={
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          checked={option?.is_selected}
                          onChange={(e) => {
                            setGroupDataError({
                              ...groupDataError,
                              college_list: null,
                            });

                            var arr = [...collegeList];
                            var index = arr.findIndex(
                              (i) => i.id === option?.id
                            );
                            arr[index].is_selected = e.target.checked;
                            setCollegeList(arr);
                          }}
                          name="gilad"
                        />
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setGroupDataError({
                          ...groupDataError,
                          college_list: null,
                        });

                        var arr = [...collegeList];
                        var index = arr.findIndex((i) => i.id === option?.id);
                        arr[index].is_selected = e.target.checked;
                        setCollegeList(arr);
                      }}
                      label={
                        <span style={{ fontSize: 13 }}>{option.name}</span>
                      }
                    />
                  </FormGroup>
                </React.Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={`Select ${entityInfo?.college}s in Group`}
                  inputProps={{
                    ...params.inputProps,
                  }}
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                  error={groupDataError?.college_list ? true : false}
                  helperText={<span>{groupDataError?.college_list}</span>}
                />
              )}
            />

            <div component="ul" className={classes.root}>
              {!showMore
                ? groupData?.college_list?.slice(0, 5).map((data, index) => {
                    let icon;

                    // if (data.label === "React") {
                    //   icon = <TagFacesIcon />;
                    // }

                    return (
                      <li key={data.id}>
                        <Chip
                          variant="outlined"
                          color="primary"
                          size="small"
                          onDelete={() => handleDeleteCollege(data.id, index)}
                          deleteIcon={<CloseIcon />}
                          label={data.name}
                          className={classes.chip}
                        />
                      </li>
                    );
                  })
                : groupData?.college_list?.map((data, index) => {
                    let icon;

                    // if (data.label === "React") {
                    //   icon = <TagFacesIcon />;
                    // }

                    return (
                      <li key={data.id}>
                        <Chip
                          variant="outlined"
                          color="primary"
                          size="small"
                          onDelete={() => handleDeleteCollege(data.id, index)}
                          deleteIcon={<CloseIcon />}
                          label={data.name}
                          className={classes.chip}
                        />
                      </li>
                    );
                  })}
            </div>
            <div>
              {groupData?.college_list?.length > 5 && (
                <Button
                  className={classes.button}
                  onClick={() => {
                    setShowMore(!showMore);
                  }}
                  style={{
                    padding: "5px",
                    marginLeft: "7px",
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                  endIcon={showMore ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  color="primary"
                >
                  {showMore ? "Hide" : "View All"}
                </Button>
              )}
            </div>
          </>
          <br />
          <>
            <Autocomplete
              multiple
              id="checkboxes-tags-demo"
              disableCloseOnSelect
              disabled={groupData?.is_member_present}
              options={adminList}
              getOptionLabel={(option) => option.name}
              style={{ width: "100%" }}
              // value={placementCycle}

              renderTags={(tagValue, getTagProps) => {
                return tagValue.map((option, index) => <></>);
              }}
              renderOption={(option, { selected }) => (
                <React.Fragment>
                  <FormGroup>
                    <FormControlLabel
                      style={{ width: 600 }}
                      control={
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          checked={option.is_selected}
                          onChange={(e) => {
                            setGroupDataError({
                              ...groupDataError,
                              admin_list: null,
                            });

                            var arr = [...adminList];
                            var index = arr.findIndex(
                              (i) => i.id === option?.id
                            );
                            arr[index].is_selected = e.target.checked;
                            setAdminList(arr);
                          }}
                          name="gilad"
                        />
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setGroupDataError({
                          ...groupDataError,
                          admin_list: null,
                        });

                        var arr = [...adminList];
                        var index = arr.findIndex((i) => i.id === option?.id);
                        arr[index].is_selected = e.target.checked;
                        setAdminList(arr);
                      }}
                      label={
                        <span style={{ fontSize: 13 }}>{option.name}</span>
                      }
                    />
                  </FormGroup>
                </React.Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label={`Select Admins for ${entityInfo?.college} Groups`}
                  inputProps={{
                    ...params.inputProps,
                  }}
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                  }}
                  error={groupDataError?.admin_list ? true : false}
                  helperText={<span>{groupDataError?.admin_list}</span>}
                />
              )}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={groupData?.is_member_present}
                  onChange={(e) => {
                    setGroupData({
                      ...groupData,
                      is_member_present: e.target.checked,
                    });
                    var arr = [...adminList];

                    arr.forEach((item) => {
                      item["is_selected"] = false;
                    });
                    setAdminList(arr);
                  }}
                  name="checkedB"
                  size="small"
                  color="primary"
                />
              }
              label={
                <Typography variant="body2" style={{ fontWeight: "bold" }}>
                  Member not present?
                </Typography>
              }
            />
            <div component="ul" className={classes.root}>
              {!showMoreAdmins
                ? groupData?.admin_list?.slice(0, 5).map((data, index) => {
                    let icon;

                    // if (data.label === "React") {
                    //   icon = <TagFacesIcon />;
                    // }

                    return (
                      <li key={data.id}>
                        <Chip
                          variant="outlined"
                          color="primary"
                          size="small"
                          onDelete={() => handleDeleteAdmins(data.id, index)}
                          deleteIcon={<CloseIcon />}
                          label={data.name}
                          className={classes.chip}
                        />
                      </li>
                    );
                  })
                : groupData?.admin_list?.map((data, index) => {
                    let icon;

                    // if (data.label === "React") {
                    //   icon = <TagFacesIcon />;
                    // }

                    return (
                      <li key={data.id}>
                        <Chip
                          variant="outlined"
                          color="primary"
                          size="small"
                          onDelete={() => handleDeleteAdmins(data.id, index)}
                          deleteIcon={<CloseIcon />}
                          label={data.name}
                          className={classes.chip}
                        />
                      </li>
                    );
                  })}
            </div>
            <div>
              {groupData?.admin_list?.length > 5 && (
                <Button
                  className={classes.button}
                  onClick={() => {
                    setShowMoreAdmins(!showMoreAdmins);
                  }}
                  style={{
                    padding: "5px",
                    marginLeft: "7px",
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                  endIcon={
                    showMoreAdmins ? <ExpandLessIcon /> : <ExpandMoreIcon />
                  }
                  color="primary"
                >
                  {showMoreAdmins ? "Hide" : "View All"}
                </Button>
              )}
            </div>
          </>
          {groupData?.is_member_present && (
            <>
              <TextField
                variant="outlined"
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                inputProps={{
                  style: { padding: "16px" },
                }}
                value={groupData?.new_member_data?.name}
                onChange={(e) => {
                  setGroupData({
                    ...groupData,
                    new_member_data: {
                      ...groupData?.new_member_data,
                      name: e.target.value,
                    },
                  });
                  setGroupDataError({ ...groupDataError, name: null });
                }}
                error={groupDataError?.name ? true : false}
                helperText={<span>{groupDataError?.name}</span>}
                InputProps={{ classes: { root: classes.inputRoot } }}
                style={{ width: "100%", marginBottom: 30 }}
                label="Name"
                name="Name"
              />
              <TextField
                variant="outlined"
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                inputProps={{
                  style: { padding: "16px" },
                }}
                value={groupData?.new_member_data?.email}
                onChange={(e) => {
                  setGroupData({
                    ...groupData,
                    new_member_data: {
                      ...groupData?.new_member_data,
                      email: e.target.value,
                    },
                  });
                  setGroupDataError({ ...groupDataError, email: null });
                }}
                error={groupDataError?.email ? true : false}
                helperText={<span>{groupDataError?.email}</span>}
                InputProps={{ classes: { root: classes.inputRoot } }}
                style={{ width: "100%", marginBottom: 30 }}
                label="Email"
                name="Email"
              />

              <TextField
                variant="outlined"
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                type="number"
                inputProps={{
                  style: { padding: "16px" },
                }}
                value={groupData?.new_member_data?.phone}
                onChange={(e) => {
                  setGroupData({
                    ...groupData,
                    new_member_data: {
                      ...groupData?.new_member_data,
                      phone: e.target.value,
                    },
                  });
                  setGroupDataError({ ...groupDataError, phone: null });
                }}
                error={groupDataError?.phone ? true : false}
                helperText={<span>{groupDataError?.phone}</span>}
                InputProps={{ classes: { root: classes.inputRoot } }}
                style={{ width: "100%", marginBottom: 30 }}
                label="Phone No."
                name="Phone"
              />

              <TextField
                variant="outlined"
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                inputProps={{
                  style: { padding: "16px" },
                }}
                value={groupData?.new_member_data?.designation}
                onChange={(e) => {
                  setGroupData({
                    ...groupData,
                    new_member_data: {
                      ...groupData?.new_member_data,
                      designation: e.target.value,
                    },
                  });
                  setGroupDataError({ ...groupDataError, designation: null });
                }}
                error={groupDataError?.designation ? true : false}
                helperText={<span>{groupDataError?.designation}</span>}
                InputProps={{ classes: { root: classes.inputRoot } }}
                style={{ width: "100%", marginBottom: 30 }}
                label="Designation"
                name="Designation"
              />
            </>
          )}
          <br />
          <div className={classes.btngrid}>
            <Button
              variant="outlined"
              className={classes.button}
              onClick={handleClose}
              color="primary"
            >
              Cancel
            </Button>

            <Button
              autoFocus
              className={classes.button}
              variant="contained"
              style={{ marginLeft: "15px", minWidth: "140px" }}
              color="primary"
              onClick={() => {
                if (!loading) handleSubmit();
              }}
            >
              {loading ? (
                <CircularProgress
                  className={classes.circularProgress}
                  size={14}
                />
              ) : (
                <>Add</>
              )}{" "}
            </Button>
          </div>
        </div>
        {/* </DialogActions> */}
      </Dialog>
    </div>
  );
}
