import { generateCsvForParams } from "./commaSeparatedStrings";

const checkSelectedGroup = (
  isCollegeGroup,
  selectedGroup,
  collegeGroupList
) => {
  if (isCollegeGroup) {
    if (!selectedGroup && collegeGroupList?.length === 1) {
      return `&group_id=${collegeGroupList[0]?.id}`;
    } else if (selectedGroup && selectedGroup.id !== "All") {
      return `&group_id=${selectedGroup.id}`;
    } else {
      return `&group_id=${generateCsvForParams(
        collegeGroupList?.map((item) => item.id)
      )}`;
    }
  } else {
    if (selectedGroup && selectedGroup.id !== "All") {
      return `&group_id=${selectedGroup.id}`;
    } else {
      return ``;
    }
  }
};

export default checkSelectedGroup;
