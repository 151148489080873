import {
  Button,
  CircularProgress,
  Dialog,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { PlacementPostAPI } from "../../../api/PlacementCycles/action";
import Calendar from "../../Input/Calendar/index3";

const useStyles = makeStyles((theme) => ({
  root1: {
    borderRadius: "8px",
    border: ".5px solid #707070",
    boxShadow: "none",
  },
  button: {
    display: "flex",
    borderRadius: "8px",
    boxShadow: "none",
    height: "48px",
    fontSize: "16px",
    paddingLeft: "50px",
    paddingRight: "50px",
  },
  btngrid: {
    // paddingRight: "25px",
    display: "flex",
    //paddingLeft: "25px",
    justifyContent: "space-around",
    paddingBottom: "35px",
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "15px",
  },
  add: {
    //padding: "20px",
    paddingTop: "20px",
    paddingLeft: "30px",
    paddingRight: "30px",
    width: "500px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  circularProgress: {
    color: theme.palette.common.white,
  },
}));

export default function AddCycle({
  open,
  handleClickOpen,
  handleClose,
  collegeId,
  userRolesPermission,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [selectedDate, setSelectedDate] = React.useState(null);
  const [placement, setPlacement] = useState(null);
  const [placementError, setPlacementError] = useState(null);
  const [selectedDateError, setSelectedDateError] = useState(null);

  const [loading, setLoading] = useState(false);
  const alert = useAlert();
  const history = useHistory();
  const location = useLocation();
  const placementList = useSelector((state) => state.PlacementCycles.details);
  const dispatch = useDispatch();

  const validateData = () => {
    var is_valid = true;
    var name_error = null;
    var date_error = null;
    if (!placement) {
      name_error = "Placement Cycle field cannot be left blank";
      is_valid = false;
    }
    if (!selectedDate) {
      alert.error("Please enter Start Date!!");
      is_valid = false;
    }

    setPlacementError(name_error);

    return is_valid;
  };

  const [variantName, setVariantName] = React.useState([]);
  const [addStudentToPlacementCycle, setAddStudentToPlacementCycle] =
    useState(false);

  const handleSubmit = () => {
    if (validateData()) {
      var data = {
        college_id: [collegeId],
        name: placement,
        start_date: new Date(selectedDate).toISOString(),
      };
      dispatch(
        PlacementPostAPI(
          data,
          setLoading,
          alert,
          history,
          location,
          placementList,
          handleClose,
          setPlacement,
          setSelectedDate,
          setAddStudentToPlacementCycle,
          addStudentToPlacementCycle,
          collegeId
        )
      );
    }
  };
  const CHARACTER_LIMIT = 65;
  return (
    <div>
      {userRolesPermission?.some(
        (item) => item.access_control_key === "manage_placement_cycle"
      ) && (
        <Button
          onClick={handleClickOpen}
          variant="contained"
          color="primary"
          style={{
            borderRadius: "8px",
            boxShadow: "none",
            height: "40px",
            fontSize: "16px",
            display: "flex",
            fontWeight: "normal",
            paddingLeft: "50px",
            paddingRight: "50px",
          }}
        >
          Add New Cycle
        </Button>
      )}
      <Dialog
        fullScreen={fullScreen}
        maxWidth="md"
        PaperProps={{ classes: { root: classes.root1 } }}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div className={classes.add}>
          <div
            style={{
              // padding: "10px",
              //   paddingLeft: "20px",
              display: "flex",
              marginBottom: "30px",
              marginLeft: "120px",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Typography variant="h6">Add New Placement Cycle</Typography>
          </div>

          <TextField
            variant="outlined"
            InputLabelProps={{
              className: classes.floatingLabelFocusStyle,
            }}
            inputProps={{
              style: { padding: "16px" },
              //className: classes.textfiledrow,
              classes: { root: classes.inputRoot },
              maxlength: CHARACTER_LIMIT,
            }}
            /*  inputProps={{
              style: { padding: "16px" },
            }} */
            value={placement}
            onChange={(e) => {
              setPlacement(e.target.value);
              setPlacementError(null);
            }}
            error={placementError ? true : false}
            helperText={<span>{placementError}</span>}
            // fullWidth
            /* InputProps={{ classes: { root: classes.inputRoot } }} */
            style={{ width: "100%" }}
            label="Enter Placement Cycle"
            name="Enter Placement Cycle"
            helperText={`${
              placement?.length ? placement?.length : 0
            }/${CHARACTER_LIMIT}`}
          />

          <div
            style={{
              display: "flex",
              // paddingLeft: "70px",
              marginTop: "20px",
              marginBottom: "10px",
            }}
          >
            {/* <Button color="primary" startIcon={<DateRangeIcon />} style={{ padding: "0px", display: "flex" }}>
              Select Start Date
            </Button> */}
            <Calendar
              title="Start Date"
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
            />
          </div>
          <div style={{ marginBottom: "10px" }}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  size="small"
                  checked={addStudentToPlacementCycle ? true : false}
                  onChange={() => {
                    setAddStudentToPlacementCycle(!addStudentToPlacementCycle);
                  }}
                />
              }
              label={
                <a href="/add-students" style={{ textDecoration: "none" }}>
                  <Typography variant="body2">
                    Add Students after creating placement cycle
                  </Typography>
                </a>
              }
            />
          </div>
          <div className={classes.btngrid}>
            <Button
              variant="outlined"
              className={classes.button}
              onClick={handleClose}
              color="primary"
            >
              Cancel
            </Button>

            <Button
              autoFocus
              className={classes.button}
              variant="contained"
              style={{ marginLeft: "15px", minWidth: "140px" }}
              color="primary"
              onClick={() => {
                if (!loading) handleSubmit();
              }}
            >
              {loading ? (
                <CircularProgress
                  className={classes.circularProgress}
                  size={14}
                />
              ) : (
                <>Add</>
              )}{" "}
            </Button>
          </div>
        </div>
        {/* </DialogActions> */}
      </Dialog>
    </div>
  );
}

const variants = [
  {
    id: 3,
    name: "Voucher",
    slug: "voucher",
    type: "Main",
    locale: "en",
    created_at: "2021-11-15T08:27:23.000Z",
    updated_at: "2021-11-15T08:27:23.000Z",
    cover: null,
  },
  {
    id: 1,
    name: "Top Up",
    slug: "top-up",
    type: "Main",
    locale: "en",
    created_at: "2021-11-15T08:26:44.000Z",
    updated_at: "2021-11-15T08:26:44.000Z",
    cover: null,
  },
  {
    id: 2,
    name: "Game Key",
    slug: "game-key",
    type: "Main",
    locale: "en",
    created_at: "2021-11-15T08:27:03.000Z",
    updated_at: "2021-11-15T08:27:03.000Z",
    cover: null,
  },
  {
    id: 12,
    name: "Other",
    slug: "other",
    type: "SubMain",
    locale: "en",
    created_at: "2021-11-15T08:30:50.000Z",
    updated_at: "2021-11-15T08:30:50.000Z",
    cover: null,
  },
  {
    id: 11,
    name: "Nintendo",
    slug: "nintendo",
    type: "SubMain",
    locale: "en",
    created_at: "2021-11-15T08:30:22.000Z",
    updated_at: "2021-11-15T08:30:22.000Z",
    cover: null,
  },
  {
    id: 10,
    name: "Xbox",
    slug: "xbox",
    type: "SubMain",
    locale: "en",
    created_at: "2021-11-15T08:30:08.000Z",
    updated_at: "2021-11-15T08:30:08.000Z",
    cover: null,
  },
];
