import {
  AppBar,
  Avatar,
  Button,
  Container,
  CssBaseline,
  Divider,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  Paper,
  MenuItem,
  Toolbar,
  Typography,
  Box,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Help } from "@material-ui/icons";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import MenuIcon from "@material-ui/icons/Menu";
import Phone from "@material-ui/icons/Phone";
import PostAddIcon from "@material-ui/icons/PostAdd";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { IconContext } from "react-icons";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  EJDListAPI,
  InviteStudentLinkAPI,
  LicenceCheckAPI,
} from "../../api/Common/action";
import { LogoutAPICall } from "../../api/Domain/action";
import {
  collegeDetailsSelectorFn,
  isEducationGroupFn,
  userLoggedInSelectorFn,
} from "../../api/Domain/selector";
import { userDetailsApi } from "../../api/SignIn/action";
import {
  collegeIdFn,
  collegeUserDetailSelectorFn,
  sidebarSelectorFn,
  userRolesActionsFn,
} from "../../api/SignIn/selector";
import logo from "../../assets/png/getwork-logo.png";
import Search from "../Input/Search";
import "../Common/Sidebar/Navbar.css";
import SubMenu from "../Common/Sidebar/SubMenu";
import InviteStudents from "../Input/CopyLink/InviteStudents";
import SendEjd from "../Input/DropDownButton/SendEjd";
import SettingsIcon from "@material-ui/icons/Settings";
import {
  changeStateInterview,
  INTERVIEW_STUDENTS,
} from "../../api/Assignment/action";
import { Alert } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import pSBC from "shade-blend-color";
import { CreditInfoGetAPI } from "../../api/Credits/action";

const drawerWidth = 220;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    // paddingRight: 24,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  toolbar2: {
    // paddingRight: 24,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "100%",
    //padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    backgroundColor: "#fff",
    boxShadow: "none",
    borderBottom: ".5px solid #b0b6ba",
    // borderBottom: "1px solid rgba(0 0 0 0.12)",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
    color: "#000",
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    borderRight: "1px solid #b0b6ba",
    alignItems: "center",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 2,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(7) + 2,
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    backgroundColor: "#F5F7F8",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(10),
    // paddingLeft: "50px",
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: "auto",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    borderRadius: "8px",
    //boxShadow: "none",
  },
  btn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    marginTop: "10px",
  },
  std: {
    marginLeft: "20px",
    boxShadow: "none",
    borderRadius: "8px",
    display: "flex",
    height: "40px",
  },
  postJob: {
    backgroundColor: theme.palette.primary.secondary,
    borderRadius: "8px",
    width: "80%",
    display: "flex",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: theme.palette.primary.secondary,
    },
  },
  collegelogo: {
    // marginRight: "15%",
    // marginLeft: "15%",
  },
  sidepanel: {
    height: "70vh",
    "&:hover": {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    // [theme.breakpoints.down("sm")]: {
    //   marginTop: "-50px",
    // },
    // [theme.breakpoints.up("md")]: {
    //   marginTop: "0px",
    // },
    // [theme.breakpoints.up("lg")]: {
    //   marginTop: "0px",
    // },
  },
  avatarBox: {
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    height: "120px",
  },
  userCredit: {
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    cursor: "pointer",
  },
}));

const creditIcon = (
  <svg
    width="16"
    height="15"
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.35742 2.65561H13.6074C13.7388 2.65557 13.8701 2.66391 14.0005 2.68057C13.9563 2.37041 13.8497 2.07241 13.6873 1.80454C13.5248 1.53667 13.3098 1.30447 13.0552 1.12195C12.8005 0.93943 12.5116 0.810364 12.2057 0.742535C11.8999 0.674706 11.5834 0.669519 11.2755 0.727286L2.02344 2.30686H2.01289C1.43214 2.41791 0.915686 2.74649 0.569023 3.22549C1.0913 2.854 1.7165 2.65478 2.35742 2.65561ZM13.6074 3.49936H2.35742C1.76088 3.50001 1.18897 3.73727 0.767151 4.15909C0.345335 4.5809 0.108073 5.15282 0.107422 5.74936V12.4994C0.108073 13.0959 0.345335 13.6678 0.767151 14.0896C1.18897 14.5114 1.76088 14.7487 2.35742 14.7494H13.6074C14.204 14.7487 14.7759 14.5114 15.1977 14.0896C15.6195 13.6678 15.8568 13.0959 15.8574 12.4994V5.74936C15.8568 5.15282 15.6195 4.5809 15.1977 4.15909C14.7759 3.73727 14.204 3.50001 13.6074 3.49936ZM11.9375 10.2494C11.715 10.2494 11.4975 10.1834 11.3125 10.0598C11.1275 9.93614 10.9833 9.76044 10.8981 9.55488C10.813 9.34931 10.7907 9.12311 10.8341 8.90488C10.8775 8.68665 10.9847 8.4862 11.142 8.32886C11.2993 8.17153 11.4998 8.06438 11.718 8.02097C11.9363 7.97756 12.1625 7.99984 12.368 8.08499C12.5736 8.17014 12.7493 8.31433 12.8729 8.49934C12.9965 8.68434 13.0625 8.90185 13.0625 9.12436C13.0625 9.42272 12.944 9.70887 12.733 9.91985C12.522 10.1308 12.2359 10.2494 11.9375 10.2494Z"
      fill="black"
    />
  </svg>
);

const StyledMenu = withStyles({
  paper: {
    //border: ".5px solid #d3d4d5",
    borderRadius: "8px",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    marginTop: "20px",
    display: "flex",
    width: "300px",
    flexDirection: "column",
  },
})((props) => (
  <Menu
    elevation={0}
    MenuListProps={{
      style: {
        padding: "0px",
      },
    }}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      //backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.black,
      },
    },
  },
}))(MenuItem);

export default function Layout({ children }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const alert = useAlert();
  const location = useLocation();
  const classes = useStyles();
  const collegeUserDetail = useSelector(collegeUserDetailSelectorFn);
  const collegeId = useSelector(collegeIdFn);
  const collegeDetails = useSelector(collegeDetailsSelectorFn);
  const isLoggedIn = useSelector(userLoggedInSelectorFn);
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const sidebarDataNew = useSelector(sidebarSelectorFn);
  const isEducationGroup = useSelector(isEducationGroupFn);

  useEffect(() => {
    dispatch(userDetailsApi(history, location, alert, false));
  }, []);

  const handleFilterChange = (dispatcher, data) => {
    dispatch(changeStateInterview(dispatcher, data));
  };

  useEffect(() => {
    handleFilterChange(INTERVIEW_STUDENTS, []);
  }, [location.pathname]);

  const inviteLink = useSelector((state) => state.commonData?.studentLink);
  const ejdList = useSelector((state) => state.commonData?.ejdList);
  const licenceData = useSelector((state) => state?.commonData?.licenceData);
  const creditData = useSelector((state) => state?.creditData?.creditData);

  useEffect(() => {
    if (!inviteLink && isLoggedIn) {
      dispatch(InviteStudentLinkAPI(alert, history, location));
    }
    if (!ejdList && isLoggedIn) {
      dispatch(EJDListAPI(alert, history, location));
    }
    if (!licenceData && isLoggedIn) {
      dispatch(LicenceCheckAPI(alert, history, location));
    }

    if (!creditData && isLoggedIn && collegeId) {
      dispatch(CreditInfoGetAPI(alert, history, location, () => {}, collegeId));
    }
  }, [isLoggedIn, collegeId]);
  const userRolesPermission = useSelector(userRolesActionsFn);
  const [openLicenceAlert, setOpenLicenceAlert] = useState(false);

  useEffect(() => {
    if (licenceData && licenceData?.display) {
      setOpenLicenceAlert(true);
    } else setOpenLicenceAlert(false);
  }, [licenceData]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      {/* <Navbar /> */}
      <AppBar
        position="absolute"
        //className={clsx(classes.appBar, open && classes.appBarShift)}
        className={clsx(classes.appBar)}
      >
        <Toolbar className={classes.toolbar}>
          <>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              className={clsx(
                classes.menuButton,
                open && classes.menuButtonHidden
              )}
            >
              <MenuIcon color="primary" />
            </IconButton>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingLeft: "40px",

                width: 150,
                backgroundColor: "#fff",
                height: 50,
              }}
            >
              <img
                src={collegeDetails?.logo}
                alt="logo"
                style={{
                  margin: "auto",
                  maxWidth: "100%",
                  maxHeight: "100%",
                }}
                // className={classes.collegelogo}
              />
            </div>
            <Hidden smDown>
              <Search placeholder="Search Student, Company, Job" />
            </Hidden>
            <div style={{ display: "flex" }}>
              {ejdList?.length > 0 && <SendEjd ejdList={ejdList} />}
              <InviteStudents inviteLink={inviteLink} />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" style={{ color: "#000" }}>
                {collegeUserDetail?.name}
              </Typography>

              <div>
                <Avatar
                  alt="user"
                  src={
                    collegeUserDetail?.profile_image
                      ? collegeUserDetail?.profile_image
                      : collegeDetails?.logo
                  }
                  onClick={handleClick}
                  style={{ marginLeft: "15px", cursor: "pointer" }}
                />
                <StyledMenu
                  id="customized-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <Box style={{ position: "relative" }}>
                    <Box className={classes.avatarBox} />

                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "-45px",
                      }}
                    >
                      <Avatar
                        alt="user"
                        src={
                          collegeUserDetail?.profile_image
                            ? collegeUserDetail?.profile_image
                            : collegeDetails?.logo
                        }
                        style={{
                          border: "10px solid #fff",
                          height: "80px",
                          width: "80px",
                        }}
                      />
                    </Box>
                    <Box
                      style={{
                        textAlign: "center",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Typography style={{ fontSize: "14px" }}>
                        {collegeUserDetail?.name}
                      </Typography>
                      <Typography style={{ fontSize: "14px" }}>
                        {" "}
                        {collegeUserDetail?.email}
                      </Typography>
                      <Link
                        to="/billing-info"
                        style={{
                          textDecoration: "none",
                          width: "fit-content",
                          marginTop: 6,
                        }}
                        onClick={() => {
                          handleClose();
                        }}
                      >
                        <Box
                          style={{
                            textAlign: "center",
                            padding: "10px",
                            borderRadius: "6px",
                            width: "fit-content",
                          }}
                          className={classes.userCredit}
                        >
                          <Typography style={{ fontSize: "14px" }}>
                            {creditIcon} Credit:{" "}
                            <span style={{ color: "#029E73" }}>
                              {creditData?.available_credits}
                            </span>{" "}
                          </Typography>
                        </Box>
                      </Link>

                      <Divider
                        style={{
                          width: "100%",
                          marginTop: 15,
                          marginBottom: 5,
                        }}
                      />
                    </Box>
                  </Box>

                  <Link
                    to="/account"
                    style={{ textDecoration: "none" }}
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    <StyledMenuItem>
                      <ListItemIcon
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          marginRight: "0px",
                        }}
                      >
                        <SettingsIcon fontSize="small" />
                      </ListItemIcon>
                      {/* <Typography variant="body2" style={{ marginLeft: "-20px" }}>
                      Help
                    </Typography> */}
                      <ListItemText
                        primary="Account Settings"
                        style={{ marginLeft: "-20px" }}
                      />
                    </StyledMenuItem>
                  </Link>
                  <Link
                    to="/help"
                    style={{ textDecoration: "none" }}
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    <StyledMenuItem>
                      <ListItemIcon
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          marginRight: "0px",
                        }}
                      >
                        <Help fontSize="small" />
                      </ListItemIcon>

                      <ListItemText
                        primary="Help & Support"
                        style={{ marginLeft: "-20px" }}
                      />
                    </StyledMenuItem>
                  </Link>
                  <Link
                    to="/contact"
                    style={{ textDecoration: "none" }}
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    <StyledMenuItem>
                      <ListItemIcon
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          //  marginRight: "0px",
                        }}
                      >
                        <Phone fontSize="small" />
                      </ListItemIcon>
                      <ListItemText
                        primary="Contact Us"
                        style={{ marginLeft: "-20px" }}
                      />
                    </StyledMenuItem>
                  </Link>
                  <StyledMenuItem
                    onClick={() => {
                      dispatch(LogoutAPICall(history, alert));
                    }}
                  >
                    <ListItemIcon
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        // marginRight: "0px",
                      }}
                    >
                      <ExitToAppIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                      primary="Logout"
                      style={{ marginLeft: "-20px" }}
                    />
                  </StyledMenuItem>
                </StyledMenu>
              </div>
            </div>
          </>{" "}
        </Toolbar>
      </AppBar>
      <Hidden smUp>
        <Drawer
          variant="temporary"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open={open}
        >
          <div className={classes.toolbarIcon}>
            {/* <img src={collegeDetails?.logo} height="40px" width="70%" /> */}
            <Grid container wrap="nowrap">
              <Grid
                item
                xs
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingLeft: "40px",

                  width: 150,
                  backgroundColor: "#fff",
                  height: 50,
                }}
              >
                <img
                  src={collegeDetails?.logo}
                  alt="logo"
                  style={{
                    margin: "auto",
                    maxWidth: "100%",
                    maxHeight: "100%",
                  }}
                  // className={classes.collegelogo}
                />
              </Grid>

              <Grid item>
                <IconButton onClick={handleDrawerClose}>
                  <ChevronLeftIcon />
                </IconButton>
              </Grid>
            </Grid>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "center",
              marginTop: "10px",
              marginBottom: "24px",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              className={classes.postJob}
              startIcon={<PostAddIcon />}
              disabled={
                userRolesPermission?.some(
                  (item) => item.access_control_key === "manage_job"
                ) === true
                  ? false
                  : true
              }
              onClick={() => {
                history.push("/post-job");
              }}
            >
              Post Job
            </Button>
          </div>
          <div className={classes.sidepanel}>
            <IconContext.Provider>
              {sidebarDataNew &&
                sidebarDataNew.map((item, index) => {
                  return <SubMenu items={item} key={index} />;
                })}
            </IconContext.Provider>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography variant="overline">Powered by</Typography>
              <img src={logo} height="17px" width="70px" />
            </div>
          </div>
          <Divider />
        </Drawer>
      </Hidden>
      <Hidden smDown>
        {/* <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open={open}
        > */}
        <div className="nav" id="navbar">
          <nav className="nav__container">
            <div>
              <div
                className={classes.sidepanel}
                style={{ marginTop: "86px" }}
                // onMouseOver={handleDrawerOpen}
              >
                <div
                  style={{
                    padding: "10px",
                    display: "flex",
                    alignItems: "center",
                    padding: "8px",
                    marginBottom: "20px",
                    height: "45px",
                    marginLeft: "6px",

                    cursor: "pointer",
                  }}
                  variant="contained"
                  color="primary"
                  className={classes.postJob}
                  startIcon={<PostAddIcon />}
                  disabled={
                    userRolesPermission?.some(
                      (item) => item.access_control_key === "manage_job"
                    ) === true
                      ? false
                      : true
                  }
                  onClick={() => {
                    history.push("/post-job");
                  }}
                >
                  <PostAddIcon
                    style={{
                      color: "white",
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "3px",
                    }}
                  />
                  <Typography
                    variant="body2"
                    style={{
                      margin: "auto",
                      marginLeft: "25px",
                      color: "white",
                    }}
                  >
                    {" "}
                    Post Job{" "}
                  </Typography>
                </div>
                <IconContext.Provider value={{ color: "#6c757d" }}>
                  {sidebarDataNew &&
                    sidebarDataNew.map((item, index) => {
                      return <SubMenu items={item} key={index} />;
                    })}
                </IconContext.Provider>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: "20px",
                  }}
                >
                  {/* <Typography variant="overline">Powered by</Typography> */}
                  <img
                    src={logo}
                    height="auto"
                    width="50px"
                    alt="getwork-logo"
                  />
                </div>
              </div>
            </div>
          </nav>
        </div>
        {/* </Drawer> */}
      </Hidden>

      {/* <Sidebar /> */}
      <main className={classes.content}>
        <div className={classes.appBarSpacer}>
          <Container maxWidth="xl" className={classes.container}>
            {openLicenceAlert && (
              <div id="alert">
                <>
                  <div className={classes.toolbar2}>
                    <Alert
                      style={{ width: "100%" }}
                      severity="error"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setOpenLicenceAlert(false);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      {licenceData?.display_message}
                      {". "}
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          history.push("/contact");
                        }}
                      >
                        {" "}
                        <u>Contact Us</u>
                      </span>
                    </Alert>
                  </div>
                </>
              </div>
            )}

            {children}
          </Container>
        </div>
      </main>
    </div>
  );
}
