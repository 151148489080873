import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/styles";
import {
  Box,
  Typography,
  DialogActions,
  Button,
  Chip,
} from "@material-ui/core";
import pSBC from "shade-blend-color";
import { entityInfoSelectorFn } from "../../../api/SignIn/selector";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  divider: {
    height: 28,
    margin: 4,
  },
  chipbox: {
    border: "1px solid #B7B7B7",
    padding: 5,
    borderRadius: 8,
    marginTop: "10px",
  },
  rootchip: {
    display: "flex",
    flexWrap: "wrap",
    listStyle: "none",
    paddingLeft: "0px",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    marginLeft: 0,
    borderRadius: "8px",
  },
}));
export default function CompanyNameDialog({
  open,
  handleClose,
  collegesList = [],
  handleClickOpenCollege1,
}) {
  const classes = useStyles();
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState("md");
  const [showMore, setShowMore] = useState(false);

  const entityInfo = useSelector(entityInfoSelectorFn) || {
    college: "College",
    university: "University",
  };

  const handleClose1 = () => {
    handleClickOpenCollege1();
    handleClose();
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
      >
        <DialogTitle id="scroll-dialog-title">
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingRight: 6,
            }}
          >
            <Typography style={{ fontSize: "20px", fontWeight: "600" }}>
              Company name: Abcde infrastructure pvt td
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <Box style={{ padding: 5, fontSize: "14px" }}>
            <Typography>
              {`These are the ${entityInfo.college} where this company has not been created.
              Please add the company or remove to post the job.`}
            </Typography>
            <div className={classes.chipbox}>
              <div className={classes.rootchip}>
                {!showMore
                  ? collegesList

                      ?.slice(0, 5)
                      ?.map((data, index) => (
                        <Chip
                          className={classes.chip}
                          color="primary"
                          variant="outlined"
                          size="small"
                          label={data?.name}
                        />
                      ))
                  : collegesList?.map((data, index) => (
                      <Chip
                        className={classes.chip}
                        color="primary"
                        variant="outlined"
                        size="small"
                        label={data?.name}
                      />
                    ))}
                {collegesList.filter((college) => {
                  return (
                    college.checked &&
                    college.placementCycles.some((cycle) => cycle.checked)
                  );
                })?.length > 5 && (
                  <Button
                    color="primary"
                    onClick={() => {
                      setShowMore(!showMore);
                    }}
                  >
                    {" "}
                    {!showMore ? `+${collegesList?.length - 5} more` : "Hide"}
                  </Button>
                )}
              </div>
            </div>
          </Box>
        </DialogContent>
        <DialogActions style={{ padding: 15, paddingRight: 25 }}>
          <Button
            onClick={handleClose}
            variant="outlined"
            color="inherit"
            style={{ height: "40px", boxShadow: "none", width: "200px" }}
          >
            Remove Colleges
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ height: "40px", boxShadow: "none", width: "200px" }}
            onClick={handleClose1}
          >
            Add Company
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
