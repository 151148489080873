import { Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  collegeGroupListSelectorFn,
  collegeIdFn,
  isCollegeGroupFn,
  isEducationGroupFn,
  userRolesActionsFn,
} from "../../api/SignIn/selector";
import GroupListDropdown from "../../components/Input/DropDownButton/GroupListDropdown";
import { groupViewCheck } from "../../utility/groupViewCheck";
import InterviewCalendarGroup from "./InterviewCalenderGroup";
import InterviewCalendar from "./InteviewCalendar";

const useStyles = makeStyles((_) => ({
  button: {
    borderRadius: "8px",
    height: "40px",
    display: "flex",
    boxShadow: "none",
  },
  grid: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "20px",
    alignItems: "center",
    marginTop: "0px",
  },
}));

function CalendarView() {
  const classes = useStyles();
  const userRolesPermission = useSelector(userRolesActionsFn);
  const isEducationGroup = useSelector(isEducationGroupFn);
  const isCollegeGroup = useSelector(isCollegeGroupFn);
  const collegeId = useSelector(collegeIdFn);
  const alert = useAlert();
  const [selectedGroup, setSelectedGroup] = useState(null);
  const collegeGroupList = useSelector(collegeGroupListSelectorFn);

  useEffect(() => {
    if (collegeGroupList?.length > 0 && isCollegeGroup) {
      setSelectedGroup({ id: "All", name: "All Groups" });
    }
  }, [collegeGroupList?.length]);
  return (
    <>
      <div>
        {/* <Breadcrumbs aria-label="breadcrumb">
            <Link
              href="/"
              onClick={handleClick}
              style={{ textDecoration: "none" }}
            >
              <Typography color="secondary" variant="body2">
                Interview
              </Typography>
            </Link>
            <Link
              style={{ textDecoration: "none" }}
              color="inherit"
              href="/getting-started/installation/"
              onClick={handleClick}
            >
              <Typography color="primary" variant="body2">
              Track Jobs
            </Typography>
            </Link>
          </Breadcrumbs> */}
        {groupViewCheck(isEducationGroup, isCollegeGroup, collegeId) &&
          collegeGroupList?.length > 0 && (
            <div style={{ marginTop: "5px", marginBottom: "5px" }}>
              <GroupListDropdown
                title="Group"
                state={selectedGroup}
                setState={setSelectedGroup}
                groupList={collegeGroupList}
              />
            </div>
          )}
        <div className={classes.grid}>
          <div>
            <Typography variant="h2">Interview</Typography>
            <Typography variant="body2" color="secondary">
              Manage all your Schedule interviews on single page
            </Typography>
          </div>
          {userRolesPermission?.some(
            (item) => item.access_control_key === "manage_interview"
          ) && (
            <>
              {/* {!groupViewCheck(isEducationGroup, isCollegeGroup, collegeId) ? ( */}
              <Link
                to="/create-interview"
                target="_self"
                style={{ textDecoration: "none" }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                >
                  Schedule Interview
                </Button>
              </Link>
              {/* ) : (
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() => {
                    alert.error(
                      "Please switch to college view to schedule an interview"
                    );
                  }}
                >
                  Schedule Interview
                </Button>
              )} */}
            </>
          )}
        </div>
        {!groupViewCheck(isEducationGroup, isCollegeGroup, collegeId) ? (
          <InterviewCalendar />
        ) : (
          <InterviewCalendarGroup
            selectedGroup={selectedGroup}
            setSelectedGroup={setSelectedGroup}
          />
        )}
      </div>
    </>
  );
}

export default CalendarView;
