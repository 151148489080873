import { Button, Typography, Tooltip, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import View from "../../Table/JobTable/View";
import moment from "moment";
import InfoToolTip from "../../Common/ToolTips/InfoToolTip";

// export const columnsOpenJobs = [
//   {
//     field: "job_title",
//     headerName: "Job Title",
//     // type: "text",
//     width: 170,
//     renderHeader: (params) => (
//       <strong
//         style={{
//           color: "#5a6268",
//           marginLeft: "45px",
//         }}
//       >
//         Job Title
//       </strong>
//     ),
//     renderCell: (params) => (
//       <>
//         <Grid
//           container
//           wrap="nowrap"
//           style={{
//             textAlign: "left",
//             display: "flex",
//             //    margin: "auto",
//             fontSize: "14px",
//           }}
//         >
//           {" "}
//           <Grid item xs zeroMinWidth>
//             <Link
//               to={`/job/${params?.row?.job_title
//                 ?.toLowerCase()
//                 .replace(" ", "-")}-${params.row.id}`}
//               style={{ textDecoration: "none", display: "contents" }}
//             >
//               {" "}
//               <Tooltip title={params.row.job_title} placement="right">
//                 <Typography color="primary" noWrap style={{ fontSize: "14px" }}>
//                   {params.row.job_title}
//                 </Typography>
//               </Tooltip>
//             </Link>
//           </Grid>
//         </Grid>
//       </>

//       // {/* <span
//       //   style={{
//       //     textAlign: "left",
//       //     display: "flex",
//       //     //   margin: "auto",
//       //   }}
//       // >
//       //   <Link
//       //     to={`/job/${params.row.job_title.toLowerCase().replace(" ", "-")}-${
//       //       params.row.id
//       //     }`}
//       //     style={{ textDecoration: "none" }}
//       //   >
//       //     <Tooltip title={params.row.job_title} placement="right">
//       //       <Typography color="primary" style={{ fontSize: "14px" }}>
//       //         {params.row.job_title}
//       //       </Typography>
//       //     </Tooltip>
//       //   </Link>
//       // </span> */}
//     ),
//   },
//   {
//     field: "company",

//     // type: "text",
//     width: 170,
//     renderHeader: (params) => (
//       <strong style={{ color: "#5a6268", marginLeft: "40px" }}>Company</strong>
//     ),
//     renderCell: (params) => (
//       <>
//         <Grid
//           container
//           wrap="nowrap"
//           style={{
//             textAlign: "left",
//             display: "flex",
//             //    margin: "auto",
//             fontSize: "14px",
//           }}
//         >
//           <Grid item xs zeroMinWidth>
//             <Link
//               to={`/company-profile/${params.row.company_id}`}
//               style={{ textDecoration: "none", display: "contents" }}
//             >
//               {" "}
//               <Tooltip title={params.row.company} placement="right">
//                 <Typography color="primary" noWrap style={{ fontSize: "14px" }}>
//                   {params.row.company}
//                 </Typography>
//               </Tooltip>
//             </Link>
//           </Grid>
//         </Grid>
//       </>

//       // <span
//       //   style={{
//       //     textAlign: "left",
//       //     display: "flex",
//       //     //  margin: "auto",
//       //     fontSize: "14px",
//       //   }}
//       // >
//       //   {" "}
//       //   <Link to="/company-details" style={{ textDecoration: "none" }}>
//       //     <Tooltip title={params.row.company} placement="right">
//       //       <Typography color="primary" style={{ fontSize: "14px" }}>
//       //         {params.row.company}
//       //       </Typography>
//       //     </Tooltip>
//       //   </Link>
//       // </span>
//     ),
//   },
//   {
//     field: "job_type",
//     // type: "text",
//     sortable: false,
//     width: 110,
//     renderHeader: (params) => (
//       <strong style={{ color: "#5a6268", marginLeft: "10px" }}>Job Type</strong>
//     ),
//     renderCell: (params) => (
//       <span
//         style={{
//           textAlign: "center",
//           display: "flex",
//           margin: "auto",
//           fontSize: "14px",
//         }}
//       >
//         {params.row.job_type}
//       </span>
//     ),
//   },
//   {
//     field: "source",
//     // type: "text",
//     sortable: false,
//     width: 140,
//     renderHeader: (params) => (
//       <strong style={{ color: "#5a6268", marginLeft: "10px" }}>Source</strong>
//     ),
//     renderCell: (params) => (
//       <span
//         style={{
//           textAlign: "center",
//           display: "flex",
//           margin: "auto",
//           fontSize: "14px",
//         }}
//       >
//         {params.row.job_source}
//       </span>
//     ),
//   },

//   {
//     field: "eligible_count",
//     headerName: "Eligible Count",
//     width: 120,
//     renderHeader: (params) => (
//       <strong
//         style={{
//           color: "#5a6268",
//           marginLeft: "15px",
//         }}
//       >
//         Eligible
//       </strong>
//     ),
//     renderCell: (params) => (
//       <>
//         <span
//           style={{
//             textAlign: "center",
//             display: "flex",
//             margin: "auto",
//             fontSize: "14px",
//           }}
//         >
//           {params?.row?.eligible_count ?? "..."}
//         </span>
//       </>
//     ),
//   },
//   {
//     field: "applied_count",
//     headerName: "Applied Count",
//     width: 120,
//     renderHeader: (params) => (
//       <strong
//         style={{
//           color: "#5a6268",
//           marginLeft: "15px",
//         }}
//       >
//         Applied
//       </strong>
//     ),
//     renderCell: (params) => (
//       <>
//         <span
//           style={{
//             textAlign: "center",
//             display: "flex",
//             margin: "auto",
//             fontSize: "14px",
//           }}
//         >
//           {params?.row?.applied_count ?? "..."}
//         </span>
//       </>
//     ),
//   },
//   {
//     field: "present_count",
//     headerName: "Present Count",
//     width: 120,
//     renderHeader: (params) => (
//       <strong
//         style={{
//           color: "#5a6268",
//           marginLeft: "15px",
//         }}
//       >
//         Present
//       </strong>
//     ),
//     renderCell: (params) => (
//       <>
//         <span
//           style={{
//             textAlign: "center",
//             display: "flex",
//             margin: "auto",
//             fontSize: "14px",
//           }}
//         >
//           {params?.row?.present_count ?? "..."}
//         </span>
//       </>
//     ),
//   },
//   {
//     field: "hired_count",
//     headerName: "Hired Count",
//     width: 120,
//     renderHeader: (params) => (
//       <strong
//         style={{
//           color: "#5a6268",
//           marginLeft: "25px",
//         }}
//       >
//         Hired
//       </strong>
//     ),
//     renderCell: (params) => (
//       <>
//         <span
//           style={{
//             textAlign: "center",
//             display: "flex",
//             margin: "auto",
//             fontSize: "14px",
//           }}
//         >
//           {params?.row?.hired_count ?? "..."}
//         </span>
//       </>
//     ),
//   },

//   {
//     field: "ctc",
//     // type: "text",
//     sortable: false,
//     width: 150,
//     renderHeader: (params) => (
//       <strong style={{ color: "#5a6268", marginLeft: "10px" }}>
//         CTC (Min-Max)
//       </strong>
//     ),
//     renderCell: (params) => (
//       <span
//         style={{
//           textAlign: "center",
//           display: "flex",
//           margin: "auto",
//           fontSize: "14px",
//         }}
//       >
//         {params.row.ctc}
//       </span>
//     ),
//   },
//   {
//     field: "stipend",
//     // type: "text",
//     sortable: false,
//     width: 180,
//     renderHeader: (params) => (
//       <strong style={{ color: "#5a6268", marginLeft: "10px" }}>
//         Stipend (Min-Max)
//       </strong>
//     ),
//     renderCell: (params) => (
//       <span
//         style={{
//           textAlign: "center",
//           display: "flex",
//           margin: "auto",
//           fontSize: "14px",
//         }}
//       >
//         {params.row.stipend}
//       </span>
//     ),
//   },
//   {
//     field: "vacancies",
//     // type: "text",
//     width: 120,
//     renderHeader: (params) => (
//       <strong style={{ color: "#5a6268", marginLeft: "10px" }}>
//         Vacancies
//       </strong>
//     ),
//     renderCell: (params) => (
//       <span
//         style={{
//           textAlign: "center",
//           display: "flex",
//           margin: "auto",
//           fontSize: "14px",
//         }}
//       >
//         {params.row.vacancies ? params.row.vacancies : "NA"}
//       </span>
//     ),
//   },
//   {
//     field: "applicants",
//     // type: "text",
//     width: 120,
//     renderHeader: (params) => (
//       <strong style={{ color: "#5a6268", marginLeft: "5px" }}>
//         Applicants
//       </strong>
//     ),
//     renderCell: (params) => (
//       <span
//         style={{
//           textAlign: "center",
//           display: "flex",
//           margin: "auto",
//         }}
//       >
//         {" "}
//         <Link
//           to={`/track/?job_id=${params?.row?.id}`}
//           style={{ textDecoration: "none" }}
//         >
//           <Typography color="primary" style={{ fontSize: "14px" }}>
//             {params.row.applicants}
//           </Typography>
//         </Link>
//       </span>
//     ),
//   },
//   {
//     field: "job_stage",
//     // type: "text",
//     sortable: false,
//     width: 140,
//     renderHeader: (params) => (
//       <strong style={{ color: "#5a6268", marginLeft: "10px" }}>
//         Job Stage
//       </strong>
//     ),
//     renderCell: (params) => (
//       <Tooltip title={params.row.job_stage} placement="right">
//         <Typography noWrap style={{ fontSize: "14px" }}>
//           {params.row.job_stage}
//         </Typography>
//       </Tooltip>
//       // <span
//       //   style={{
//       //     textAlign: "center",
//       //     display: "flex",
//       //     margin: "auto",
//       //     fontSize: "14px",
//       //   }}
//       // >
//       //   {params.row.job_stage}
//       // </span>
//     ),
//   },
//   {
//     field: "campus_drive_date",
//     // type: "text",
//     width: 145,
//     renderHeader: (params) => (
//       <strong style={{ color: "#5a6268", marginLeft: "25px" }}>Drive On</strong>
//     ),
//     renderCell: (params) => (
//       <span
//         style={{
//           textAlign: "center",
//           display: "flex",
//           margin: "auto",
//           fontSize: "14px",
//         }}
//       >
//         {params?.row?.campus_drive_date?.slice(0, 10) || "NA"}
//       </span>
//     ),
//   },
//   {
//     field: "end_date",
//     // type: "text",
//     width: 145,
//     renderHeader: (params) => (
//       <strong style={{ color: "#5a6268", marginLeft: "25px" }}>Apply By</strong>
//     ),
//     renderCell: (params) => (
//       <span
//         style={{
//           textAlign: "center",
//           display: "flex",
//           margin: "auto",
//           fontSize: "14px",
//         }}
//       >
//         {params?.row?.end_date?.slice(0, 10)}
//       </span>
//     ),
//   },
//   {
//     field: "created",
//     headerName: "Created",
//     type: "date",
//     width: 120,
//     renderHeader: (params) => (
//       <strong style={{ color: "#5a6268", marginLeft: "20px" }}>Created</strong>
//     ),
//     renderCell: (params) => (
//       <span
//         style={{
//           textAlign: "center",
//           display: "flex",
//           margin: "auto",
//           fontSize: "14px",
//         }}
//       >
//         {params.row.created}
//       </span>
//     ),
//   },
//   {
//     field: "button",
//     headerName: "",
//     type: "button",
//     width: 100,
//     renderHeader: (params) => (
//       <strong style={{ color: "#5a6268", marginLeft: "6px" }}>Actions</strong>
//     ),
//     renderCell: (cellValues) => {
//       return <View id={cellValues?.row?.id} />;
//     },
//   },
// ];

const lengthChecker = (text) => {
  const maxLength = 75;
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + "...";
  }
  return text;
};

export const columnsClosedJobs = (
  isEducationGroup = false,
  isCollegeGroup = false
) => [
  // {
  //   field: "id",
  //   headerName: "Job Id",
  // type: "text",
  //   width: 85,
  //   renderHeader: (params) => <strong style={{ color: "#5a6268" }}>Job Id</strong>,
  //   renderCell: (params) => (
  //     <span
  //       style={{
  //         textAlign: "center",
  //         display: "flex",
  //         margin: "auto",
  //         fontSize: "14px",
  //       }}
  //     >
  //       {params.row.id}
  //     </span>
  //   ),
  // },
  {
    field: "job_title",
    headerName: "Job Title",
    // type: "text",
    width: 150,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "25px",
        }}
      >
        Job Title
      </strong>
    ),
    renderCell: (params) => (
      <>
        <Grid
          container
          wrap="nowrap"
          style={{
            textAlign: "left",
            display: "flex",
            //    margin: "auto",
            fontSize: "14px",
          }}
        >
          {" "}
          <Grid item xs zeroMinWidth>
            <Link
              to={
                params?.row?.job_title
                  ? `/${
                      isEducationGroup || isCollegeGroup ? "job" : "job"
                    }/${params.row.job_title.toLowerCase().replace(" ", "-")}-${
                      params.row.id
                    }`
                  : `/${isEducationGroup || isCollegeGroup ? "job" : "job"}-${
                      params.row.id
                    }`
              }
              target={`${
                isEducationGroup || isCollegeGroup ? "_self" : "_blank"
              }`}
              style={{ textDecoration: "none", display: "contents" }}
            >
              {" "}
              <Tooltip title={params.row.job_title} placement="right">
                <Typography color="primary" noWrap style={{ fontSize: "14px" }}>
                  {params.row.job_title}
                </Typography>
              </Tooltip>
            </Link>
          </Grid>
        </Grid>
      </>
      // <span
      //   style={{
      //     textAlign: "left",
      //     display: "flex",
      //     //  margin: "auto",
      //   }}
      // >
      //   <Link
      //     to={`/job/${params.row.job_title.toLowerCase().replace(" ", "-")}-${
      //       params.row.id
      //     }`}
      //     style={{ textDecoration: "none" }}
      //   >
      //     <Tooltip title={params.row.job_title} placement="right">
      //       <Typography color="primary" style={{ fontSize: "14px" }}>
      //         {params.row.job_title}
      //       </Typography>
      //     </Tooltip>
      //   </Link>
      // </span>
    ),
  },
  {
    field: "company",
    headerName: "Company",
    // type: "text",
    width: 150,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "30px" }}>Company</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "left",
          display: "flex",
          // margin: "auto",
          fontSize: "14px",
        }}
      >
        {" "}
        <Link
          to={`/${
            isEducationGroup || isCollegeGroup
              ? "company-profile"
              : "company-profile"
          }/${params.row.company_id}`}
          target={`${isEducationGroup || isCollegeGroup ? "_self" : "_blank"}`}
          style={{ textDecoration: "none" }}
        >
          <Tooltip title={params.row.company} placement="right">
            <Typography color="primary" style={{ fontSize: "14px" }}>
              {params.row.company}
            </Typography>
          </Tooltip>
        </Link>
      </span>
    ),
  },
  {
    field: "job_type",
    // type: "text",
    headerName: "Job Type",
    sortable: false,
    width: 110,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "10px" }}>Job Type</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.job_type}
      </span>
    ),
  },
  {
    field: "source",
    // type: "text",
    headerName: "Source",
    sortable: false,
    width: 180,
    renderHeader: (params) => (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          marginLeft: ".5rem",
        }}
      >
        <strong style={{ color: "#5a6268" }}>Source</strong>
      </div>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.job_source}
      </span>
    ),
  },
  {
    field: "ctc",
    // type: "text",
    headerName: "CTC",
    sortable: false,
    width: 110,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "25px" }}>CTC</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.ctc}
      </span>
    ),
  },
  {
    field: "stipend",
    // type: "text",
    headerName: "Stipend(Min-Max)",
    sortable: false,
    width: 180,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "10px" }}>
        Stipend(Min-Max)
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.stipend}
      </span>
    ),
  },
  {
    field: "vacancies",
    // type: "text",
    headerName: "Vacancies",
    width: 120,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "10px" }}>
        Vacancies
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.vacancies ? params.row.vacancies : "NA"}
      </span>
    ),
  },

  {
    field: "applicants",
    type: "number",
    headerName: "Applied",
    width: 120,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268" }}>Applied</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
        }}
      >
        {" "}
        <Link
          to={`/${
            isEducationGroup || isCollegeGroup ? "track" : "track"
          }/?job_id=${params?.row?.id}`}
          target={`${isEducationGroup || isCollegeGroup ? "_self" : "_blank"}`}
          style={{ textDecoration: "none" }}
        >
          <Typography color="primary" style={{ fontSize: "14px" }}>
            {params.row.applicants}
          </Typography>
        </Link>
      </span>
    ),
  },
  {
    field: "students_offered",
    headerName: "Offered",
    type: "number",
    width: 120,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "25px",
        }}
      >
        Offered
      </strong>
    ),
    renderCell: (params) => (
      <>
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
          }}
        >
          {" "}
          <Link
            to={`/${
              isEducationGroup || isCollegeGroup ? "track" : "track"
            }/?job_id=${params?.row?.id}`}
            target={`${
              isEducationGroup || isCollegeGroup ? "_self" : "_blank"
            }`}
            style={{ textDecoration: "none" }}
          >
            <Typography color="primary" style={{ fontSize: "14px" }}>
              {params?.row?.student_offered ?? "..."}
            </Typography>
          </Link>
        </span>
      </>
    ),
  },
  {
    field: "students_joined",
    headerName: "Joined",
    type: "number",
    width: 120,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "25px",
        }}
      >
        Joined
      </strong>
    ),
    renderCell: (params) => (
      <>
        <span
          style={{
            textAlign: "center",
            display: "flex",
            margin: "auto",
          }}
        >
          {" "}
          <Link
            to={`/${
              isEducationGroup || isCollegeGroup ? "track" : "track"
            }/?job_id=${params?.row?.id}`}
            target={`${
              isEducationGroup || isCollegeGroup ? "_self" : "_blank"
            }`}
            style={{ textDecoration: "none" }}
          >
            <Typography color="primary" style={{ fontSize: "14px" }}>
              {params?.row?.student_joined ?? "..."}
            </Typography>
          </Link>
        </span>
      </>
    ),
  },
  {
    field: "student_hired",
    type: "number",
    headerName: "Hired",
    width: 100,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "10px" }}>Hired</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        <Link
          to={`/${
            isEducationGroup || isCollegeGroup ? "track" : "track"
          }/?job_id=${params?.row?.id}`}
          target={`${isEducationGroup || isCollegeGroup ? "_self" : "_blank"}`}
          style={{ textDecoration: "none" }}
        >
          <Typography color="primary" style={{ fontSize: "14px" }}>
            {params.row.student_hired}
          </Typography>
        </Link>
      </span>
    ),
  },
  {
    field: "campus_drive_date",
    // type: "text",
    headerName: "Drive On",
    width: 145,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "25px" }}>Drive On</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params?.row?.campus_drive_date?.slice(0, 10) || "NA"}
      </span>
    ),
  },
  {
    field: "created",
    // type: "text",
    headerName: "Created",
    width: 150,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "7px" }}>
        Action Date
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.created}
      </span>
    ),
  },
  {
    field: "created",
    headerName: "Closed Date",
    type: "date",
    width: 150,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "20px" }}>
        Action Date
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.created}
      </span>
    ),
  },
  {
    field: "reason",
    headerName: "Reason",
    // type: "button",
    width: 200,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268" }}>Reason</strong>
    ),
    renderCell: (params) =>
      params?.row?.reason ? (
        <>
          <Grid
            container
            wrap="nowrap"
            style={{
              textAlign: "left",
              display: "flex",
              fontSize: "14px",
            }}
          >
            {" "}
            <Grid item xs zeroMinWidth>
              <Tooltip title={params?.row?.reason} placement="right">
                <Typography noWrap style={{ fontSize: "14px" }}>
                  {lengthChecker(params?.row?.reason)}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </>
      ) : (
        <Typography noWrap style={{ fontSize: "14px" }}>
          NA
        </Typography>
      ),
  },
  {
    field: "button",
    headerName: "Actions",
    type: "button",
    width: 100,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268" }}>Actions</strong>
    ),
    renderCell: (cellValues) => {
      return (
        <View id={cellValues?.row?.id} isEducationGroup={isEducationGroup} />
      );
    },
  },
];

export const columnsUpcomingJobs = [
  // {
  //   field: "id",
  //   headerName: "Job Id",
  //   // type: "text",
  //   width: 85,
  //   renderHeader: (params) => <strong style={{ color: "#5a6268" }}>Job Id</strong>,
  //   renderCell: (params) => (
  //     <span
  //       style={{
  //         textAlign: "left",
  //         display: "flex",
  //         //   margin: "auto",
  //         fontSize: "14px",
  //       }}
  //     >
  //       {params.row.id}
  //     </span>
  //   ),
  // },
  {
    field: "job_title",
    headerName: "Job Title",
    // type: "text",
    width: 170,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "40px",
        }}
      >
        Job Title
      </strong>
    ),
    renderCell: (params) => (
      <>
        <Grid
          container
          wrap="nowrap"
          style={{
            textAlign: "left",
            display: "flex",
            //    margin: "auto",
            fontSize: "14px",
          }}
        >
          {" "}
          <Grid item xs zeroMinWidth>
            <Link
              to={
                params.row.job_title
                  ? `/job/${params.row.job_title
                      .toLowerCase()
                      .replace(" ", "-")}-${params.row.id}`
                  : `job-${params.row.id}`
              }
              target="_blank"
              style={{ textDecoration: "none", display: "contents" }}
            >
              {" "}
              <Tooltip title={params.row.job_title} placement="right">
                <Typography color="primary" noWrap style={{ fontSize: "14px" }}>
                  {params.row.job_title}
                </Typography>
              </Tooltip>
            </Link>
          </Grid>
        </Grid>
      </>
      // <span
      //   style={{
      //     textAlign: "left",
      //     display: "flex",
      //     //   margin: "auto",
      //   }}
      // >
      //   <Link
      //     to={`/job/${params.row.job_title.toLowerCase().replace(" ", "-")}-${
      //       params.row.id
      //     }`}
      //     style={{ textDecoration: "none" }}
      //   >
      //     {" "}
      //     <Tooltip title={params.row.title} placement="right">
      //       <Typography color="primary" style={{ fontSize: "14px" }}>
      //         {params.row.title}
      //       </Typography>
      //     </Tooltip>
      //   </Link>
      // </span>
    ),
  },
  {
    field: "company",
    headerName: "Company",
    // type: "text",
    width: 170,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "40px" }}>Company</strong>
    ),
    renderCell: (params) => (
      <>
        <Grid
          container
          wrap="nowrap"
          style={{
            textAlign: "left",
            display: "flex",
            //    margin: "auto",
            fontSize: "14px",
          }}
        >
          {" "}
          <Grid item xs zeroMinWidth>
            <Link
              to={`/company-profile/${params.row.company_id}`}
              style={{ textDecoration: "none", display: "contents" }}
            >
              {" "}
              <Tooltip title={params.row.company} placement="right">
                <Typography color="primary" noWrap style={{ fontSize: "14px" }}>
                  {params.row.company}
                </Typography>
              </Tooltip>
            </Link>
          </Grid>
        </Grid>
      </>
      // <span
      //   style={{
      //     textAlign: "left",
      //     display: "flex",
      //     //margin: "auto",
      //     fontSize: "14px",
      //   }}
      // >
      //   {" "}
      //   <Link to="/company-details" style={{ textDecoration: "none" }}>
      //     <Tooltip title={params.row.company} placement="right">
      //       <Typography color="primary" style={{ fontSize: "14px" }}>
      //         {params.row.company}
      //       </Typography>
      //     </Tooltip>
      //   </Link>
      // </span>
    ),
  },
  {
    field: "job_type",
    // type: "text",
    headerName: "Job Type",
    sortable: false,
    width: 110,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "10px" }}>Job Type</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.job_type}
      </span>
    ),
  },
  {
    field: "source",
    headerName: "Source",
    // type: "text",
    sortable: false,
    width: 140,
    renderHeader: (params) => (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          marginLeft: ".5rem",
        }}
      >
        <strong style={{ color: "#5a6268" }}>Source</strong>
      </div>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.job_source}
      </span>
    ),
  },

  {
    field: "ctc",
    headerName: "CTC",
    // type: "text",
    sortable: false,
    width: 110,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "25px" }}>CTC</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.ctc}
      </span>
    ),
  },
  {
    field: "stipend",
    // type: "text",
    headerName: "Stipend(Min-Max)",
    sortable: false,
    width: 180,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "10px" }}>
        Stipend (Min-Max)
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.stipend}
      </span>
    ),
  },
  {
    field: "vacancies",
    // type: "text",
    headerName: "Vacancies",
    width: 120,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "10px" }}>
        Vacancies
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.vacancies ? params.row.vacancies : "NA"}
      </span>
    ),
  },
  {
    field: "job_stage",
    // type: "text",
    headerName: "Job Stage",
    sortable: false,
    width: 140,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "10px" }}>
        Job Stage{" "}
      </strong>
    ),
    renderCell: (params) => (
      <Tooltip title={params.row.job_stage} placement="right">
        <Typography noWrap style={{ fontSize: "14px" }}>
          {params.row.job_stage}
        </Typography>
      </Tooltip>
      // <span
      //   style={{
      //     textAlign: "center",
      //     display: "flex",
      //     margin: "auto",
      //     fontSize: "14px",
      //   }}
      // >
      //   {params.row.job_stage}
      // </span>
    ),
  },
  {
    field: "campus_drive_date",
    // type: "text",
    headerName: "Drive On",
    width: 145,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "25px" }}>Drive On</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params?.row?.campus_drive_date?.slice(0, 10) || "NA"}
      </span>
    ),
  },

  {
    field: "created",
    headerName: "Created",
    type: "date",
    width: 150,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "30px" }}>
        Action Date
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.created}
      </span>
    ),
  },
  {
    field: "start_date",
    headerName: "Start Date",
    type: "date",
    width: 150,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "25px" }}>
        Start Date
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.start_date}
      </span>
    ),
  },
  {
    field: "button",
    headerName: "Actions",
    type: "button",
    width: 100,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "5px" }}>Actions</strong>
    ),
    renderCell: (cellValues) => {
      return (
        <Link
          to={`/job/${cellValues.row.job_title
            .toLowerCase()
            .replace(" ", "-")}-${cellValues.row.id}`}
          style={{ textDecoration: "none", display: "contents" }}
        >
          <Button color="primary">View</Button>
        </Link>
      );
    },
  },
];

export const draftedJobsColumn = (
  isEducationGroup = false,
  isCollegeGroup = false
) => [
  // {
  //   field: "id",
  //   headerName: "Job Id",
  //   // type: "text",
  //   width: 85,
  //   renderHeader: (params) => <strong style={{ color: "#5a6268" }}>Job Id</strong>,
  //   renderCell: (params) => (
  //     <span
  //       style={{
  //         textAlign: "center",
  //         display: "flex",
  //         margin: "auto",
  //         fontSize: "14px",
  //       }}
  //     >
  //       {params.row.id}
  //     </span>
  //   ),
  // },
  {
    field: "job_title",
    headerName: "Job Title",
    // type: "text",
    width: 170,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "45px",
        }}
      >
        Job Title
      </strong>
    ),
    renderCell: (params) => (
      <>
        <Grid
          container
          wrap="nowrap"
          style={{
            textAlign: "left",
            display: "flex",
            //    margin: "auto",
            fontSize: "14px",
          }}
        >
          {" "}
          <Grid item xs zeroMinWidth>
            <Link
              to={
                params?.row?.job_title
                  ? `/${
                      isEducationGroup || isCollegeGroup ? "job" : "job"
                    }/${params.row.job_title.toLowerCase().replace(" ", "-")}-${
                      params.row.id
                    }`
                  : `/${isEducationGroup || isCollegeGroup ? "job" : "job"}-${
                      params.row.id
                    }`
              }
              target={`${
                isEducationGroup || isCollegeGroup ? "_self" : "_blank"
              }`}
              style={{ textDecoration: "none", display: "contents" }}
            >
              {" "}
              <Tooltip title={params.row.job_title} placement="right">
                <Typography color="primary" noWrap style={{ fontSize: "14px" }}>
                  {params.row.job_title}
                </Typography>
              </Tooltip>
            </Link>
          </Grid>
        </Grid>
      </>
      // <span
      //   style={{
      //     textAlign: "left",
      //     display: "flex",
      //     //  margin: "auto",
      //   }}
      // >
      //   <Link
      //     to={`/job/${params.row.job_title.toLowerCase().replace(" ", "-")}-${
      //       params.row.id
      //     }`}
      //     style={{ textDecoration: "none" }}
      //   >
      //     {" "}
      //     <Tooltip title={params.row.job_title} placement="right">
      //       <Typography color="primary" style={{ fontSize: "14px" }}>
      //         {params.row.job_title}
      //       </Typography>
      //     </Tooltip>
      //   </Link>
      // </span>
    ),
  },
  {
    field: "company",
    headerName: "Company",
    // type: "text",
    width: 170,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "40px" }}>Company</strong>
    ),
    renderCell: (params) => (
      <>
        <Grid
          container
          wrap="nowrap"
          style={{
            textAlign: "left",
            display: "flex",
            //    margin: "auto",
            fontSize: "14px",
          }}
        >
          {" "}
          <Grid item xs zeroMinWidth>
            <Link
              to={`/${
                isEducationGroup || isCollegeGroup
                  ? "company-profile"
                  : "company-profile"
              }/${params.row.company_id}`}
              target={`${
                isEducationGroup || isCollegeGroup ? "_self" : "_blank"
              }`}
              style={{ textDecoration: "none", display: "contents" }}
            >
              {" "}
              <Tooltip title={params.row.company} placement="right">
                <Typography color="primary" noWrap style={{ fontSize: "14px" }}>
                  {params.row.company}
                </Typography>
              </Tooltip>
            </Link>
          </Grid>
        </Grid>
      </>
      // <span
      //   style={{
      //     textAlign: "left",
      //     display: "flex",
      //     //  margin: "auto",
      //     fontSize: "14px",
      //   }}
      // >
      //   {" "}
      //   <Link to="/company-details" style={{ textDecoration: "none" }}>
      //     <Tooltip title={params.row.company} placement="right">
      //       <Typography color="primary" style={{ fontSize: "14px" }}>
      //         {params.row.company}
      //       </Typography>
      //     </Tooltip>
      //   </Link>
      // </span>
    ),
  },
  {
    field: "job_type",
    // type: "text",
    headerName: "Job Type",
    sortable: false,
    width: 110,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "10px" }}>Job Type</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.job_type}
      </span>
    ),
  },

  {
    field: "source",
    headerName: "Source",
    // type: "text",
    sortable: false,
    width: 140,
    renderHeader: (params) => (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          marginLeft: ".5rem",
        }}
      >
        <strong style={{ color: "#5a6268" }}>Source</strong>
      </div>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.job_source}
      </span>
    ),
  },
  {
    field: "ctc",
    // type: "text",
    headerName: "CTC",
    sortable: false,
    width: 110,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "25px" }}>CTC</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.ctc}
      </span>
    ),
  },
  {
    field: "stipend",
    // type: "text",
    headerName: "Stipend(Min-Max)",
    sortable: false,
    width: 180,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "10px" }}>
        Stipend (Min-Max)
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.stipend}
      </span>
    ),
  },
  {
    field: "vacancies",
    // type: "text",
    headerName: "Vacancies",
    width: 120,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "10px" }}>
        Vacancies
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.vacancies ? params.row.vacancies : "NA"}
      </span>
    ),
  },

  {
    field: "campus_drive_date",
    // type: "text",
    headerName: "Drive On",
    width: 145,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "25px" }}>Drive On</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params?.row?.campus_drive_date?.slice(0, 10) || "NA"}
      </span>
    ),
  },
  {
    field: "end_date",
    type: "date",
    headerName: "Apply By",
    width: 150,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "25px" }}>Apply By</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.end_date}
      </span>
    ),
  },

  {
    field: "created",
    headerName: "Created",
    type: "date",
    width: 150,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "30px" }}>
        Action Date
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.created}
      </span>
    ),
  },

  {
    field: "button",
    headerName: "Actions",
    // type: "text",
    width: 100,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "5px" }}>Actions</strong>
    ),
    renderCell: (params) => (
      <Link
        to={
          params.row.job_title
            ? `/${
                isEducationGroup || isCollegeGroup ? "job" : "job"
              }/${params.row.job_title.replace(" ", "-")}-${params.row.id}`
            : `/${isEducationGroup || isCollegeGroup ? "job" : "job"}-${
                params.row.id
              }`
        }
        target={`${isEducationGroup || isCollegeGroup ? "_self" : "_blank"}`}
        style={{ textDecoration: "none" }}
      >
        <Button color="primary">View</Button>
      </Link>
    ),
  },
];

export const columnsEJD = (
  isEducationGroup = false,
  isCollegeGroup = false
) => [
  // {
  //   field: "id",
  //   headerName: "Job Id",
  //   type: "text",
  //   width: 85,
  //   renderHeader: (params) => <strong style={{ color: "#5a6268" }}>Job Id</strong>,
  //   renderCell: (params) => (
  //     <span
  //       style={{
  //         textAlign: "center",
  //         display: "flex",
  //         margin: "auto",
  //         fontSize: "14px",
  //       }}
  //     >
  //       {params.row.id}
  //     </span>
  //   ),
  // },
  {
    field: "job_title",
    headerName: "Job Title",
    type: "text",
    width: 170,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "45px",
        }}
      >
        Job Title
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "left",
          display: "flex",
          //    margin: "auto",
          fontSize: "14px",
        }}
      >
        <Link
          to={`/${isEducationGroup || isCollegeGroup ? "ejd-job" : "ejd-job"}/${
            params?.row.job_id
          }`}
          target={`${isEducationGroup || isCollegeGroup ? "_self" : "_blank"}`}
          style={{ textDecoration: "none" }}
        >
          <Tooltip title={params?.row?.job_title} placement="right">
            <Typography color="primary" style={{ fontSize: "14px" }}>
              {params?.row?.job_title}
            </Typography>
          </Tooltip>
        </Link>
      </span>
    ),
  },
  {
    field: "company",
    headerName: "Company",
    type: "text",
    width: 170,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "40px" }}>Company</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "left",
          display: "flex",
          //    margin: "auto",
          fontSize: "14px",
        }}
      >
        {" "}
        {params?.row?.ejd_comp_verification === "Verified" ? (
          <Link
            to={`/${
              isEducationGroup || isCollegeGroup
                ? "company-profile"
                : "company-profile"
            }/${params?.row?.company_id}`}
            target={`${
              isEducationGroup || isCollegeGroup ? "_self" : "_blank"
            }`}
            style={{ textDecoration: "none" }}
          >
            <Typography color="primary" style={{ fontSize: "14px" }}>
              {params?.row?.company}
            </Typography>
          </Link>
        ) : (
          <>
            <Typography style={{ fontSize: "14px", color: "red" }}>
              {params.row.company}
            </Typography>
          </>
        )}
      </span>
    ),
  },
  {
    field: "job_type",
    headerName: "Job Type",
    type: "text",
    sortable: false,
    width: 110,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "10px" }}>Job Type</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params?.row?.job_type}
      </span>
    ),
  },
  {
    field: "source",
    // type: "text",
    headerName: "Source",
    sortable: false,
    width: 140,
    renderHeader: (params) => (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          marginLeft: ".5rem",
        }}
      >
        <strong style={{ color: "#5a6268" }}>Source</strong>
      </div>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.job_source}
      </span>
    ),
  },
  {
    field: "ctc",
    headerName: "CTC",
    type: "text",
    sortable: false,
    width: 110,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "25px" }}>CTC</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params?.row?.ctc}
      </span>
    ),
  },
  {
    field: "stipend",
    headerName: "Stipend(Min-Max)",
    // type: "text",
    sortable: false,
    width: 180,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "10px" }}>
        Stipend (Min-Max)
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params?.row?.stipend}
      </span>
    ),
  },
  {
    field: "vacancies",
    headerName: "Vacancies",
    type: "text",
    width: 120,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "10px" }}>
        Vacancies
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.vacancies ? params.row.vacancies : "NA"}
      </span>
    ),
  },
  {
    field: "ejd_status",
    headerName: "Status",
    type: "text",
    width: 100,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "10px" }}>Status</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          fontWeight: "2px",
          color:
            params?.row?.ejd_status === "POSTED"
              ? "#51c03e"
              : params?.row?.ejd_status === "PENDING"
              ? "#FFAC37"
              : "#f64a3f",
        }}
      >
        {params?.row?.ejd_status ? params?.row?.ejd_status : "NA"}
      </span>
    ),
  },

  {
    field: "campus_drive_date",
    // type: "text"
    headerName: "Drive On",
    width: 145,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "25px" }}>Drive On</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params?.row?.campus_drive_date?.slice(0, 10) === "1970-01-01"
          ? "NA"
          : params?.row?.campus_drive_date?.slice(0, 10) || "NA"}
      </span>
    ),
  },

  {
    field: "created",
    headerName: "Sent on",
    type: "date",
    width: 150,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "30px" }}>
        Action Date
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params?.row?.created}
      </span>
    ),
  },
  // {
  //   field: "tentative_date",
  //   type: "date",
  //   width: 150,
  //   renderHeader: (params) => <strong style={{ color: "#5a6268", marginLeft: "5px" }}>Tentative Date</strong>,
  //   renderCell: (params) => (
  //     <span
  //       style={{
  //         textAlign: "center",
  //         display: "flex",
  //         margin: "auto",
  //         fontSize: "14px",
  //       }}
  //     >
  //       {params?.row?.tentative_date ? params?.row?.tentative_date : "NA"}
  //     </span>
  //   ),
  // },
  {
    field: "button",
    headerName: "Actions",
    type: "button",
    width: 100,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "5px" }}>Actions</strong>
    ),
    renderCell: (cellValues) => {
      return (
        <Link
          to={`/${isEducationGroup || isCollegeGroup ? "ejd-job" : "ejd-job"}/${
            cellValues?.row?.job_id
          }`}
          target={`${isEducationGroup || isCollegeGroup ? "_self" : "_blank"}`}
          style={{ textDecoration: "none" }}
        >
          <Button color="primary">View</Button>
        </Link>
      );
    },
  },
];

export const columnsEJDRejected = (
  isEducationGroup = false,
  isCollegeGroup = false
) => [
  {
    field: "job_title",
    headerName: "Job Title",
    type: "text",
    width: 170,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "45px",
        }}
      >
        Job Title
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "left",
          display: "flex",
          //    margin: "auto",
          fontSize: "14px",
        }}
      >
        <Link
          to={`/${isEducationGroup || isCollegeGroup ? "ejd-job" : "ejd-job"}/${
            params?.row.job_id
          }`}
          target={`${isEducationGroup || isCollegeGroup ? "_self" : "_blank"}`}
          style={{ textDecoration: "none" }}
        >
          <Typography color="primary" style={{ fontSize: "14px" }}>
            {params?.row?.job_title}
          </Typography>
        </Link>
      </span>
    ),
  },
  {
    field: "company",
    headerName: "Company",
    type: "text",
    width: 170,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "40px" }}>Company</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "left",
          display: "flex",
          //    margin: "auto",
          fontSize: "14px",
        }}
      >
        {" "}
        {params?.row?.ejd_comp_verification === "Verified" ? (
          <Link
            to={`/${
              isEducationGroup || isCollegeGroup
                ? "company-profile"
                : "company-profile"
            }/${params?.row?.company_id}`}
            target={`${
              isEducationGroup || isCollegeGroup ? "_self" : "_blank"
            }`}
            style={{ textDecoration: "none" }}
          >
            <Typography color="primary" style={{ fontSize: "14px" }}>
              {params?.row?.company}
            </Typography>
          </Link>
        ) : (
          <>
            <Typography style={{ fontSize: "14px", color: "red" }}>
              {params.row.company}
            </Typography>
          </>
        )}
      </span>
    ),
  },
  {
    field: "job_type",
    headerName: "Job Type",
    type: "text",
    sortable: false,
    width: 110,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "10px" }}>Job Type</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params?.row?.job_type}
      </span>
    ),
  },
  {
    field: "source",
    headerName: "Source",
    // type: "text",
    sortable: false,
    width: 140,
    renderHeader: (params) => (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          marginLeft: ".5rem",
        }}
      >
        <strong style={{ color: "#5a6268" }}>Source</strong>
      </div>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.job_source}
      </span>
    ),
  },
  {
    field: "ctc",
    headerName: "CTC",
    type: "text",
    sortable: false,
    width: 110,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "25px" }}>CTC</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params?.row?.ctc}
      </span>
    ),
  },
  {
    field: "stipend",
    headerName: "Stipend(Min-Max)",
    // type: "text",
    sortable: false,
    width: 180,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "10px" }}>
        Stipend (Min-Max)
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params?.row?.stipend}
      </span>
    ),
  },
  {
    field: "vacancies",
    headerName: "Vacancies",
    type: "text",
    width: 120,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "10px" }}>
        Vacancies
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.vacancies ? params.row.vacancies : "NA"}
      </span>
    ),
  },

  {
    field: "campus_drive_date",
    // type: "text",
    headerName: "Drive On",
    width: 145,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "25px" }}>Drive On</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params?.row?.campus_drive_date?.slice(0, 10) || "NA"}
      </span>
    ),
  },
  {
    field: "created",
    type: "date",
    headerName: "Sent on",
    width: 150,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "30px" }}>Sent on</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params?.row?.created}
      </span>
    ),
  },
  {
    field: "last_updated_by",
    headerName: "Rejected By",
    type: "text",
    width: 140,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "10px" }}>
        Rejected By
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params?.row?.last_updated_by ? params?.row?.last_updated_by : "NA"}
      </span>
    ),
  },
  {
    field: "reason",
    headerName: "Reject Reason",
    type: "text",
    width: 200,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "10px", width: "180px" }}>
        Reject Reason
      </strong>
    ),
    renderCell: (params) => (
      <div
        style={{
          display: "flex",
          width: "190px",
          justifyContent: "space-between",
        }}
      >
        <Typography noWrap style={{ fontSize: "14px" }}>
          {params?.row?.reason || "NA"}
        </Typography>
        {params?.row?.reason && params?.row?.reason.length > 30 && (
          <InfoToolTip label={params?.row?.reason} />
        )}
      </div>
    ),
  },

  {
    field: "update_time",
    headerName: "Rejected At",
    type: "date",
    width: 150,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "5px" }}>
        Action Date
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params?.row?.update_time
          ? moment(params?.row?.update_time).format("YYYY-MM-DD")
          : "NA"}
      </span>
    ),
  },
];

export const columnsJobInvitations = [
  // {
  //   field: "id",
  //   headerName: "Job Id",
  //   type: "text",
  //   width: 85,
  //   renderHeader: (params) => <strong style={{ color: "#5a6268" }}>Job Id</strong>,
  //   renderCell: (params) => (
  //     <span
  //       style={{
  //         textAlign: "center",
  //         display: "flex",
  //         margin: "auto",
  //         fontSize: "14px",
  //       }}
  //     >
  //       {params.row.id}
  //     </span>
  //   ),
  // },
  {
    field: "job_title",
    headerName: "Job Title",
    type: "text",
    width: 170,
    renderHeader: (params) => (
      <strong
        style={{
          color: "#5a6268",
          marginLeft: "45px",
        }}
      >
        Job Title
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "left",
          display: "flex",
          //    margin: "auto",
          fontSize: "14px",
        }}
      >
        <Link
          to={`/ejd-job/${params?.row.job_id}`}
          style={{ textDecoration: "none" }}
        >
          <Tooltip title={params?.row?.job_title} placement="right">
            <Typography color="primary" style={{ fontSize: "14px" }}>
              {params?.row?.job_title}
            </Typography>
          </Tooltip>
        </Link>
      </span>
    ),
  },
  {
    field: "company",
    headerName: "Company",
    type: "text",
    width: 170,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "40px" }}>Company</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "left",
          display: "flex",
          //    margin: "auto",
          fontSize: "14px",
        }}
      >
        {" "}
        {params?.row?.ejd_comp_verification === "Verified" ? (
          <Link
            to={`/company-profile/${params?.row?.company_id}`}
            style={{ textDecoration: "none" }}
          >
            <Typography color="primary" style={{ fontSize: "14px" }}>
              {params?.row?.company}
            </Typography>
          </Link>
        ) : (
          <>
            <Typography style={{ fontSize: "14px", color: "red" }}>
              {params.row.company}
            </Typography>
          </>
        )}
      </span>
    ),
  },
  {
    field: "job_type",
    headerName: "Job Type",
    type: "text",
    sortable: false,
    width: 110,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "10px" }}>Job Type</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params?.row?.job_type}
      </span>
    ),
  },
  {
    field: "source",
    headerName: "Source",
    // type: "text",
    sortable: false,
    width: 140,
    renderHeader: (params) => (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          marginLeft: ".5rem",
        }}
      >
        <strong style={{ color: "#5a6268" }}>Source</strong>
      </div>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.job_source}
      </span>
    ),
  },
  {
    field: "ctc",
    headerName: "CTC",
    type: "text",
    sortable: false,
    width: 110,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "25px" }}>CTC</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params?.row?.ctc}
      </span>
    ),
  },
  {
    field: "stipend",
    // type: "text",
    headerName: "Stipend(Min-Max)",
    sortable: false,
    width: 180,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "10px" }}>
        Stipend (Min-Max)
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params?.row?.stipend}
      </span>
    ),
  },
  {
    field: "vacancies",
    headerName: "Vacancies",
    type: "text",
    width: 120,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "10px" }}>
        Vacancies
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params.row.vacancies ? params.row.vacancies : "NA"}
      </span>
    ),
  },

  {
    field: "ejd_status",
    headerName: "Status",
    type: "text",
    width: 100,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "10px" }}>Status</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
          color:
            params?.row?.ejd_status === "POSTED"
              ? "#51c03e"
              : params?.row?.ejd_status === "PENDING"
              ? "yellow"
              : "#f64a3f",
        }}
      >
        {params?.row?.ejd_status ? params?.row?.ejd_status : "NA"}
      </span>
    ),
  },

  {
    field: "campus_drive_date",
    // type: "text",
    headerName: "Drive On",
    width: 145,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "25px" }}>Drive On</strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params?.row?.campus_drive_date?.slice(0, 10) || "NA"}
      </span>
    ),
  },

  {
    field: "created",
    headerName: "Sent on",
    type: "date",
    width: 150,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "30px" }}>
        Action Date
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params?.row?.created}
      </span>
    ),
  },
  {
    field: "tentative_date",
    headerName: "Tentative Date",
    type: "date",
    width: 150,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "5px" }}>
        Tentative Date
      </strong>
    ),
    renderCell: (params) => (
      <span
        style={{
          textAlign: "center",
          display: "flex",
          margin: "auto",
          fontSize: "14px",
        }}
      >
        {params?.row?.tentative_date ? params?.row?.tentative_date : "NA"}
      </span>
    ),
  },
  {
    field: "button",
    headerName: "Actions",
    type: "button",
    width: 100,
    renderHeader: (params) => (
      <strong style={{ color: "#5a6268", marginLeft: "5px" }}>Actions</strong>
    ),
    renderCell: (cellValues) => {
      return (
        <Link
          to={`/ejd-job/${cellValues?.row?.job_id}`}
          style={{ textDecoration: "none" }}
        >
          <Button color="primary">View</Button>
        </Link>
      );
    },
  },
];

export const openJobsField = [
  {
    field: "job_title",
    headerName: "Job Title",
    hide: false,
  },
  {
    field: "company",
    headerName: "Company",
    hide: false,
  },
  {
    field: "job_type",
    headerName: "Job Type",
    hide: false,
  },
  {
    field: "job_source",
    headerName: "Source",
    hide: false,
  },

  {
    field: "eligible_count",
    headerName: "Eligible",
    hide: false,
  },
  {
    field: "applied_count",
    headerName: "Applied",
    hide: false,
  },
  {
    field: "present_count",
    headerName: "Present",
    hide: false,
  },
  {
    field: "hired_count",
    headerName: "Hired",
    hide: false,
  },
  {
    field: "students_offered",
    headerName: "Offered",
    hide: false,
  },
  {
    field: "students_joined",
    headerName: "Joined",
    hide: false,
  },

  {
    field: "ctc",
    headerName: "Ctc(Min-Max)",
    hide: false,
  },
  {
    field: "stipend",
    headerName: "Stipend(Min-Max)",
    hide: false,
  },
  {
    field: "vacancies",
    headerName: "Vacancies",
    hide: false,
  },
  {
    field: "applicants",
    headerName: "Applicants",
    hide: false,
  },
  {
    field: "job_stage",
    headerName: "Job Stage",
    hide: false,
  },
  {
    field: "campus_drive_date",
    headerName: "Drive On",
    hide: false,
  },
  {
    field: "end_date",
    headerName: "Apply By",
    hide: false,
  },
  {
    field: "created",
    headerName: "Created",
    hide: false,
  },
  {
    field: "Offer Letter Count",
    headerName: "Offer Letter Count",
    hide: false,
  },
  {
    field: "Offer Letter",
    headerName: "Offer Letter",
    hide: false,
  },
  {
    field: "button",
    headerName: "Actions",
    hide: false,
  },
];
export const closedJobsField = [
  {
    field: "job_title",
    headerName: "Job Title",
    hide: false,
  },
  {
    field: "company",
    headerName: "Company",
    hide: false,
  },
  {
    field: "job_type",
    headerName: "Job Type",
    hide: false,
  },
  {
    field: "source",
    headerName: "Source",
    hide: false,
  },
  {
    field: "ctc",
    headerName: "Ctc",
    hide: false,
  },
  {
    field: "stipend",
    headerName: "Stipend(Min-Max)",
    hide: false,
  },
  {
    field: "vacancies",
    headerName: "Vacancies",
    hide: false,
  },
  {
    field: "student_hired",
    headerName: "Student Hired",
    hide: false,
  },
  {
    field: "applicants",
    headerName: "Students Applied",
    hide: false,
  },
  {
    field: "hired_count",
    headerName: "Hired",
    hide: false,
  },
  {
    field: "students_joined",
    headerName: "Joined",
    hide: false,
  },
  {
    field: "students_offered",
    headerName: "Offered",
    hide: false,
  },
  {
    field: "campus_drive_date",
    headerName: "Drive On",
    hide: false,
  },

  {
    field: "created",
    headerName: "Closed Date",
    hide: false,
  },
  {
    field: "button",
    headerName: "Actions",
    hide: false,
  },
];

export const upcomingJobsField = [
  {
    field: "job_title",
    headerName: "Job Title",
    hide: false,
  },
  {
    field: "company",
    headerName: "Company",
    hide: false,
  },
  {
    field: "job_type",
    headerName: "Job Type",
    hide: false,
  },
  {
    field: "source",
    headerName: "Source",
    hide: false,
  },

  {
    field: "ctc",
    headerName: "Ctc",
    hide: false,
  },
  {
    field: "stipend",
    headerName: "Stipend",
    hide: false,
  },
  {
    field: "vacancies",
    headerName: "Vacancies",
    hide: false,
  },
  {
    field: "job_stage",
    headerName: "Job Stage",
    hide: false,
  },
  {
    field: "campus_drive_date",
    headerName: "Drive On",
    hide: false,
  },

  {
    field: "created",
    headerName: "Created",
    hide: false,
  },
  {
    field: "start_date",
    headerName: "Start Date",
    hide: false,
  },
  {
    field: "button",
    headerName: "Actions",
    hide: false,
  },
];

export const draftedJobsField = [
  {
    field: "job_title",
    headerName: "Job Title",
    hide: false,
  },
  {
    field: "company",
    headerName: "Company",
    hide: false,
  },
  {
    field: "job_type",
    headerName: "Job Type",
    hide: false,
  },

  {
    field: "source",
    headerName: "Source",
    hide: false,
  },

  {
    field: "ctc",
    headerName: "Ctc",
    hide: false,
  },
  {
    field: "stipend",
    headerName: "Stipend(Min-Max)",
    hide: false,
  },

  {
    field: "vacancies",
    headerName: "Vacancies",
    hide: false,
  },

  {
    field: "campus_drive_date",
    headerName: "Drive On",
    hide: false,
  },

  {
    field: "end_date",
    headerName: "Apply By",
    hide: false,
  },

  {
    field: "created",
    headerName: "Created",
    hide: false,
  },

  {
    field: "button",
    headerName: "Actions",
    hide: false,
  },
];

export const EJDField = [
  {
    field: "job_title",
    headerName: "Job Title",
    hide: false,
  },
  {
    field: "company",
    headerName: "Company",
    hide: false,
  },
  {
    field: "job_type",
    headerName: "Job Type",
    hide: false,
  },

  {
    field: "source",
    headerName: "Source",
    hide: false,
  },

  {
    field: "ctc",
    headerName: "Ctc",
    hide: false,
  },
  {
    field: "stipend",
    headerName: "Stipend(Min-Max)",
    hide: false,
  },

  {
    field: "vacancies",
    headerName: "Vacancies",
    hide: false,
  },

  {
    field: "ejd_status",
    headerName: "Status",
    hide: false,
  },
  {
    field: "campus_drive_date",
    headerName: "Drive On",
    hide: false,
  },

  {
    field: "created",
    headerName: "Sent on",
    hide: false,
  },

  {
    field: "button",
    headerName: "Actions",
    hide: false,
  },
];

export const EJDRejectedField = [
  {
    field: "job_title",
    headerName: "Job Title",
    hide: false,
  },
  {
    field: "company",
    headerName: "Company",
    hide: false,
  },
  {
    field: "job_type",
    headerName: "Job Type",
    hide: false,
  },

  {
    field: "source",
    headerName: "Source",
    hide: false,
  },

  {
    field: "ctc",
    headerName: "Ctc",
    hide: false,
  },
  {
    field: "stipend",
    headerName: "Stipend(Min-Max)",
    hide: false,
  },

  {
    field: "vacancies",
    headerName: "Vacancies",
    hide: false,
  },
  {
    field: "campus_drive_date",
    headerName: "Drive On",
    hide: false,
  },

  {
    field: "created",
    headerName: "Sent on",
    hide: false,
  },

  {
    field: "last_updated_by",
    headerName: "Rejected By",
    hide: false,
  },
  {
    field: "reason",
    headerName: "Reject Reason",
    hide: false,
  },

  {
    field: "update_time",
    headerName: "Rejected At",
    hide: false,
  },
];

export const jobInvitationsField = [
  {
    field: "job_title",
    headerName: "Job Title",
    hide: false,
  },
  {
    field: "company",
    headerName: "Company",
    hide: false,
  },
  {
    field: "job_type",
    headerName: "Job Type",
    hide: false,
  },

  {
    field: "source",
    headerName: "Source",
    hide: false,
  },

  {
    field: "ctc",
    headerName: "Ctc",
    hide: false,
  },
  {
    field: "stipend",
    headerName: "Stipend(Min-Max)",
    hide: false,
  },
  {
    field: "vacancies",
    headerName: "Vacancies",
    hide: false,
  },
  {
    field: "ejd_status",
    headerName: "Status",
    hide: false,
  },

  {
    field: "campus_drive_date",
    headerName: "Drive On",
    hide: false,
  },

  {
    field: "created",
    headerName: "Sent on",
    hide: false,
  },
  {
    field: "tentative_date",
    headerName: "Tentative Date",
    hide: false,
  },
  {
    field: "button",
    headerName: "actions",
    hide: false,
  },
];
