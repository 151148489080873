//Imports
import axios from "axios";
import { checkForToken } from "../../constants/ApiCall";
import { BackendBaseApi } from "../../constants/constants";
//Exports
export const PLACEMENT_CYCLES = "PLACEMENT_CYCLES";
export const USER_ROLES_ALL = "USER_ROLES_ALL";

//Placement Api Call Action
export const PlacementCyclesGetAPI = (
  setLoading,
  alert,
  history,
  location,
  collegeId
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .get(
        BackendBaseApi.SANCHALAN +
          "api/placement_cycles/placements/?college_param=" +
          collegeId,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        setLoading(false);
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (res.data.success) {
    } else {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
    dispatch({
      type: PLACEMENT_CYCLES,
      payload: res.data.data,
    });
  };
};

export const PlacementPostAPI = (
  data,
  setLoading,
  alert,
  history,
  location,
  placementList,
  handleClose,
  setPlacement,
  setSelectedDate,
  setAddStudentToPlacementCycle,
  addStudentToPlacementCycle,
  collegeId
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .post(
        BackendBaseApi.SANCHALAN +
          "api/placement_cycles/placements/?college_param=" +
          collegeId,
        data,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        setLoading(false);
        if (
          error?.response?.status !== 401 &&
          error?.response?.status !== 403
        ) {
          alert.error(
            "Unable to Create New Placement Cycle. Some Error Occurred!!"
          );
        }
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (res.data.success) {
      alert.success("Placement Cycle Added Successfully");
      var arr = [...placementList];
      arr.unshift(res.data.data.data);
      if (res?.data?.data?.data?.id && addStudentToPlacementCycle)
        history.push(
          `/add-students/?placement_cycle_id=${res?.data?.data?.data?.id}&placement_cycle_name=${res?.data?.data?.data?.name}`
        );
      setPlacement("");
      setSelectedDate(null);
      setAddStudentToPlacementCycle(false);
    } else {
      alert.error("Some Error Occurred!!!");
    }
    setLoading(false);
    handleClose();

    dispatch({
      type: PLACEMENT_CYCLES,
      payload: arr,
    });
  };
};

export const PlacementPatchAPI = (
  data,
  objId,
  setLoading,
  alert,
  history,
  location,
  placementList,
  handleClose,
  fromEndForm
) => {
  return async (dispatch) => {
    setLoading(true);
    const res = await axios
      .patch(
        `${BackendBaseApi.SANCHALAN}api/placement_cycles/placements/${objId}/`,
        data,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        setLoading(false);
        if (
          error?.response?.status !== 401 &&
          error?.response?.status !== 403
        ) {
          alert.error("Unable to Update. Some Error Occurred!!");
        }
        checkForToken(error, history, location, alert);

        throw error;
      });

    if (res.data.success) {
      alert.success("Placement Cycle Updated Successfully");

      var arr = [...placementList];
      if (fromEndForm) {
        arr.forEach((i) => {
          if (i.id === objId) {
            i["end_date"] = data.end_date;
            i["is_ended"] = true;
          }
        });
      } else {
        arr.forEach((i) => {
          if (i.id === objId) {
            i["name"] = data.name;
            i["start_date"] = data.start_date;
          }
        });
      }
      handleClose();
      dispatch({
        type: PLACEMENT_CYCLES,
        payload: arr,
      });
    } else {
      alert.error(res.data.error);
    }
    setLoading(false);
  };
};

export const changeState = (dispatcher, data) => {
  return async (dispatch) => {
    dispatch({ type: dispatcher, payload: data });
  };
};
