import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import React, { useEffect } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { IndustryGetAPI } from "../../../api/Common/action";
import AddNewCompanyForm from "../DialogItem/AddNewCompanyForm";
import { useState } from "react";
import { BackendBaseApi } from "../../../constants/constants";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },

  root1: {
    borderRadius: "8px",
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },

  formControl: {
    minWidth: "100%",
    marginTop: "20px",
  },
  button: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    height: "40px",
    paddingLeft: "30px",
    width: "150px",
    paddingRight: "30px",
    display: "flex",
  },
}));

export default function AddNewCompany({
  collegeId,
  setRefreshData = () => {},
  selectedGroup,
}) {
  const classes = useStyles();
  const alert = useAlert();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  const licenceData = useSelector((state) => state?.commonData?.licenceData);

  const initialCompanyDetails = {
    company_name: null,
    company_website: null,
    industry_id: null,
    industry_name: null,
    industry_type_id: null,
    industry_type_name: null,
    contact_person_name: null,
    email: null,
    phone: null,
    designation: null,
  };
  const [companyDetails, setCompanyDetails] = useState(initialCompanyDetails);

  const industryList = useSelector((state) => state?.commonData?.industryData);

  const handleClickOpen = () => {
    if (licenceData && licenceData?.display) {
      alert.error(licenceData?.display_message);
      return;
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    dispatch(IndustryGetAPI(alert, history, location));
  }, []);

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        size="small"
        startIcon={<AddIcon />}
        // style={{
        //   borderRadius: "30px",
        //   boxShadow: "none",
        //   display: "flex",
        //   height: "48px",
        //   paddingLeft: "30px",
        //   paddingRight: "30px",

        //   width: "100%",
        // }}
        className={classes.button}
        onClick={handleClickOpen}
      >
        Add New
      </Button>
      <Dialog
        PaperProps={{ classes: { root: classes.root1 } }}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <div style={{ padding: "40px" }}>
          <AddNewCompanyForm
            handleClose={handleClose}
            industryList={industryList ? industryList : []}
            collegeId={collegeId}
            companyDetails={companyDetails}
            setCompanyDetails={setCompanyDetails}
            source="ADD_NEW"
            setRefreshData={setRefreshData}
            initialCompanyDetails={initialCompanyDetails}
            selectedGroup={selectedGroup}
          />
        </div>
      </Dialog>
    </div>
  );
}
