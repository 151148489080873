import { Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import GetAppIcon from "@material-ui/icons/GetApp";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { PlacementListAPI } from "../../api/Common/action";
import { isGradeSystemFn } from "../../api/Domain/selector";
import { StudentNavbarAPI, StudentsGetAPI } from "../../api/ManageStudents/action";
import { collegeGroupListSelectorFn, collegeIdFn, isCollegeGroupFn, userRolesActionsFn } from "../../api/SignIn/selector";
import CustomCircularProgress from "../../components/CircularProgressBar";
import ShortlistEligible from "../../components/DialogSection/AddCompanyPlacement/AddStudentPlacement copy";
import AddNewStudent from "../../components/DialogSection/AddNewCompany/AddNewStudent";
import StudentTab from "../../components/Header/StudentTab";
import GroupListDropdown from "../../components/Input/DropDownButton/GroupListDropdown";
import { generateCsvForParams, generateCsv } from "../../utility/commaSeparatedStrings";
import {STUDENT_DATA} from "../../api/ManageStudents/action";

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
    backgroundColor: theme.palette.primary.secondary,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.palette.primary.secondary,
    },
  },
  grid: {
    display: "flex",
    marginTop: "10px",
    marginBottom: "20px",
    justifyContent: "space-between",
    //  alignItems: "center",
    flexDirection: "row",
  },
  buttonBase: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
  },
  primebtn: {
    borderRadius: "8px",
    margin: theme.spacing(1),
    boxShadow: "none",
    padding: "7px",
    marginRight: "10px",
    height: "40px",
    paddingLeft: "30px",
    paddingRight: "30px",
    display: "flex",
  },
}));

export default function StudentGroupTrack() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const alert = useAlert();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [value, setValue] = React.useState("all");
  const [firstApiCall, setFirstApiCall] = useState(false);
  const [applyClickedScore, setApplyClickedScore] = useState(false);
  const [placementSession, setPlacementSession] = useState(null);
  // const collegeDetails = useSelector(collegeDetailSelectorFn);
  const collegeId = useSelector(collegeIdFn);
  const [callNavbar, setCallNavbar] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const collegeGroupList = useSelector(collegeGroupListSelectorFn);
  const isCollegeGroup = useSelector(isCollegeGroupFn);
  const [newCollegeGroupList, setNewCollegeGroupList] = useState([]); 
  const [selectedID, setSelectedID] = useState([]);
  const [filters, setFilters] = useState({
    search: null,
    course: null,
    degree: null,
    batch: null,
    cgpa_min: null,
    cgpa_max: null,
    status_active: true,
    status_blocked: true,
    status: "ALL",
    current_tab: null,
  });
  const [clickSearchButton, setClickSearchButton] = useState(false);
  const isGradeSystem = useSelector(isGradeSystemFn);

  const checkValue = () => {
    if (filters?.status === "" || filters?.status === "ALL" || filters?.status === "all") return ``;
    else if (filters?.current_tab?.params === "pending_students") return `&key=${"pending_students"}`;
    else return `&placement_status=${filters?.status}`;
  };
  const searchFilter = () => {
    if (filters.search) return `&search=${filters.search}`;
    else return ``;
  };
  const departmentFilter = () => {
    if (filters.course) return `&course=${filters.course.id}`;
    else return ``;
  };
  const degreeFilter = () => {
    if (filters.degree) return `&degree=${filters.degree.id}`;
    else return ``;
  };
  const batchFilter = () => {
    if (filters.batch) return `&batch=${filters.batch.id}`;
    else return ``;
  };
  const statusFilter = () => {
    if (filters.status_active && filters.status_blocked) return ``;
    else if (filters.status_active) return `&status=ACTIVE`;
    else if (filters.status_blocked) return `&status=INACTIVE`;
    else return ``;
  };

  const checkSelectedGroup = () => {
    if (isCollegeGroup) {
      if (!selectedGroup && collegeGroupList?.length === 1) return `&group_id=${collegeGroupList[0]?.id}`;
      else if (selectedGroup && selectedGroup.id !== "All") return `&group_id=${selectedGroup.id}`;
      else {
        return `&group_id=${generateCsvForParams(collegeGroupList?.map((item) =>  item.id))}`;
      }
    } else {
      if (selectedGroup && selectedGroup.id !== "All") return `&group_id=${selectedGroup.id}`;
      else return ``;
    }
  };

  const scoreFilter = () => {
    if (isGradeSystem) {
      if (filters.cgpa_min && filters?.cgpa_max) return `&min_cgpa=${Number(filters.cgpa_min)}&max_cgpa=${Number(filters.cgpa_max)}`;
      else if (filters.cgpa_min) return `&min_cgpa=${Number(filters.cgpa_min)}`;
      else if (filters.cgpa_max) return `&max_cgpa=${Number(filters.cgpa_max)}`;
      else return ``;
    } else {
      if (filters.cgpa_min && filters?.cgpa_max) return `&min_percentage=${Number(filters.cgpa_min)}&max_percentage=${Number(filters.cgpa_max)}`;
      else if (filters.cgpa_min) return `&min_percentage=${Number(filters.cgpa_min)}`;
      else if (filters.cgpa_max) return `&max_percentage=${Number(filters.cgpa_max)}`;
      else return ``;
    }
  };
  useEffect(() => {
    if (firstApiCall) {
      var url = `api/manage_student/view/?${checkValue()}${checkSelectedGroup()}`;
      dispatch(StudentsGetAPI(setLoading, alert, history, location, url, setClickSearchButton, setFirstApiCall, setApplyClickedScore));

      var url_filter = `api/manage_student/navbar/?${checkValue()}${checkSelectedGroup()}`;

      dispatch(StudentNavbarAPI(url_filter));
    }
  }, [filters?.status]);

  const clearStudentData = () => {
    dispatch({
      type: STUDENT_DATA,
      payload: [],
    });
  }

  useEffect(() => {
    if (selectedGroup) {
      var url = `api/manage_student/view/?${checkValue()}${searchFilter()}${departmentFilter()}${batchFilter()}${statusFilter()}${degreeFilter()}${scoreFilter()}${checkSelectedGroup()}`;
      dispatch(StudentsGetAPI(setLoading, alert, history, location, url, setClickSearchButton, setFirstApiCall, setApplyClickedScore));

      var url_filter = `api/manage_student/navbar/?${checkValue()}${searchFilter()}${departmentFilter()}${batchFilter()}${statusFilter()}${degreeFilter()}${scoreFilter()}${checkSelectedGroup()}`;

      dispatch(StudentNavbarAPI(url_filter));
    }
  }, [selectedGroup]);

  useEffect(() => {
    if (firstApiCall) {
      var url = `api/manage_student/view/?${checkValue()}${searchFilter()}${departmentFilter()}${batchFilter()}${statusFilter()}${degreeFilter()}${scoreFilter()}${checkSelectedGroup()}`;
      dispatch(StudentsGetAPI(setLoading, alert, history, location, url, setClickSearchButton, setFirstApiCall, setApplyClickedScore));

      var url_filter = `api/manage_student/navbar/?${checkValue()}${searchFilter()}${departmentFilter()}${batchFilter()}${statusFilter()}${degreeFilter()}${scoreFilter()}${checkSelectedGroup()}`;

      dispatch(StudentNavbarAPI(url_filter));
    }
  }, [filters.status_blocked, filters.status_active, filters.batch, filters.course, filters.degree]);

  useEffect(() => {
    if (clickSearchButton) {
      var url = `api/manage_student/view/?${checkValue()}${searchFilter()}${departmentFilter()}${batchFilter()}${statusFilter()}${degreeFilter()}${scoreFilter()}${checkSelectedGroup()}`;
      dispatch(StudentsGetAPI(setLoading, alert, history, location, url, setClickSearchButton, setFirstApiCall, setApplyClickedScore));
      var url_filter = `api/manage_student/navbar/?${checkValue()}${searchFilter()}${departmentFilter()}${batchFilter()}${statusFilter()}${degreeFilter()}${scoreFilter()}${checkSelectedGroup()}`;

      dispatch(StudentNavbarAPI(url_filter));
    }
  }, [clickSearchButton]);

  const studentList = useSelector((state) => state?.StudentData?.details);
  const filterList = useSelector((state) => state?.StudentData?.filterData);

  useEffect(() => {
    if (applyClickedScore) {
      var url = `api/manage_student/view/?college_id=${collegeId}${checkValue()}${searchFilter()}${departmentFilter()}${batchFilter()}${statusFilter()}${degreeFilter()}${scoreFilter()}${checkSelectedGroup()}`;
      dispatch(StudentsGetAPI(setLoading, alert, history, location, url, setClickSearchButton, setFirstApiCall, setApplyClickedScore));
      var url_filter = `api/manage_student/navbar/?college_id=${collegeId}${checkValue()}${searchFilter()}${departmentFilter()}${batchFilter()}${statusFilter()}${degreeFilter()}${scoreFilter()}${checkSelectedGroup()}`;

      dispatch(StudentNavbarAPI(url_filter));
    }
  }, [applyClickedScore]);

  const navbarData = useSelector((state) => state?.StudentData?.navbar);
  const placementSessionList = useSelector((state) => state.commonData.placementList);
  const [loadingPlacement, setLoadingPlacement] = useState(false);
  const userRolesPermission = useSelector(userRolesActionsFn);

  useEffect(() => {
    if (collegeId) dispatch(PlacementListAPI(alert, history, location, setLoadingPlacement, collegeId));
  }, [collegeId]);

  useEffect(() => {
    if (placementSessionList?.length > 0) {
      if (location.search.includes("placement_cycle")) {
        var placement_name = checkPlacementName(location?.search.replace("?placement_cycle=", ""));
        setPlacementSession({
          id: location?.search.replace("?placement_cycle=", ""),
          name: placement_name,
        });
      } else {
        setPlacementSession({ id: "All", name: "All Placement Cycles" });
      }
    }
  }, [location, placementSessionList?.length]);

  const checkPlacementName = (id) => {
    var name = "";
    placementSessionList.map((item) => {
      if (item.id === id) name = item.name;
    });
    return name;
  };

  const [tempStudentList, setTempStudentList] = useState([]);
  useEffect(() => {
    if (studentList?.length > 0) {
      var arr = [...studentList];
      let new_arr = [];
      var obj = {};
      arr.forEach((item) => {
        var a = [...item?.placement_cycle_list];
        var n = a.filter((i) => i.is_selected);

        new_arr.push({
          name: item?.name,
          enrollment_id: item?.enrollment_id,
          email: item?.email,
          phone: item?.phone,
          batch: item?.batch,
          degree: item?.degree?.name,
          course: item?.course?.name,
          status: item?.status,
          cgpa: item?.cgpa,
          applied_jobs: item?.applied_jobs,
          created: item?.created,
          placement_cycles: generateCsv(n, "name"),
        });
      });
      setTempStudentList(new_arr);
    }
  }, [studentList?.length]);

  useEffect(() => {
    let new_arr = [];

    if (selectedRowsData?.length > 0) {
      let arr = [...selectedRowsData];
      var obj = {};
      arr.forEach((item) => {
        var a = [...item?.placement_cycle_list];
        var n = a.filter((i) => i.is_selected);
        new_arr.push({
          name: item?.name,
          enrollment_id: item?.enrollment_id,
          email: item?.email,
          phone: item?.phone,
          batch: item?.batch,
          degree: item?.degree?.name,
          course: item?.course?.name,
          status: item?.status,
          cgpa: item?.cgpa,
          applied_jobs: item?.applied_jobs,
          created: item?.created,
          placement_cycles: generateCsv(n, "name"),
        });
        new_arr.push(obj);
      });
      setTempStudentList(new_arr);
    } else {
      if (studentList?.length > 0) {
        var arr = [...studentList];
        let new_arr = [];
        var obj = {};
        arr.forEach((item) => {
          var a = [...item?.placement_cycle_list];
          var n = a.filter((i) => i.is_selected);

          new_arr.push({
            name: item?.name,
            enrollment_id: item?.enrollment_id,
            email: item?.email,
            phone: item?.phone,
            batch: item?.batch,
            degree: item?.degree?.name,
            course: item?.course?.name,
            status: item?.status,
            cgpa: item?.cgpa,
            applied_jobs: item?.applied_jobs,
            created: item?.created,
            placement_cycles: generateCsv(n, "name"),
          });
        });
        setTempStudentList(new_arr);
      }
    }
  }, [selectedRowsData?.length]);

  const [filterDataText, setFilterDataText] = useState(null);
  useEffect(() => {
    if (
      (filters.status_blocked || filters.status_active || filters.batch || filters.course || filters.degree || applyClickedScore || clickSearchButton) &&
      studentList?.length === 0
    ) {
      setFilterDataText("No Results Found");
    }
  }, [filters.status_blocked, filters.status_active, filters.batch, filters.course, filters.degree, applyClickedScore, clickSearchButton]);

  useEffect(() => {
    if (filters?.current_tab?.params === "pending_students") {
      setPlacementSession({ id: "All", name: "All Placement Cycles" });
    }
  }, [filters?.current_tab]);

  useEffect(() => {
    if (callNavbar) {
      var url_filter = `api/manage_student/navbar/?college_id=${collegeId}${checkValue()}${searchFilter()}${departmentFilter()}${batchFilter()}${statusFilter()}${degreeFilter()}${scoreFilter()}${checkSelectedGroup()}`;

      dispatch(StudentNavbarAPI(url_filter));
    }
  }, [callNavbar]);

  useEffect(() => {
    if (collegeGroupList?.length > 0 && isCollegeGroup) {
      setNewCollegeGroupList([{ id: "All", name: "All Groups" }].concat(collegeGroupList));
    }
    else
      setNewCollegeGroupList(collegeGroupList);
  }, [collegeGroupList?.length]);


  return (
    <>
      {/* <Layout> */}
      {collegeGroupList?.length > 0 && (
        <div style={{ marginTop: "5px", marginBottom: "5px" }}>
          <GroupListDropdown title="Group" state={selectedGroup} setState={setSelectedGroup} groupList={newCollegeGroupList} />
        </div>
      )}
      {loadingPlacement ? (
        <CustomCircularProgress />
      ) : (
        <>
          <div className={classes.grid}>
            <div>
              <Typography variant="h2">Manage Students</Typography>
              <Typography
                variant="body2"
                color="secondary"
                // style={{ marginTop: "10px" }}
              >
                Manage your all Students in one place
              </Typography>
              {/* <FormControlLabel
              value="end"
              control={<Checkbox color="primary" size="small" />}
              label={
                <Typography variant="body2" style={{ fontWeight: "bold" }}>
                  Show Data of All Active Placement Sessions
                </Typography>
              }
              labelPlacement="end"
            /> */}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              {userRolesPermission?.some((item) => item.access_control_key === "manage_students") && (
                <>
                  <div style={{ display: "flex" }}>
                    {filters?.current_tab?.params !== "pending_students" && (
                      <>
                        <AddNewStudent studentList={studentList} currentTab={filters?.status} placementSession={placementSession} collegeId={collegeId} fromGroup={true} />
                      </>
                    )}
                    {/* <InviteCompany link={inviteLink} /> */}

                    <CSVLink
                      data={tempStudentList}
                      style={{
                        cursor: "pointer",
                        textDecoration: "none",
                      }}
                      filename={"Students.csv"}
                    >
                      <Button variant="contained" size="small" style={{ marginRight: "0px" }} className={classes.button} startIcon={<GetAppIcon />}>
                        Export
                      </Button>
                    </CSVLink>
                  </div>
                  {filters?.current_tab?.params !== "pending_students" && (
                    <div style={{ marginTop: "10px" }}>
                      <ShortlistEligible collegeId={collegeId} />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <div style={{ marginTop: "-25px" }}>
            <StudentTab
              loading={loading}
              filters={filters}
              setFilters={setFilters}
              setClickSearchButton={setClickSearchButton}
              setSelectedRowsData={setSelectedRowsData}
              selectedRowsData={selectedRowsData}
              clickSearchButton={clickSearchButton}
              value={value}
              setValue={setValue}
              navbarData={navbarData}
              filterList={filterList}
              setApplyClickedScore={setApplyClickedScore}
              filterDataText={filterDataText}
              setFilterDataText={setFilterDataText}
              collegeId={collegeId}
              userRolesPermission={userRolesPermission}
              setCallNavbar={setCallNavbar}
              checkSelectedGroup={checkSelectedGroup}
              clearData={clearStudentData}
              selectedID={selectedID}
              setSelectedID={setSelectedID}
            />
          </div>
        </>
      )}
      {/* </Layout> */}
    </>
  );
}
