import { Grid, Typography, Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { PlacementCyclesGetAPI } from "../../api/PlacementCycles/action";
import {
  collegeDetailSelectorFn,
  collegeIdFn,
  userRolesActionsFn,
} from "../../api/SignIn/selector";
import PlacementCycle from "../../components/Cards/PlacementCycle";
import CustomButtonCircularProgress from "../../components/CircularProgressBar/index2";
import AddCycle from "../../components/DialogSection/AddCycle";
import NoDataFunc from "../../components/NoDataSVG";
import { Tabs, Tab } from "@mui/material";
import { ThumbDownAltSharp, ThumbUpSharp } from "@mui/icons-material";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  thumsup: {
    color: theme.palette.primary.main,
  },
  tabSection: {
    width: "70%",
    display: "flex",
    justifyContent: "center",
  },
  tabBtn: {
    width: "50%",
  },
}));

export default function Placement() {
  const classes = useStyles();
  const alert = useAlert();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const collegeDetails = useSelector(collegeDetailSelectorFn);
  const collegeId = useSelector(collegeIdFn);
  const licenceData = useSelector((state) => state?.commonData?.licenceData);
  const [tabActive, settabActive] = useState(0);

  useEffect(() => {
    if (collegeId)
      dispatch(
        PlacementCyclesGetAPI(setLoading, alert, history, location, collegeId)
      );
  }, [collegeId]);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    if (licenceData && licenceData?.display) {
      alert.error(licenceData?.display_message);
      return;
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const placementList = useSelector((state) => state.PlacementCycles.details);
  const userRolesPermission = useSelector(userRolesActionsFn);

  const [placementCyclesToShow, setplacementCyclesToShow] = useState(
    placementList ?? []
  );

  useEffect(() => {
    if (placementList && placementList?.length > 0) {
      setplacementCyclesToShow(placementList);
    }
  }, [placementList]);

  const handleChange = (e, newValue) => {
    let activeCycleFlag = e.target.innerText === "Active Cycles";

    let activeCycles = placementList?.filter((item) =>
      activeCycleFlag ? !item?.is_ended : item?.is_ended
    );
    if (activeCycleFlag) {
      settabActive(0);
    } else {
      settabActive(1);
    }

    setplacementCyclesToShow(activeCycles);
  };

  return (
    <>
      {/* <Layout> */}
      <Grid container spacing={5}>
        <Grid item xs={9} style={{ paddingBottom: "0px" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <section style={{ width: "30%" }}>
              <Typography variant="h2">Placement Cycle</Typography>

              <Typography variant="body2" style={{ color: "#6c757d" }}>
                Manage All Placement Cycles
              </Typography>
            </section>
            {/* <section className={classes.tabSection} onClick={handleChange}> 
          
            <Button variant="contained"
              color={tabActive === 0 ? "primary" :'gray'} 
              style={{
                borderRadius: "8px 0px 0px 8px",
                boxShadow: "none",
                height: "40px",
                display:'flex',
                fontSize: "16px",
                fontWeight: "normal",
                paddingLeft: "25px",
                paddingRight: "25px",
              }}>Active Cycles</Button>

            <Button 
              variant="contained"
              color={tabActive === 1 ? "primary" :'gray'} 
              style={{
                borderRadius: "0px 8px 8px 0px",
                boxShadow: "none",
                height: "40px",
                fontSize: "16px",
                display:'flex',
                fontWeight: "normal",
                paddingLeft: "25px",
                paddingRight: "25px",
              }}
            >Inactive Cycles</Button>
            
          </section> */}
          </div>
        </Grid>
        <Grid
          item
          xs={3}
          style={{
            paddingBottom: "0px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <div>
            <AddCycle
              open={open}
              handleClickOpen={handleClickOpen}
              handleClose={handleClose}
              collegeId={collegeId}
              userRolesPermission={userRolesPermission}
            />
          </div>
        </Grid>

        {loading ? (
          [0, 1, 2, 3, 4, 5, 6, 7].map((item) => (
            <Grid item xs={12} sm={4}>
              <Skeleton
                variant="rectangular"
                style={{ width: "100%" }}
                height={230}
              />
            </Grid>
          ))
        ) : //  placementCyclesToShow?.map((item) => (
        //   <Grid item xs={12} sm={4}>
        //     <PlacementCycle
        //       item={item}
        //       collegeId={collegeId}
        //       userRolesPermission={userRolesPermission}
        //     />
        //   </Grid>
        // ))

        placementCyclesToShow?.length > 0 ? (
          placementCyclesToShow?.map((item) => (
            <Grid item xs={12} sm={4}>
              <PlacementCycle
                item={item}
                collegeId={collegeId}
                userRolesPermission={userRolesPermission}
              />
            </Grid>
          ))
        ) : (
          <NoDataFunc handleClickOpen={handleClickOpen} />
        )}
        {/* <Grid item xs={12} sm={4}>
            <PlacementCycle />
          </Grid>
          <Grid item xs={12} sm={4}>
            <PlacementCycle />
          </Grid> */}
      </Grid>
      {/* </Layout> */}
    </>
  );
}
