import { Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { EDGStudentsAPI } from "../../api/University/APIs/action";
import UplacementCycle from "../../components/Cards/UniversityGraph/UPlcementCycle";
import CustomCircularProgress from "../../components/CircularProgressBar";
import { collegeGroupListSelectorFn, isCollegeGroupFn } from "../../api/SignIn/selector";
import GroupListDropdown from "../../components/Input/DropDownButton/GroupListDropdown";
import { generateCsvForParams } from "../../utility/commaSeparatedStrings";

function UniversityManageStudent() {
  const dispatch = useDispatch();
  const alert = useAlert();
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const collegeGroupList = useSelector(collegeGroupListSelectorFn);

  const [newCollegeGroupList, setNewCollegeGroupList] = useState([]);

  const isCollegeGroup = useSelector(isCollegeGroupFn);

  const checkSelectedGroup = () => {
    if (isCollegeGroup) {
      if (!selectedGroup && collegeGroupList?.length === 1) return `&group_id=${collegeGroupList[0]?.id}`;
      else if (selectedGroup && selectedGroup.id !== "All") return `&group_id=${selectedGroup.id}`;
      else return `&group_id=${generateCsvForParams(collegeGroupList?.map((item) => item.id))}`;
    } else {
      if (selectedGroup && selectedGroup.id !== "All") return `&group_id=${selectedGroup.id}`;
      else return ``;
    }
  };

  useEffect(() => {
    if (collegeGroupList?.length > 0 && isCollegeGroup) {
      setNewCollegeGroupList([{ id: "All", name: "All Groups" }].concat(collegeGroupList));
    }
    else
    setNewCollegeGroupList(collegeGroupList);
  }, [collegeGroupList?.length]);


  // useEffect(() => {
  //   if(selectedGroup)
  //   dispatch(EDGJobsAPI(setLoading, alert, history, location, selectedGroup?.id));
  // }, [selectedGroup]);
  useEffect(() => {
    if (selectedGroup)
      dispatch(
        EDGStudentsAPI(setLoading, alert, history, location, checkSelectedGroup())
      );
  }, [selectedGroup]);

  useEffect(() => {
      dispatch(
        EDGStudentsAPI(setLoading, alert, history, location, checkSelectedGroup())
      );
  }, []);

  const collegeStudentsList = useSelector(
    (state) => state?.EDGCommon?.edgStudentsList
  );


  return (
    <>
      {/* <UniversityLayout> */}
      {collegeGroupList?.length > 0 && (
        <div style={{ marginTop: "5px", marginBottom: "5px" }}>
          <GroupListDropdown
            title="Group"
            state={selectedGroup}
            setState={setSelectedGroup}
            groupList={newCollegeGroupList}
          />
        </div>
      )}
      {loading ? (
        <CustomCircularProgress />
      ) : (
        <>
          <Typography variant="h2">Manage Students</Typography>
          <div style={{ marginTop: "20px" }}>
            <Grid container spacing={5}>
              {collegeStudentsList?.length > 0 &&
                collegeStudentsList?.map((item) => (
                  <Grid item xs={4}>
                    <UplacementCycle
                      item={item}
                      countTitle={"Registered Students"}
                      count={item?.registered_students}
                      link={`/manage-students`}
                      linkTitle={"View All Students Info"}
                      from={"Students"}
                      secondaryCountTitle={"Placed Students"}
                      secondaryCount={item?.placed_students}
                      thirdCount={item?.active_registered_students}
                      thirdCountTitle={"Active Students"}
                      fourthCount={item?.active_placed_students}
                      fourthCountTitle={"Active Placed Students"}
                    />
                  </Grid>
                ))}
            </Grid>
          </div>
        </>
      )}
      {/* </UniversityLayout> */}
    </>
  );
}

export default UniversityManageStudent;
