import { Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  collegeDetailSelectorFn,
  collegeListSelectorFn,
  entityInfoSelectorFn
} from "../../../api/SignIn/selector";
import {
  EDGCollegeGroupAPI,
  EDGCollegeGroupsAPI
} from "../../../api/University/APIs/action";
import AllGroups from "../../../components/Cards/UniversityGraph/UPlcementCycle/groups";
import CustomCircularProgress from "../../../components/CircularProgressBar";
import AddGroups from "../../../components/DialogSection/AddGroups";

function AllSubGroups() {
  const dispatch = useDispatch();
  const alert = useAlert();
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [selectedCurrentItem, setSelectedCurrentItem] = useState(false);
  const collegeDetails = useSelector(collegeDetailSelectorFn);
  const entityInfo = useSelector(entityInfoSelectorFn);
  const [collegeListArray, setCollegeListArray] = useState([]);
  const [adminListArray, setAdminListArray] = useState([]);

  useEffect(() => {
    // dispatch(EDGCollegesAPI(setLoading, alert, history, location));
    if (collegeDetails?.education_group_id) {
      dispatch(EDGCollegeGroupAPI(alert, history, location));
      dispatch(
        EDGCollegeGroupsAPI(
          setLoading,
          alert,
          history,
          location,
          collegeDetails?.education_group_id
        )
      );
    }
  }, [collegeDetails?.education_group_id]);

  const collegeList = useSelector(collegeListSelectorFn);
  const groupList = useSelector((state) => state?.EDGCommon?.collegeGroups);
  const adminList = useSelector((state) => state?.EDGCommon?.admins);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    // dispatch(
    //   EDGCollegeGroupsAPI(
    //     setLoading,
    //     alert,
    //     history,
    //     location,
    //     collegeDetails?.education_group_id
    //   )
    // );
  };

  useEffect(() => {
    if (collegeList?.length) {
      var arr = [...collegeList];
      arr.forEach((item) => {
        item.is_selected = false;
      });
      setCollegeListArray(arr);
    }
  }, [collegeList]);

  useEffect(() => {
    if (adminList?.length) {
      var arr = [...adminList];
      arr.forEach((item) => {
        item.is_selected = false;
      });
      setAdminListArray(arr);
    }
  }, [adminList]);

  return (
    <>
      {/* <UniversityLayout> */}
      {loading ? (
        <CustomCircularProgress />
      ) : (
        <>
          {" "}
          <Grid container spacing={5}>
            <Grid item xs={6} style={{ paddingBottom: "0px" }}>
              <Typography variant="h2">{entityInfo?.college} Groups</Typography>
              <Typography
                variant="body2"
                color="secondary"
                // style={{ marginTop: "10px" }}
              >
                Manage your {entityInfo?.college} Groups
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                paddingBottom: "0px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <div>
                <AddGroups
                  open={open}
                  handleClickOpen={handleClickOpen}
                  handleClose={handleClose}
                  selectedCurrentItem={selectedCurrentItem}
                  setSelectedCurrentItem={setSelectedCurrentItem}
                  collegeList1={collegeListArray}
                  setCollegeList={setCollegeListArray}
                  adminList1={adminListArray}
                  setAdminList={setAdminListArray}
                  groupList={groupList}
                  collegeDetails={collegeDetails}
                />
              </div>
            </Grid>
          </Grid>
          <div style={{ marginTop: "20px" }}>
            <Grid container spacing={5}>
              {groupList?.length > 0 &&
                groupList?.map((item) => (
                  <Grid item xs={4}>
                    <AllGroups item={item} />{" "}
                  </Grid>
                ))}
            </Grid>
          </div>
        </>
      )}
      {/* </UniversityLayout> */}
    </>
  );
}

export default AllSubGroups;
