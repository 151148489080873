import { Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { collegeGroupListSelectorFn, isCollegeGroupFn, isEducationGroupFn } from "../../../api/SignIn/selector";
import { EDGAllAPI, EDGAllAPIUrl } from "../../../api/University/APIs/action";
import UplacementCycle from "../../../components/Cards/UniversityGraph/UPlcementCycle";
import CustomCircularProgress from "../../../components/CircularProgressBar";
import GroupListDropdown from "../../../components/Input/DropDownButton/GroupListDropdown";
import { BackendBaseApi } from "../../../constants/constants";
import { generateCsvForParams } from "../../../utility/commaSeparatedStrings";

function UniversityJobERF() {
  const dispatch = useDispatch();
  const alert = useAlert();
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const isEducationGroup = useSelector(isEducationGroupFn);
  const isCollegeGroup = useSelector(isCollegeGroupFn);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const collegeGroupList = useSelector(collegeGroupListSelectorFn);
  
  const checkSelectedGroup = () => {
    if (isCollegeGroup) {
      if (!selectedGroup && collegeGroupList?.length === 1) return `&group_id=${collegeGroupList[0]?.id}`;
      else if (selectedGroup && selectedGroup.id !== "All") return `&group_id=${selectedGroup.id}`;
      else return `&group_id=${generateCsvForParams(collegeGroupList?.map((item) => item.id))}`;
    } else {
      if (selectedGroup && selectedGroup.id !== "All") return `&group_id=${selectedGroup.id}`;
      else return ``;
    }
  };

  useEffect(() => {
    if (selectedGroup) {
      var url = `${BackendBaseApi.SANCHALAN}api/university/university_count/?${checkSelectedGroup()}`;
      dispatch(EDGAllAPIUrl(setLoading, alert, history, location, url));
    }
  }, [selectedGroup]);

  const [newCollegeGroupList, setNewCollegeGroupList] = useState([]); 

  useEffect(() => {
    if(collegeGroupList?.length > 0){
    if (isCollegeGroup) {
      setNewCollegeGroupList([{ id: "All", name: "All Groups" }].concat(collegeGroupList));
      setSelectedGroup({ id: "All", name: "All Groups" });
    }
    else {
    setNewCollegeGroupList(collegeGroupList);
    setSelectedGroup(collegeGroupList[0]);
    }}
  }, [collegeGroupList?.length]);

  const collegeCompanyErfList = useSelector((state) => state?.EDGCommon?.edgList);
  return (
        <>
          {collegeGroupList?.length > 0 && (
            <div style={{ marginTop: "5px", marginBottom: "5px" }}>
              <GroupListDropdown title="Group" state={selectedGroup} setState={setSelectedGroup} groupList={newCollegeGroupList} />
            </div>
          )}
          <br />
          {loading ? (
        <CustomCircularProgress />
      ) : (
        <>
          <Typography variant="h2">Job Management Forms</Typography>
          <div style={{ marginTop: "20px" }}>
            <Grid container spacing={5}>
              {collegeCompanyErfList?.length > 0 &&
                collegeCompanyErfList?.map((item) => (
                  <Grid item xs={4}>
                    <UplacementCycle item={item} link={`/job-posting-form`} linkTitle={"View Job Posting Form"} from={"JobERF"} />
                  </Grid>
                ))}
            </Grid>
          </div>
        </>
      )}
      {/* </UniversityLayout> */}
    </>
  );
}

export default UniversityJobERF;
