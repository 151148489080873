import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import {
  Typography,
  Grid,
  IconButton,
  TextField,
  SvgIcon,
  Box,
  Divider,
} from "@material-ui/core";
import PaymentDialog from "./PaymentDialog";
import { useAlert } from "react-alert";

const token = (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 12.3484C5.81953 12.3484 0 9.92109 0 6.93008C0 3.93906 5.81953 1.51172 13 1.51172C20.1805 1.51172 26 3.93906 26 6.93008C26 9.92109 20.1805 12.3484 13 12.3484ZM13 21.0117C17.9613 21.0117 22.3488 19.8184 25.1164 17.975C25.6801 18.5844 26 19.2395 26 19.9301C26 22.9211 20.1805 25.3484 13 25.3484C5.81953 25.3484 0 22.9211 0 19.9301C0 19.2395 0.319921 18.5844 0.883593 17.9801C3.65117 19.8184 8.03867 21.0117 13 21.0117ZM13 14.5117C17.9613 14.5117 22.3488 13.3184 25.1164 11.475C25.6801 12.0844 26 12.7395 26 13.4301C26 16.4211 20.1805 18.8484 13 18.8484C5.81953 18.8484 0 16.4211 0 13.4301C0 12.7395 0.319921 12.0844 0.883593 11.4801C3.65117 13.3184 8.03867 14.5117 13 14.5117Z"
      fill="#029E73"
    />
  </svg>
);

function TokenIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5 4.75C2.23828 4.75 0 3.81641 0 2.66602C0 1.51562 2.23828 0.582031 5 0.582031C7.76172 0.582031 10 1.51562 10 2.66602C10 3.81641 7.76172 4.75 5 4.75ZM5 8.08203C6.9082 8.08203 8.5957 7.62305 9.66016 6.91406C9.87695 7.14844 10 7.40039 10 7.66602C10 8.81641 7.76172 9.75 5 9.75C2.23828 9.75 0 8.81641 0 7.66602C0 7.40039 0.123047 7.14844 0.339844 6.91602C1.4043 7.62305 3.0918 8.08203 5 8.08203ZM5 5.58203C6.9082 5.58203 8.5957 5.12305 9.66016 4.41406C9.87695 4.64844 10 4.90039 10 5.16602C10 6.31641 7.76172 7.25 5 7.25C2.23828 7.25 0 6.31641 0 5.16602C0 4.90039 0.123047 4.64844 0.339844 4.41602C1.4043 5.12305 3.0918 5.58203 5 5.58203Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
}

export default function AddCreditsDialog({
  handleClose,
  handleClickOpen,
  open,
  creatOrder,
}) {
  const alert = useAlert();
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("md");
  const [openpayment, setOpenPayment] = React.useState(false);
  const [payment, setPayment] = useState(false);

  const [credit, setCredit] = useState(0);

  const initialOrderSummary = [
    {
      id: "amount",
      title: "Your Amount",
      value: 0,
    },
    {
      id: "sgst",
      title: "SGST (9%)",
      value: 0,
    },
    {
      id: "gst",
      title: "GST (9%)",
      value: 0,
    },
  ];
  const [orderSummary, setOrderSummary] = useState(initialOrderSummary);

  useEffect(() => {
    const sgst = credit * 0.09;
    const gst = credit * 0.09;

    setOrderSummary([
      {
        id: "amount",
        title: "Your Amount",
        value: credit,
      },
      {
        id: "sgst",
        title: "SGST (9%)",
        value: sgst,
      },
      {
        id: "gst",
        title: "GST (9%)",
        value: gst,
      },
    ]);
  }, [credit]);

  const handleClickOpenPayment = () => {
    setOpenPayment(true);
  };

  const handleClosePayment = () => {
    setOpenPayment(false);
  };

  const handleCreditChange = (e) => {
    const value = e.target.value;
    if (value === "" || value < 0) {
      setCredit(0);
    } else if (!isNaN(value)) {
      setCredit(Number(value));
    }
  };

  const handleAddCredit = () => {
    creatOrder(orderSummary);
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <div
          style={{
            padding: 20,
            paddingBottom: 30,
            paddingLeft: "3%",
            paddingRight: "3%",
            position: "relative",
          }}
        >
          <div>
            <Grid
              container
              spacing={4}
              style={{
                margin: 0,
                width: "100%",
                backgroundColor: "#F5F7F8",
                borderRadius: 10,
              }}
            >
              <Grid item md={12}>
                <Typography
                  variant="body1"
                  style={{ fontWeight: "600", fontSize: "16px" }}
                >
                  Available Credits
                </Typography>
                <Typography
                  variant="h3"
                  style={{
                    marginTop: "10px",
                    fontSize: "36px",
                    color: "#029E73",
                    fontWeight: "600",
                  }}
                >
                  {token}1,000
                </Typography>
                <span
                  style={{
                    fontSize: "16px",
                    fontStyle: "italic",
                    color: "#5F6265",
                    marginTop: "-10px",
                  }}
                >
                  Note : 1 Credits = ₹ 1
                </span>
              </Grid>

              <Grid item md={12}>
                <Typography
                  variant="body1"
                  style={{
                    fontWeight: "600",
                    fontSize: "16px",
                    marginBottom: 5,
                  }}
                >
                  Add Credits
                </Typography>

                <TextField
                  value={credit}
                  onChange={handleCreditChange}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item md={12}>
                <Typography
                  variant="body1"
                  style={{
                    fontWeight: "600",
                    fontSize: "16px",
                    marginBottom: 5,
                  }}
                >
                  Quick Add
                </Typography>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {" "}
                  <Button
                    startIcon={<TokenIcon />}
                    variant="outlined"
                    color="primary"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: 7,
                    }}
                    onClick={() => setCredit(500)}
                  >
                    500
                  </Button>
                  <Button
                    startIcon={<TokenIcon />}
                    variant="outlined"
                    color="primary"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: 7,
                    }}
                    onClick={() => setCredit(1000)}
                  >
                    1,000
                  </Button>{" "}
                  <Box style={{ position: "relative" }}>
                    <Box
                      style={{
                        backgroundColor: "#F69D61",
                        borderRadius: "30px",
                        padding: 5,
                        paddingLeft: 10,
                        paddingRight: 10,
                        position: "absolute",
                        top: "-20px",
                        left: "15px",
                        zIndex: 1,
                        fontSize: 10,
                        fontWeight: 600,
                      }}
                    >
                      Maximum
                    </Box>
                    <Button
                      startIcon={<TokenIcon />}
                      variant="outlined"
                      color="primary"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#fff",
                      }}
                      onClick={() => setCredit(1500)}
                    >
                      1,500
                    </Button>
                  </Box>
                </div>
              </Grid>
            </Grid>
          </div>

          {credit ? (
            <div style={{ marginTop: "15px" }}>
              <Grid
                container
                spacing={4}
                style={{
                  margin: 0,
                  width: "100%",
                  backgroundColor: "#F5F7F8",
                  borderRadius: 10,
                }}
              >
                <Grid item md={12}>
                  <div>
                    <Typography
                      style={{
                        fontSize: "16px",
                        textAlign: "left",
                        fontWeight: "600",
                        marginBottom: "10px",
                      }}
                    >
                      Order Summary
                    </Typography>
                    {orderSummary.map((item) => (
                      <>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            marginBottom: "12px",
                          }}
                        >
                          <Typography
                            variant="body1"
                            noWrap
                            style={{
                              color: "#6C757D",
                              fontSize: "14px",
                            }}
                          >
                            {item.title}
                          </Typography>
                          <Typography
                            style={{
                              fontWeight: "600",
                              color: "#000",
                              fontSize: "14px",
                            }}
                          >
                            ₹ {item.value}
                          </Typography>
                        </div>
                      </>
                    ))}
                    <Divider />
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginTop: "12px",
                      }}
                    >
                      <Typography
                        variant="body1"
                        noWrap
                        style={{
                          color: "#6C757D",
                          fontSize: "14px",
                        }}
                      >
                        Total (Inc. GST)
                      </Typography>
                      <Typography
                        style={{
                          fontWeight: "600",
                          color: "#000",
                          fontSize: "14px",
                        }}
                      >
                        ₹{" "}
                        {orderSummary[0].value +
                          orderSummary[1].value +
                          orderSummary[2].value}
                      </Typography>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          ) : null}

          <Button
            variant="contained"
            fullWidth
            color="primary"
            style={{ boxShadow: "none", marginTop: "15px", height: 50 }}
            onClick={handleAddCredit}
            disabled={credit === 0}
          >
            Add Credits
          </Button>
        </div>
      </Dialog>
    </React.Fragment>
  );
}
