import React, { useEffect, useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import { Hidden } from "@material-ui/core";
import ApplyDialog, {
  UPDATE_AVATAR,
} from "../../DialogSection/Apply/ApplyDialog";
import Logo from "../../../assets/png/getwork-logo.png";
import { Avatar, Button, Tooltip } from "@mui/material";
import { useSelect } from "@mui/base";
import { collegeDetailsSelectorFn } from "../../../api/Domain/selector";
import { useSelector } from "react-redux";
import { useAlert } from "react-alert";
import CustomButtonCircularProgress from "../../CircularProgressBar/index2";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  text: {
    padding: theme.spacing(2, 2, 0),
  },
  paper: {
    paddingBottom: 50,
  },
  list: {
    marginBottom: theme.spacing(2),
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    top: "auto",
    bottom: 0,
  },
  container: {
    [theme.breakpoints.only("xs")]: {
      paddingLeft: "1px !important",
      paddingRight: "1px !important",
    },
  },
  toolbar: {
    justifyContent: "center",
    position: "relative",
    [theme.breakpoints.only("xs")]: {
      justifyContent: "flex-start",
      paddingLeft: 5,
      paddingRight: 5,
    },
  },
}));

export default function PublicJobNavbar({
  children,
  userDetails,
  loginSucessful,
  setLoginSucessful,
  setUserDetails,
  jobDetails,
  jobId,
  applied,
  handleApplyClick,
  college_id,
  disabled,
  loadingApply
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const collegeDetails = useSelector(collegeDetailsSelectorFn);
  const alert = useAlert();
  const disbaled_Text = "REGISTRATION CLOSED";


  const first_name =
    userDetails?.first_name ??
    window.localStorage.getItem("userDetails_first_name");
  const last_name =
    userDetails?.last_name ??
    window.localStorage.getItem("userDetails_last_name");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [showToolTip, setShowToolTip] = useState(false);
  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar color="inherits">
        <Toolbar className={classes.toolbar}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              //   paddingLeft: "40px",
              width: 100,
              backgroundColor: "#fff",
              height: 50,
            }}
          >
            <img
              src={collegeDetails?.logo}
              alt="college-logo"
              style={{
                margin: "auto",
                maxWidth: "100%",
                maxHeight: "100%",
              }}
            />
          </div>
          {/* <Hidden only={["sm", "xs"]}> */}
          <Typography
            variant="h6"
            style={{ color: "#000", fontSize: "15px", marginLeft: "10px" }}
          >
            {collegeDetails?.college_name}
          </Typography>
          {/* </Hidden> */}
          {loginSucessful && (
            <Tooltip title={first_name + " " + last_name} open={showToolTip}>
              <Avatar
                style={{
                  borderColor: "grey",
                  color: "black",
                  fontSize: "1rem",
                  cursor: "pointer",
                  position: "absolute",
                  right: "10px",
                }}
                onClick={() => {
                  setShowToolTip(!showToolTip);
                }}
              >
                {first_name && first_name[0]?.toUpperCase()}
                {last_name && last_name[0]?.toUpperCase()}
              </Avatar>
            </Tooltip>
          )}
        </Toolbar>
      </AppBar>
      <Toolbar id="back-to-top-anchor" />
      <Container maxWidth="xl" className={classes.container}>
        {children}
      </Container>
      <Hidden only={["md", "lg", "xl"]}>
        <AppBar position="fixed" color="primary" className={classes.appBar}>
          <Toolbar
            style={{ justifyContent: "center" }}
            onclick={handleClickOpen}
          >
            <Button
              disabled={disabled}
              style={{ fontWeight: "600", textAlign: "center", color: "#fff" }}
              onClick={() => {
                if (!loginSucessful) handleClickOpen();
                else handleApplyClick();
              }}
            >
              {disabled
                ? disbaled_Text
                : loginSucessful
                  ? loadingApply ? <CustomButtonCircularProgress /> : applied
                    ? "APPLIED"
                    : "APPLY"
                  : "LOGIN TO APPLY"}
            </Button>
          </Toolbar>
        </AppBar>

        <ApplyDialog
          open={open}
          setOpen={setOpen}
          handleClickOpen={handleClickOpen}
          alert={alert}
          job_id={jobId}
          jobDetails={jobDetails}
          loginSuccessful={loginSucessful}
          setLoginSucessful={setLoginSucessful}
          setUserDetails={setUserDetails}
          college_id={college_id}
        />
      </Hidden>
    </React.Fragment>
  );
}
