import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import { DropzoneAreaBase } from "material-ui-dropzone";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { IndustryGetAPI } from "../../../api/Common/action";
import { AllLocationsAPI, AllLocationsAPII } from "../../../api/JobPost/action";
import {
  collegeListSelectorFn,
  isCollegeGroupFn,
  isEducationGroupFn,
} from "../../../api/SignIn/selector";
import { BackendBaseApi } from "../../../constants/constants";
import { groupViewCheck } from "../../../utility/groupViewCheck";
import CustomButtonCircularProgress from "../../CircularProgressBar/index2";
import AddCompanyMember from "../../DialogSection/AddNewCompany/AddCompanyMember";
import Editor from "../../Editor";
import OnlyCollegeList from "../../GroupCollegeList/onlyCollegeList";
import Calendar from "../../Input/Calendar/index5";
import CompanyDetailsTable from "../../Table/CompanyDetailsTable";
import { checkForBreadcrumb } from "../../../utility/checkBreadcrumbs";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: "12px",
    marginBottom: "5px",
    width: "100%",
  },

  btn: {
    borderRadius: "8px",
    boxShadow: "none",
    marginTop: "30px",
    marginBottom: "30px",
    paddingLeft: "70px",
    paddingRight: "70px",
  },

  btn2: {
    borderRadius: "8px",
    boxShadow: "none",
    height: "48px",
    marginBottom: "30px",
    marginRight: "12px",
    paddingLeft: "70px",
    paddingRight: "70px",
    minWidth: "200px",
  },
  upload: {
    borderRadius: "8px",
    boxShadow: "none",
    paddingTop: "10px",
    marginTop: "20px",
    marginBottom: "20px",
    paddingBottom: "10px",
    minWidth: "150px",
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  DropzoneArea: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    margin: 0,
    padding: 0,
  },
  circularProgress: {
    color: theme.palette.common.white,
  },
}));

export default function EditCompany({
  companyData,
  industryList,
  collegeId,
  userRolesPermission,
}) {
  const classes = useStyles();
  const [editor, setEditor] = React.useState(null);
  const [pocList, setPocList] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [loadingLocations, setLoadingLocations] = useState(false);
  const [loadingLogo, setLoadingLogo] = useState(false);
  const alert = useAlert();
  const params = useParams();
  const isEducationGroup = useSelector(isEducationGroupFn);
  const isCollegeGroup = useSelector(isCollegeGroupFn);
  const [editReason, setEditReason] = useState(null);
  const [editReasonError, setEditReasonError] = useState(null);

  const [companyDetailsError, setCompanyDetailsError] = useState({
    college_list: null,
  });
  const mainCollegeList = useSelector(collegeListSelectorFn);

  useEffect(() => {
    dispatch(IndustryGetAPI(alert, history, location));
  }, []);

  const checkCollege = (college_id_list) => {
    var arr = [];
    college_id_list.map((item) => {
      arr.push({ id: item, name: checkCollegeName(item) });
    });
    return arr;
  };

  const checkCollegeName = (id) => {
    var name = "";
    mainCollegeList?.map((item) => {
      if (item?.id === id) name = item?.name;
    });
    return name;
  };
  const [companyDetails, setCompanyDetails] = useState({
    company_name: companyData?.name,
    company_website: companyData?.company_website,
    company_address: companyData?.company_address,
    company_turnover: companyData?.company_turnover,
    landline: companyData?.landline,
    company_size: companyData?.company_size,
    industry:
      companyData?.industry_id && companyData?.industry_name
        ? { id: companyData?.industry_id, name: companyData?.industry_name }
        : null,
    industry_type:
      companyData?.industry_type_id && companyData?.industry_type_name
        ? {
            id: companyData?.industry_type_id,
            name: companyData?.industry_type_name,
          }
        : null,
    company_location:
      companyData?.company_location?.id &&
      companyData?.company_location?.name &&
      companyData?.company_location?.state &&
      companyData?.company_location?.state_id
        ? {
            city_id: companyData?.company_location?.id,
            city: companyData?.company_location?.name,
            state_id: companyData?.company_location?.state_id,
            state: companyData?.company_location?.state,
          }
        : null,
    linkedin: companyData?.linkedin,
    glassdoor: companyData?.glassdoor,
    about: companyData?.about,
    logoData: companyData?.logo,
    logo: null,
    visiting_date: companyData?.visiting_date,
    college_list: checkCollege(companyData?.college_id),
  });

  useEffect(() => {
    if (companyData) {
      setCompanyDetails({
        ...companyDetails,
        company_name: companyData?.name,
        company_website: companyData?.company_website,
        landline: companyData?.landline,
        industry:
          companyData?.industry_id && companyData?.industry_name
            ? { id: companyData?.industry_id, name: companyData?.industry_name }
            : null,
        industry_type:
          companyData?.industry_type_id && companyData?.industry_type_name
            ? {
                id: companyData?.industry_type_id,
                name: companyData?.industry_type_name,
              }
            : null,
        company_location:
          companyData?.company_location?.id &&
          companyData?.company_location?.name &&
          companyData?.company_location?.state &&
          companyData?.company_location?.state_id
            ? {
                city_id: companyData?.company_location?.id,
                city: companyData?.company_location?.name,
                state_id: companyData?.company_location?.state_id,
                state: companyData?.company_location?.state,
              }
            : null,
        linkedin: companyData?.linkedin,
        glassdoor: companyData?.glassdoor,
        about: companyData?.about,
        logoData: companyData?.logo,
        logo: null,
        visiting_date: companyData?.visiting_date,
        college_list: checkCollege(companyData?.college_id),
        company_address: companyData?.company_address,
        company_turnover: companyData?.company_turnover,
        company_size: companyData?.company_size,
      });
    }
  }, [companyData]);

  useEffect(() => {
    dispatch(AllLocationsAPII(setLoadingLocations, alert, history, location));
  }, []);

  useEffect(() => {
    if (companyData) {
      setPocList(companyData?.poc_list);
      setEditor(companyData?.about);
    }
  }, [companyData]);

  useEffect(() => {
    setCompanyDetails({ ...companyDetails, about: editor });
  }, [editor]);

  const [loadingPost, setLoadingPost] = useState(false);
  const cityList = useSelector((state) =>
    state?.AllLocations?.details?.data ? state?.AllLocations?.details?.data : []
  );

  // const timer = React.useRef();

  const updateProfile = () => {
    // if (!loadingPost) {
    //   //setSuccess(false);
    //   setLoadingPost(true);
    //   timer.current = window.setTimeout(() => {
    //     //setSuccess(true);
    //     setLoadingPost(false);
    //   }, 2000);
    // }
    if (companyDetails?.college_list?.length === 0) {
      alert.error("Please Select atleast one College from List");
      return;
    }
    var company_details = {
      company_name: companyDetails.company_name,
      company_website: companyDetails.company_website,
      industry_id: companyDetails.industry.id,
      industry_name: companyDetails.industry.name,
      industry_type_id: companyDetails.industry_type?.id,
      industry_type_name:
        companyDetails.industry_type?.id === 1
          ? "Private"
          : companyDetails.industry_type?.id === 2
          ? "Government"
          : "Semi-Government",
      company_location: companyDetails?.company_location
        ? {
            id: companyDetails?.company_location?.city_id,
            name: companyDetails?.company_location?.city,
            state_id: companyDetails?.company_location?.state_id,
            state: companyDetails?.company_location?.state,
          }
        : null,
      linkedin: companyDetails?.linkedin,
      glassdoor: companyDetails?.glassdoor,
      landline: companyDetails?.landline,
      about: companyDetails?.about,
      visiting_date: companyDetails?.visiting_date,
      company_address: companyDetails?.company_address,
      company_turnover: companyDetails?.company_turnover,
      company_size: companyDetails?.company_size,
    };
    // if (companyData?.visiting_date)
    //   company_details.visiting_date = companyData?.visiting_date;

    //setLoadingPost(true);
    if (!editReason) {
      setEditReasonError("Please enter reason");
      return;
    }
    var data = {
      college_id: companyDetails?.college_list?.map((item) => item?.id),
      company_form_data: company_details,
      poc_list: pocList,
      reason: editReason,
    };
    setLoadingPost(true);
    axios
      .patch(
        `${BackendBaseApi.SANCHALAN}api/company/view/${params?.company_id}`,
        data,
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        if (res.data.success) {
          alert.success("Company Details Successfully Updated!!");
          const partPath = checkForBreadcrumb(
            isEducationGroup,
            isCollegeGroup,
            collegeId,
            "company-profile"
          );
          history.push(`${partPath}/${params?.company_id}`);
          // if (fileNames?.length > 0) updateLogo();
        } else {
          alert.error("Some Error Occurred while uploading");
        }
        setLoadingPost(false);
      })
      .catch((err) => {
        if (err.response.status !== 401)
          alert.error("Some Error Occurred while uploading");
        setLoadingPost(false);
        throw err;
      });
    //setLoadingPost(false);
  };

  const updateLogo = () => {
    var fd = new FormData();
    fd.append("logo", companyDetails?.logo);

    setLoadingLogo(true);
    axios
      .patch(
        `${BackendBaseApi.SANCHALAN}api/company/view/${params?.company_id}`,
        fd,
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        if (res.data.success) {
          alert.success("Logo Successfully Updated!!");
          setLoadingLogo(false);
        } else {
          alert.error("Some Error Occurred while uploading the Logo");
          setLoadingLogo(false);
        }
      })
      .catch((err) => {
        if (err.response.status !== 401)
          alert.error("Some Error Occurred while uploading");

        throw err;
      });
  };

  const getNumberOfEmployees = () => {
    if (companyDetails?.company_size === "1-10") return 1;
    if (companyDetails?.company_size === "11-50") return 2;
    if (companyDetails?.company_size === "51-100") return 3;
    if (companyDetails?.company_size === "101-500") return 4;
    if (companyDetails?.company_size === "501-1000") return 5;
  };

  const getNumberOfEmployeesInString = (num) => {
    if (num === 1) return "1-10";
    if (num === 2) return "11-50";
    if (num === 3) return "51-100";
    if (num === 4) return "101-500";
    if (num === 5) return "501-1000";
  };

  const [collegeList, setCollegeList] = useState([]);

  return (
    <>
      <div>
        <div style={{ width: "70%" }}>
          <TextField
            variant="outlined"
            InputLabelProps={{
              className: classes.floatingLabelFocusStyle,
            }}
            margin="normal"
            fullWidth
            inputProps={{
              style: {
                padding: "16px",
              },
            }}
            value={companyDetails?.company_name}
            onChange={(e) => {
              setCompanyDetails({
                ...companyDetails,
                company_name: e.target.value,
              });
            }}
            style={{ backgroundColor: "#fff", borderRadius: "8px" }}
            label="Company Name"
          />
          <TextField
            variant="outlined"
            margin="normal"
            InputLabelProps={{
              className: classes.floatingLabelFocusStyle,
            }}
            required
            fullWidth
            inputProps={{
              style: {
                padding: "16px",
              },
            }}
            label="Company Website"
            value={companyDetails?.company_website}
            onChange={(e) => {
              setCompanyDetails({
                ...companyDetails,
                company_website: e.target.value,
              });
            }}
            style={{ backgroundColor: "#fff", borderRadius: "8px" }}
          />

          <TextField
            variant="outlined"
            InputLabelProps={{
              className: classes.floatingLabelFocusStyle,
            }}
            margin="normal"
            fullWidth
            inputProps={{
              style: {
                padding: "16px",
              },
            }}
            value={companyDetails?.company_address}
            onChange={(e) => {
              setCompanyDetails({
                ...companyDetails,
                company_address: e.target.value,
              });
            }}
            label="Company Address"
            style={{ backgroundColor: "#fff", borderRadius: "8px" }}
          />

          <Autocomplete
            id="country-select-demo"
            options={cityList ? cityList : []}
            getOptionLabel={(option) => option?.city + ",  " + option?.state}
            value={companyDetails?.company_location}
            onChange={(e, newValue) => {
              setCompanyDetails({
                ...companyDetails,
                company_location: newValue,
              });
            }}
            style={{
              marginTop: 10,
              backgroundColor: "#fff",
              borderRadius: "8px",
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Company Location"
                inputProps={{
                  ...params.inputProps,
                }}
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
              />
            )}
          />

          <Autocomplete
            id="country-select-demo"
            options={industryList ? industryList : []}
            getOptionLabel={(option) => option?.name}
            value={companyDetails.industry}
            onChange={(e, newValue) => {
              setCompanyDetails({ ...companyDetails, industry: newValue });
            }}
            style={{
              marginTop: 20,
              backgroundColor: "#fff",
              borderRadius: "8px",
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Industry"
                inputProps={{
                  ...params.inputProps,
                }}
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
              />
            )}
          />
          <FormControl
            variant="outlined"
            className={classes.formControl}
            style={{
              marginTop: "20px",
              backgroundColor: "#fff",
              borderRadius: "8px",
            }}
          >
            <InputLabel className={classes.floatingLabelFocusStyle}>
              Industry Type
            </InputLabel>
            <Select
              value={companyDetails?.industry_type?.id}
              onChange={(e) => {
                setCompanyDetails({
                  ...companyDetails,
                  industry_type: { id: e.target.value, name: e.target.name },
                });
              }}
              label="Industry Type"
              inputProps={{
                style: {
                  padding: "16px",
                  backgroundColor: "#fff",
                  borderRadius: "8px",
                },
              }}
            >
              <MenuItem value={1} name={"Private"}>
                Private
              </MenuItem>
              <MenuItem value={2} name={"Government"}>
                Government
              </MenuItem>
              <MenuItem value={3} name={"Semi-Government"}>
                Semi-Government
              </MenuItem>
            </Select>
          </FormControl>

          <TextField
            variant="outlined"
            InputLabelProps={{
              className: classes.floatingLabelFocusStyle,
            }}
            margin="normal"
            type="number"
            fullWidth
            inputProps={{
              style: {
                padding: "16px",
              },
            }}
            value={companyDetails?.company_turnover}
            onChange={(e) => {
              setCompanyDetails({
                ...companyDetails,
                company_turnover: e.target.value,
              });
            }}
            label="Company Turnover"
            style={{ backgroundColor: "#fff", borderRadius: "8px" }}
          />

          <FormControl
            variant="outlined"
            className={classes.formControl}
            style={{
              marginTop: "20px",
              backgroundColor: "#fff",
              borderRadius: "8px",
            }}
          >
            <InputLabel className={classes.floatingLabelFocusStyle}>
              Company Size
            </InputLabel>
            <Select
              value={getNumberOfEmployees()}
              onChange={(e) => {
                setCompanyDetails({
                  ...companyDetails,
                  company_size: getNumberOfEmployeesInString(e.target.value),
                });
              }}
              label="Company Size"
              inputProps={{
                style: {
                  padding: "16px",
                  backgroundColor: "#fff",
                  borderRadius: "8px",
                },
              }}
            >
              <MenuItem value={1} name={"1-10"}>
                1-10
              </MenuItem>
              <MenuItem value={2} name={"11-50"}>
                11-50
              </MenuItem>
              <MenuItem value={3} name={"51-100"}>
                51-100
              </MenuItem>
              <MenuItem value={4} name={"101-500"}>
                101-500
              </MenuItem>
              <MenuItem value={5} name={"501-1000"}>
                501-1000
              </MenuItem>
            </Select>
          </FormControl>

          <div style={{ marginTop: "20px" }}>
            <Calendar
              bgColor="#fff"
              selectedDate={companyDetails?.visiting_date}
              title="Visiting Date"
              onDateChange={(date) =>
                setCompanyDetails({
                  ...companyDetails,
                  visiting_date: date,
                })
              }
            />
          </div>

          {groupViewCheck(isEducationGroup, isCollegeGroup, collegeId) && (
            <div style={{ marginTop: 20 }}>
              <OnlyCollegeList
                groupData={companyDetails}
                setGroupData={setCompanyDetails}
                groupDataError={companyDetailsError}
                setGroupDataError={setCompanyDetailsError}
                mainCollegeList={mainCollegeList}
                preSelectedList={companyData?.college_id}
                setCollegeList={setCollegeList}
                collegeList={collegeList}
              />
            </div>
          )}
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            InputLabelProps={{
              className: classes.floatingLabelFocusStyle,
            }}
            inputProps={{
              style: {
                padding: "16px",
              },
            }}
            label="Linkedin URL"
            value={companyDetails?.linkedin}
            onChange={(e) => {
              setCompanyDetails({
                ...companyDetails,
                linkedin: e.target.value,
              });
            }}
            style={{ backgroundColor: "#fff", borderRadius: "8px" }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            InputLabelProps={{
              className: classes.floatingLabelFocusStyle,
            }}
            fullWidth
            inputProps={{
              style: {
                padding: "16px",
              },
            }}
            label="Glassdoor URL"
            value={companyDetails?.glassdoor}
            onChange={(e) => {
              setCompanyDetails({
                ...companyDetails,
                glassdoor: e.target.value,
              });
            }}
            style={{ backgroundColor: "#fff", borderRadius: "8px" }}
          />

          <TextField
            variant="outlined"
            margin="normal"
            InputLabelProps={{
              className: classes.floatingLabelFocusStyle,
            }}
            fullWidth
            inputProps={{
              style: {
                padding: "16px",
              },
            }}
            label="Landline"
            type="number"
            value={companyDetails?.landline}
            onChange={(e) => {
              setCompanyDetails({
                ...companyDetails,
                landline: e.target.value,
              });
            }}
            style={{ backgroundColor: "#fff", borderRadius: "8px" }}
          />
        </div>
        <div style={{ marginTop: "20px", marginBottom: "20px" }}>
          {companyData?.poc_list && companyData?.poc_list?.length > 0 && (
            <CompanyDetailsTable
              companyData={companyData}
              pocList={pocList}
              setPocList={setPocList}
            />
          )}
        </div>
        <AddCompanyMember pocList={pocList} setPocList={setPocList} />
        <div style={{ marginTop: "10px" }}>
          <Typography
            variant="h6"
            style={{ paddingTop: "10px", paddingBottom: "10px" }}
          >
            Upload Logo
          </Typography>

          <Grid container spacing={3}>
            <Grid item xs={4}>
              <DropzoneAreaBase
                dropzoneClass={classes.DropzoneArea}
                acceptedFiles={["image/*"]}
                dropzoneText={"Drag and drop an Image here"}
                showPreviewsInDropzone={true}
                showPreviews={true}
                //dropzoneText={"Drag and drop an Image here"}
                onAdd={(files) => {
                  setCompanyDetails({
                    ...companyDetails,
                    logo: files[0].file,
                    logoData: files[0].data,
                  });
                }}
              />
            </Grid>
            <Grid item xs={6}>
              {companyDetails?.logoData ? (
                <div style={{ width: "300px", marginTop: "10px" }}>
                  <img
                    src={companyDetails?.logoData}
                    width="200px"
                    height="200px"
                  />
                </div>
              ) : null}
            </Grid>
          </Grid>

          <br />

          {userRolesPermission?.some(
            (item) => item.access_control_key === "manage_company"
          ) && (
            <Button
              variant="contained"
              color="primary"
              className={classes.upload}
              onClick={() => {
                if (!loadingLogo && companyDetails?.logo) updateLogo();
              }}
            >
              {loadingLogo ? <CustomButtonCircularProgress /> : "Upload Logo"}
            </Button>
          )}
        </div>

        <Typography
          variant="h6"
          style={{ marginTop: "10px", marginBottom: "20px" }}
        >
          Description
        </Typography>
        <div style={{ width: "70%", marginBottom: 80 }}>
          <Editor
            state={editor}
            setState={setEditor}
            desc={companyData?.about}
          />
        </div>
        <div style={{ width: "70%", marginTop: "20px" }}>
          <Typography variant="body2" style={{ fontWeight: "bold" }}>
            Edit Reason
          </Typography>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            inputProps={{
              style: {
                padding: "16px",
              },
            }}
            multiline
            rows="2"
            InputLabelProps={{
              className: classes.floatingLabelFocusStyle,
            }}
            label={`Why you want to edit this company details?`}
            value={editReason}
            onChange={(e) => {
              setEditReason(e.target.value);
              setEditReasonError("");
            }}
            style={{
              marginBottom: 10,
              backgroundColor: "#fff",
              borderRadius: "8px",
            }}
            error={editReasonError ? true : false}
            helperText={<span>{editReasonError}</span>}
          />
        </div>
      </div>
      {userRolesPermission?.some(
        (item) => item.access_control_key === "manage_company"
      ) && (
        <div style={{ marginTop: "40px,", paddingTop: "40px" }}>
          <Button
            color="primary"
            variant="contained"
            className={classes.btn2}
            onClick={() => {
              if (!loadingPost) {
                updateProfile();
                // history.push();
              }
            }}
          >
            {loadingPost ? (
              <CircularProgress
                className={classes.circularProgress}
                size={14}
              />
            ) : (
              "Update"
            )}
          </Button>

          {/* <Button color="primary" variant="outlined" className={classes.btn2}>
              Cancel
            </Button> */}
        </div>
      )}
    </>
  );
}
