import {
  Box, Grid,


  Switch, Typography,



  withStyles
} from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { sanchalanAxiosGetReq } from "../../api/BaseApi/apiBase";
import {
  getGroupJobsAPI
} from "../../api/JobPost/action";
import {
  collegeDetailSelectorFn, collegeGroupListSelectorFn,
  isCollegeGroupFn, entityInfoSelectorFn
} from "../../api/SignIn/selector";
import { EDGJobsAPI } from "../../api/University/APIs/action";
import UplacementCycle from "../../components/Cards/UniversityGraph/UPlcementCycle";
import CustomCircularProgress from "../../components/CircularProgressBar";
import JobTab from "../../components/Header/JobTab/index1";
import GroupListDropdown from "../../components/Input/DropDownButton/GroupListDropdown";
import checkSelectedGroup from "../../utility/getGroupIdParam";

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 32,
    height: 20.5,
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(12px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 16,
    height: 16,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 18 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

function UniversityManageJobs() {
  const dispatch = useDispatch();
  const alert = useAlert();
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [loading1, setLoading1] = useState(false);
  const collegeDetails = useSelector(collegeDetailSelectorFn);

  const [selectedGroup, setSelectedGroup] = useState(null);

  const collegeGroupList = useSelector(collegeGroupListSelectorFn);
  const entityInfo = useSelector(entityInfoSelectorFn);
  const [newCollegeGroupList, setNewCollegeGroupList] = useState([]);

  useEffect(() => {
    if (selectedGroup)
      dispatch(
        EDGJobsAPI(
          setLoading1,
          alert,
          history,
          location,
          checkSelectedGroup(isCollegeGroup, selectedGroup, collegeGroupList)
        )
      );
  }, [selectedGroup]);

  useEffect(() => {
    dispatch(
      EDGJobsAPI(
        setLoading1,
        alert,
        history,
        location,
        checkSelectedGroup(isCollegeGroup, selectedGroup, collegeGroupList)
      )
    );
  }, []);

  const collegeJobsList = useSelector((state) => state?.EDGCommon?.edgJobsList);

  const isCollegeGroup = useSelector(isCollegeGroupFn);

  useEffect(() => {
    if (collegeGroupList?.length > 0) {
      if (isCollegeGroup) {
        setNewCollegeGroupList(
          [{ id: "All", name: "All Groups" }].concat(collegeGroupList)
        );
        setSelectedGroup({ id: "All", name: "All Groups" });
      } else {
        setNewCollegeGroupList(collegeGroupList);
        setSelectedGroup(collegeGroupList[0]);
      }
    }
  }, [collegeGroupList]);

  //next level

  // const [loading, setLoading] = useState(true);
  const [applicationStatusLoading, setApplicationStatusLoading] =
    useState(true);

  const [selectedRowsData, setSelectedRowsData] = useState([]);
  
  const [callNavbar, setCallNavbar] = useState(false);
  const [filters, setFilters] = useState({
    job_status: "OPEN",
    job_title: null,
    company_name: null,
    job_type: null,
    created: null,
    ctc_min: null,
    ctc_max: null,
    job_stage_id: null,
  });

  const initialBackendDrivenFilter = {
    outerFilters: {
      job_search: null,
      company_search: null,
    },
    innerFilters: {},
  };
  const [backendDrivenFilter, setBackendDrivenFilter] = useState(
    initialBackendDrivenFilter
  );

  const [jobTitleSearch, setJobTitleSearch] = useState(false);
  const [companyNameSearch, setCompanyNameSearch] = useState(false);
  const jobList = useSelector((state) => state?.GruopJobData?.details) || [];
  const [applyClickedCTC, setApplyClickedCTC] = useState(false);

  const [jobTypeClick, setJobTypeClick] = useState(false);
  const [placementSession, setPlacementSession] = useState(null);

  const [firstApiCall, setFirstApiCall] = useState(false);

  const [companyList, setCompanyList] = useState([]);
  const [companyListTotalCount, setCompanyListTotalCount] = useState(0);

  const [companyListTable, setCompanyListTable] = useState([]);

  const initialPageData = { currentPage: 0, pageSize: 20 };
  const [pageData, setPageData] = useState(initialPageData);

  useEffect(() => {
    if (
      companyList.length / pageData.pageSize !== pageData.currentPage + 1 &&
      !loading
    ) {
      setCompanyList((prev) => {
        return [...prev, ...(jobList || [])];
      });
    }
  }, [jobList]);

  const [filterData, setFilterData] = useState([]);
  const [filterLoading, setFilterLoader] = useState(true);

  useEffect(() => {
    const getFilters = async () => {
      setFilterLoader(true);
      let endPoint = `api/manage_job/job-filters/`;
      if (selectedGroup?.id) endPoint += `?group_id=${selectedGroup?.id}`;
      endPoint = endPoint.replace(/^&+/, "");
      endPoint = endPoint.replace(/&+$/, "");
      try {
        const res = await sanchalanAxiosGetReq(endPoint);
        const data = res?.data?.data;
        if (res?.data?.success) {
          setFilterData(data);
        }
        setFilterLoader(false);
      } catch (error) {
        throw error;
        setFilterLoader(false);
      }
    };
    getFilters();
    setBackendDrivenFilter(initialBackendDrivenFilter);
  }, [selectedGroup?.id]);

  useEffect(() => {
    setCompanyList([]);
  }, []);

  const createQueryStringOuterFilter = (paramsObject) => {
    let queryParams = "";
    Object.keys(paramsObject).forEach((key) => {
      const value = paramsObject[key];

      if (value != null && value != undefined && value != "") {
        if (value instanceof Date) {
          queryParams += `${key}=${value.getDate()}/${
            value.getMonth() + 1
          }/${value.getFullYear()}&`;
        } else {
          if (Array.isArray(value)) {
            queryParams += `${key}=${value.join(",")}&`;
          } else {
            queryParams += `${key}=${value}&`;
          }
        }
      }
    });
    return queryParams;
  };

  const formatDate = (date) => {
    if (date instanceof Date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-based, so add 1
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    }
    return "";
  };

  const createQueryStringInnerFilter = (paramsObject) => {
    let queryParams = "";
    Object.keys(paramsObject).forEach((key) => {
      const items = paramsObject[key];
      if (items) {
        const validItems = items.filter(
          (item) =>
            item.value != null &&
            item.value !== undefined &&
            item.value !== "" &&
            !(Array.isArray(item.value) && item.value.length === 0) &&
            !(
              typeof item.value === "object" &&
              !Array.isArray(item.value) &&
              !(item.value instanceof Date) &&
              Object.keys(item.value).length === 0
            )
        );
        if (validItems.length) {
          if (key == "ctc") {
            const { min, max } = validItems[0]?.value;

            if (min && max) {
              queryParams += `ctc_min=${Number(min) * 100000}&ctc_max=${
                Number(max) * 100000
              }`;
            } else if (min) {
              queryParams += `ctc_min=${Number(min) * 100000}`;
            } else if (max) {
              queryParams += `ctc_max=${Number(max) * 100000}`;
            }
          } else {
            let values = validItems
              .map((item) => {
                if (item.value instanceof Date) {
                  return formatDate(item.value);
                } else if (Array.isArray(item.value)) {
                  return item.value.join(",");
                } else {
                  return item.value;
                }
              })
              .join(",");

            if (values) {
              queryParams += `${key}=` + values;
            }
          }

          queryParams += "&";
        }
      }
    });

    queryParams = queryParams.slice(0, -1);

    return queryParams;
  };

  useEffect(() => {
    if (firstCallDone && selectedGroup?.id) {
      setPageData(initialPageData);
      setCompanyList([]);
    }
  }, [backendDrivenFilter.innerFilters]);

  useEffect(() => {
    if (
      firstCallDone &&
      selectedGroup?.id &&
      (jobTitleSearch || companyNameSearch)
    ) {
      setPageData(initialPageData);

      setCompanyList([]);
    }
  }, [jobTitleSearch, companyNameSearch]);

  const [firstCallDone, setFirstCallDone] = useState(false);
  useEffect(() => {
    let CancelToken = axios.CancelToken;
    let source = CancelToken.source();
    let CancelToken2 = axios.CancelToken;
    let source2 = CancelToken2.source();

    if (
      selectedGroup?.id &&
      companyList.length / pageData.pageSize === pageData.currentPage
    ) {
      let params = `${createQueryStringOuterFilter(
        backendDrivenFilter?.outerFilters
      )}${createQueryStringInnerFilter(backendDrivenFilter?.innerFilters)}`;
      params = params.replace(/^&+/, "");
      params = params.replace(/&+$/, "");

      let url = `api/manage_job/job-info/?${checkSelectedGroup(
        isCollegeGroup,
        selectedGroup,
        collegeGroupList
      )}&page=${pageData.currentPage + 1}&page_size=${
        pageData.pageSize
      }&${params}`;
      url = url.replace(/^&+/, "");
      url = url.replace(/&+$/, "");

      dispatch(
        getGroupJobsAPI(
          setLoading,
          alert,
          history,
          location,
          url,
          setJobTitleSearch,
          setCompanyNameSearch,
          true,
          setApplyClickedCTC,
          setFirstApiCall,
          setCompanyList,
          source,
          setCompanyListTotalCount
        )
      )
        .then(() => {
          setFirstCallDone(true);
        })
        .catch((error) => {
          console.error("Fetch data failed:", error);
        });

      if (!companyList.length) {
        let url_navbar = `api/manage_job/job_navbar/?current_tab=${
          filters?.job_status
        }${checkSelectedGroup(
          isCollegeGroup,
          selectedGroup,
          collegeGroupList
        )}&${params}`;
        url_navbar = url_navbar.replace(/&$/, "");
      }
    }
    return () => {
      source.cancel("Cancelled due to stale request");
      source2.cancel("Cancelled due to stale request");
    };
  }, [pageData, selectedGroup?.id]);

  const [toggleViewCollege, setToggleViewCollege] = useState(false);
  const handleToggle = () => {
    setToggleViewCollege((prev) => !prev);
  };

  return (
    <>
      {collegeGroupList?.length > 0 && (
        <div style={{ marginTop: "5px", marginBottom: "5px" }}>
          <GroupListDropdown
            title="Group"
            state={selectedGroup}
            setState={setSelectedGroup}
            groupList={newCollegeGroupList}
          />
        </div>
      )}
      {loading1 ? (
        <CustomCircularProgress />
      ) : (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h2">Manage Jobs</Typography>{" "}
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Typography
                color={toggleViewCollege ? "primary" : "#6C757D"}
                style={{ fontSize: 16, marginRight: 5 }}
              >
                Show {entityInfo?.college}s
              </Typography>
              <AntSwitch
                checked={toggleViewCollege}
                onChange={handleToggle}
                name="checkedC"
              />
            </Box>
          </div>
          <div style={{ marginTop: "20px" }}>
            {toggleViewCollege ? (
              <Grid container spacing={5}>
                {collegeJobsList?.length > 0 &&
                  collegeJobsList?.map((item) => (
                    <Grid item xs={4}>
                      <UplacementCycle
                        item={item}
                        countTitle={"Open Jobs"}
                        count={item?.open_jobs}
                        link={`/manage-jobs`}
                        linkTitle={"View All Jobs"}
                        from={"Jobs"}
                        secondaryCountTitle={"e-JNF Response"}
                        secondaryCount={item?.ejd_count}
                      />
                    </Grid>
                  ))}
              </Grid>
            ) : (
              <JobTab
                loading={loading}
                setSelectedRowsData={setSelectedRowsData}
                filters={filters}
                setFilters={setFilters}
                selectedRowsData={selectedRowsData}
                jobTitleSearch={jobTitleSearch}
                companyNameSearch={companyNameSearch}
                setJobTitleSearch={setJobTitleSearch}
                setCompanyNameSearch={setCompanyNameSearch}
                setApplyClickedCTC={setApplyClickedCTC}
                setJobTypeClick={setJobTypeClick}
                jobTypeClick={jobTypeClick}
                jobNavbar={[]}
                placementSession={placementSession}
                teamMemberList={[]}
                collegeId={collegeDetails?.id}
                userRolesPermission={null}
                setCallNavbar={setCallNavbar}
                applyClickedCTC={applyClickedCTC}
                companyList={companyList}
                setCompanyList={setCompanyList}
                selectedColumnFields={[]}
                setSelectedColumnFields={() => {}}
                backendDrivenFilter={backendDrivenFilter}
                setBackendDrivenFilter={setBackendDrivenFilter}
                filterData={filterData}
                filterLoading={filterLoading}
                applicationStatusLoading={applicationStatusLoading}
                setApplicationStatusLoading={setApplicationStatusLoading}
                companyListTable={companyListTable}
                setCompanyListTable={setCompanyListTable}
                companyListTotalCount={companyListTotalCount}
                pageData={pageData}
                setPageData={setPageData}
                handleToggle={handleToggle}
                toggleViewCollege={toggleViewCollege}
                setToggleViewCollege={setToggleViewCollege}
              />
            )}
          </div>
        </>
      )}
      {/* </UniversityLayout> */}
    </>
  );
}

export default UniversityManageJobs;
