import {
  AppBar,
  Avatar,
  Button,
  Container,
  CssBaseline,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  Grid,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Help } from "@material-ui/icons";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import MenuIcon from "@material-ui/icons/Menu";
import Phone from "@material-ui/icons/Phone";
import PostAddIcon from "@material-ui/icons/PostAdd";
import SettingsIcon from "@material-ui/icons/Settings";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { IconContext } from "react-icons";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { LogoutAPICall } from "../../api/Domain/action";
import { userLoggedInSelectorFn } from "../../api/Domain/selector";
import { userDetailsApi, USER_DETAILS } from "../../api/SignIn/action";
import {
  collegeDetailSelectorFn,
  collegeListSelectorFn,
  collegeUserDetailSelectorFn,
  isCollegeGroupFn,
  isEducationGroupFn,
  sidebarSelectorFn,
} from "../../api/SignIn/selector";
import { CallCollegeAPI, EDGAllAPI } from "../../api/University/APIs/action";
import { allCollegeSelectedSelectorFn } from "../../api/University/APIs/selector";
import logo from "../../assets/png/getwork-logo.png";
import CollegeGroupSwitch from "../Common/CollegeGroupSwitch/CollegeGroupSwitch";
import "../Common/Sidebar/Navbar.css";
import UniversitySubMenu from "../Common/Sidebar/UniversitySubMenu";
import CollegeGroupDropDown from "../Input/DropDownButton/CollegGroupDropdown";
import Search from "../Input/Search";
import { groupViewSelectorFn } from "../../api/Common/selector";
import { GroupViewCheck } from "../../api/Common/action";
import CollegeListDialog from "../DialogSection/CollegeList";
import TopBarLoader from "../Common/TopBarLoader/TopBarLoader";

const drawerWidth = 220;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    // paddingRight: 24,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "100%",
    //padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    backgroundColor: "#fff",
    boxShadow: "none",
    borderBottom: ".5px solid #b0b6ba",
    // borderBottom: "1px solid rgba(0 0 0 0.12)",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
    color: "#000",
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flexWrap: "wrap",
    borderRight: "1px solid #b0b6ba",
    alignItems: "center",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(0),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(0),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    backgroundColor: "#F5F7F8",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: "auto",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    borderRadius: "8px",
    //boxShadow: "none",
  },
  btn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    marginTop: "10px",
  },
  std: {
    marginLeft: "20px",
    boxShadow: "none",
    borderRadius: "8px",
    display: "flex",
    height: "40px",
  },
  postJob: {
    backgroundColor: theme.palette.primary.secondary,
    borderRadius: "8px",
    width: "80%",
    display: "flex",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: theme.palette.primary.secondary,
    },
  },
}));

const StyledMenu = withStyles({
  paper: {
    //border: ".5px solid #d3d4d5",
    borderRadius: "8px",
    boxShadow: "rgb(0 0 0 / 10%) 0px 8px 24px",
    marginTop: "20px",
    display: "flex",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      //backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.black,
      },
    },
  },
}))(MenuItem);

export default function CollegeGroupLayout({ children }) {
  const [sidebar, setSidebar] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();
  const alert = useAlert();
  const location = useLocation();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const isEducationGroup = useSelector(isEducationGroupFn);
  const isCollegeGroup = useSelector(isCollegeGroupFn);
  const groupView = useSelector(groupViewSelectorFn);

  useEffect(() => {
    dispatch(userDetailsApi(history, location, alert));
  }, []);
  useEffect(() => {
    dispatch(EDGAllAPI(setLoading, alert, history, location));
  }, []);
  const [open, setOpen] = React.useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const collegeDetails = useSelector(collegeDetailSelectorFn);

  const collegeUserDetail = useSelector(collegeUserDetailSelectorFn);
  const sidebarDataNew = useSelector(sidebarSelectorFn);
  const collegeList = useSelector(collegeListSelectorFn);

  const checkToDisplay = () => {
    switch (location.pathname) {
      case "/college-group/profile-settings":
        return false;
      case "/college-group/account-settings":
        return false;
      case "/college-group/ejd-link":
        return false;
      case "/college-group/student-link":
        return false;
      case "/college-group/company-link":
        return false;
      case "/college-group/team-link":
        return false;
      case "/create-interview":
        return false;
      case "/create-assignment":
        return false;
      case "/college-group/degree-graph":
          return false;

      default:
        return true;
    }
  };

  const checkToDisplayNew = () => {
    var boole = true;
    if (location.pathname.includes("company-profile")) boole = false;
    if (location?.pathname.includes("/job/")) boole = false;
    if (location.pathname.includes("student-profile")) boole = false;
    if (location.pathname.includes("team-profile")) boole = false;
    if (location.pathname.includes("track")) boole = false;
    if (location.pathname.includes("help")) boole = false;
    if (location.pathname.includes("questions")) boole = false;
    if (location.pathname.includes("group_FAQs")) boole = false;
    if (location.pathname.includes("/edit-student/")) boole = false;

    return boole;
  };
  const isLoggedIn = useSelector(userLoggedInSelectorFn);

  // useEffect(() => {
  //   if (isLoggedIn) {
  //     if (isCollegeGroup && !location.pathname.includes("college-group")) history.push("/college-group/dashboard");
  //     if (location.pathname.includes("college-group")) history.push(location.pathname);
  //     else history.push("/overview");
  //   }
  // }, []);
  const user_details = useSelector((state) => state?.UserDetails?.user_details);

  useEffect(() => {
    if (location.pathname.includes("college-group")) {
      var obj = {
        ...user_details,
        college_details: { ...user_details?.college_details, college_id: null },
      };
      dispatch({
        type: USER_DETAILS,
        payload: obj,
      });
    }
  }, [location]);

  const [switchToggled, setSwitchToggled] = useState(false);
  const allCollegeSelected = useSelector(allCollegeSelectedSelectorFn);

  useEffect(() => {
    if (allCollegeSelected && (isEducationGroup || isCollegeGroup)) {
      if (isEducationGroup && location.pathname.includes("college-group"))
        history.push("/dashboard");
      else {
        if (location.pathname.includes("overview")) history.push("/dashboard");
        else {
          if (location.pathname.includes("college-profile")) {
            history.push("/college-group/all-colleges");
          } else if (location.pathname.includes("interview")) {
            history.push("/college-group/interview");
          } else if (!location.pathname.includes("/college-group/")) {
            history.push("/college-group/" + location.pathname.split("/")[1]);
          }
        }
      }
    } else {
      if (isEducationGroup && location.pathname.includes("college-group"))
        history.push("/dashboard");
    }
  }, [allCollegeSelected, isEducationGroup]);

  const setfunc = () => {};
  const setfunc2 = () => {};

  const [opennew, setOpennew] = React.useState(false);

  const handleClickOpennew = () => {
    // dispatch(GroupViewCheck(true));
    setOpennew(true);
  };

  const handleClosenew = () => {
    // dispatch(GroupViewCheck(true));
    setOpennew(false);
  };

  const handleCloseOutSide = () => {
    dispatch(GroupViewCheck(false));
    setOpennew(false);
  };

  const ToggleSwitch = () => {
    if (groupView) {
      setSwitchToggled(false);
      dispatch(
        CallCollegeAPI(
          null,
          alert,
          history,
          location,
          user_details,
          location.pathname,
          true,
          setfunc,
          setfunc2,
          handleButtonClick
        )
      )
        .then(() => {
          dispatch(userDetailsApi(history, location, alert));
        })
        .catch((err) => {
          console.log("Error: ", err);
        });
      dispatch(GroupViewCheck(false));
    } else {
      handleClickOpennew();
    }
  };

  useEffect(() => {
    if (location.pathname.includes("college-group")) {
      if (groupView) {
        setSwitchToggled(false);
        dispatch(
          CallCollegeAPI(
            null,
            alert,
            history,
            location,
            user_details,
            location.pathname,
            true
          )
        )
          .then(() => {
            dispatch(userDetailsApi(history, location, alert));
          })
          .catch((err) => {
            console.log("Error: ", err);
          });
        dispatch(GroupViewCheck(false));
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    if (groupView) {
      handleButtonClick();
      setSwitchToggled(true);
    } else setSwitchToggled(false);
  }, [groupView]);

  const [proloading, setProLoading] = React.useState(false);

  const timer = React.useRef();

  const handleButtonClick = () => {
    if (!proloading) {
      setProLoading(true);
      timer.current = window.setTimeout(() => {
        setProLoading(false);
      }, 2000);
    }
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar)}
        //className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        {proloading && <TopBarLoader />}
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon color="primary" />
          </IconButton>

          <Grid
            container
            spacing={3}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Grid
              item
              xs={6}
              sm={5}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingLeft: "40px",

                  width: 150,
                  backgroundColor: "#fff",
                  height: 50,
                }}
              >
                <img
                  src={collegeDetails?.logo}
                  alt="logo"
                  style={{
                    margin: "auto",
                    maxWidth: "100%",
                    maxHeight: "100%",
                  }}
                  // className={classes.collegelogo}
                />
              </div>

              <Hidden smDown>
                <Search placeholder="Search by Name..." from={"university"} />
              </Hidden>
            </Grid>

            <Grid
              item
              xs={6}
              sm={2}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <div>
                <CollegeGroupSwitch
                  ToggleSwitch={ToggleSwitch}
                  switchToggled={groupView}
                  disable={!(checkToDisplayNew() && checkToDisplay())}
                  value={switchToggled}
                />
              </div>
            </Grid>
            <Grid
              item
              xs={6}
              sm={3}
              style={{ display: "flex", justifyContent: "flex-start" }}
            >
              {groupView && (
                <div style={{ display: "grid", width: "300px" }}>
                  {/* <SendEjd /> */}

                  <CollegeGroupDropDown
                    name="All Colleges"
                    collegeList={collegeList}
                    disabled={!(checkToDisplayNew() && checkToDisplay())}
                  />
                </div>
              )}
              <CollegeListDialog
                open={opennew}
                handleClickOpen={handleClickOpennew}
                handleClose={handleClosenew}
                name="All Colleges"
                collegeList={collegeList}
                handleCloseOutSide={handleCloseOutSide}
              />
            </Grid>
            <Grid
              item
              xs={6}
              sm={2}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="h6"
                  style={{ color: "#000", fontSize: "15px" }}
                >
                  {collegeUserDetail?.name}
                </Typography>

                <div>
                  <Avatar
                    alt="user"
                    src={
                      collegeUserDetail?.profile_image &&
                      collegeUserDetail?.profile_image
                    }
                    onClick={handleClick}
                    style={{ marginLeft: "15px", cursor: "pointer" }}
                  />
                  <StyledMenu
                    id="customized-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <Link
                      to="/college-group/help"
                      style={{ textDecoration: "none" }}
                    >
                      <StyledMenuItem>
                        <ListItemIcon
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Help fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Help" />
                      </StyledMenuItem>
                    </Link>
                    <Link
                      to="/college-group/account-settings"
                      style={{ textDecoration: "none" }}
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      <StyledMenuItem>
                        <ListItemIcon
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <SettingsIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Account Settings" />
                      </StyledMenuItem>
                    </Link>
                    <Link
                      to="/college-group/contact-us"
                      style={{ textDecoration: "none" }}
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      <StyledMenuItem>
                        <ListItemIcon
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Phone fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Contact Us" />
                      </StyledMenuItem>
                    </Link>
                    <StyledMenuItem
                      onClick={() => {
                        dispatch(LogoutAPICall(history, alert));
                      }}
                    >
                      <ListItemIcon
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <ExitToAppIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Logout" />
                    </StyledMenuItem>
                  </StyledMenu>
                </div>
              </div>
            </Grid>
          </Grid>

          {/* <div style={{ display: "grid", width: "300px" }}> */}
          {/* <SendEjd /> */}
        </Toolbar>
      </AppBar>
      {/* <Sidebar /> */}
      <Hidden smUp>
        <Drawer
          variant="temporary"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open={open}
        >
          <div className={classes.toolbarIcon}>
            <img src={collegeDetails?.logo} height="40px" width="70%" />
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <Link
              to="/college-group/post-job"
              style={{ textDecoration: "none", display: "contents" }}
            >
              <Button
                variant="contained"
                color="primary"
                className={classes.postJob}
                startIcon={<PostAddIcon />}
              >
                Post Job
              </Button>
            </Link>
          </div>
          <div style={{ height: "70vh" }}>
            <IconContext.Provider>
              {sidebarDataNew &&
                sidebarDataNew.map((item, index) => {
                  return <UniversitySubMenu items={item} key={index} />;
                })}
            </IconContext.Provider>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography variant="overline">Powered by</Typography>
              <img src={logo} height="17px" width="70px" />
            </div>
          </div>
          <Divider />
        </Drawer>
      </Hidden>

      <Hidden smDown>
        {/* <Drawer
            variant="permanent"
            classes={{
              paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
            }}
            open={open}
          > */}
        <div className="nav" id="navbar">
          <nav className="nav__container">
            {/* <div className={classes.toolbarIcon}>
                <img src={collegeDetails?.logo} height="40px" width="70%" />
                <IconButton onClick={handleDrawerClose}>
                  <ChevronLeftIcon />
                </IconButton>
              </div> */}
            <div
              className={classes.sidepanel}
              style={{ marginTop: "86px" }}
              // onMouseOver={handleDrawerOpen}
            >
              <Link
                to="/college-group/post-job"
                style={{ textDecoration: "none", display: "contents" }}
              >
                <div
                  style={{
                    padding: "10px",
                    display: "flex",
                    alignItems: "center",
                    padding: "8px",
                    marginBottom: "20px",
                    height: "45px",
                    marginLeft: "6px",

                    cursor: "pointer",
                  }}
                  variant="contained"
                  color="primary"
                  className={classes.postJob}
                >
                  <PostAddIcon
                    style={{
                      color: "white",
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "3px",
                    }}
                  />
                  <Typography
                    variant="body2"
                    style={{
                      margin: "auto",
                      marginLeft: "25px",
                      color: "white",
                    }}
                  >
                    {" "}
                    Post Job{" "}
                  </Typography>
                </div>
              </Link>
              {/* <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  <Link
                    to="/college-group/post-job"
                    style={{ textDecoration: "none", display: "contents" }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.postJob}
                      startIcon={<PostAddIcon />}
                    >
                      Post Job
                    </Button>
                  </Link>
                </div> */}
              <div style={{ height: "70vh" }}>
                <IconContext.Provider value={{ color: "#6c757d" }}>
                  {sidebarDataNew &&
                    sidebarDataNew.map((item, index) => {
                      return <UniversitySubMenu items={item} key={index} />;
                    })}
                </IconContext.Provider>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  {/* <Typography variant="overline">Powered by</Typography> */}
                  <img src={logo} height="auto" width="50px" />
                </div>
              </div>
            </div>
            {/* <Divider /> */}
            {/* </Drawer> */}
          </nav>
        </div>
      </Hidden>
      <main className={classes.content}>
        <div className={classes.appBarSpacer}>
          <Container maxWidth="xl" className={classes.container}>
            {children}
          </Container>
        </div>
      </main>
    </div>
  );
}
