import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Typography,
  makeStyles,
  InputAdornment,
  MenuItem,
  InputLabel,
  Select,
  FormControl,
  withStyles,
  InputBase,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import SearchIcon from "@material-ui/icons/Search";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import BlockIcon from "@material-ui/icons/Block";
import axios from "axios";
import { sanchalanAxiosGetReq } from "../../../api/BaseApi/apiBase";
import { useSelector } from "react-redux";
import { collegeDetailsSelectorFn } from "../../../api/Domain/selector";
import { useAlert } from "react-alert";
import { Skeleton } from "@material-ui/lab";
import { Link, useHistory, useLocation } from "react-router-dom";
import DownloadSvg from "../../../assets/svg/downloadReport.svg";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
}));

function CampusReportActionbar({
  selectedPlacement,
  setSelectedPlacement,
  collegeId,
  loadingReportCard,
}) {
  const classes = useStyles();
  const location = useHistory();
  const [status, setStatus] = React.useState(1);
  const [placementCycles, setPlacementCycles] = useState([]);
  const collegeDetail = useSelector(collegeDetailsSelectorFn);
  // const [selectedValue,setSelectedValue] = useState(null);
  const alert = useAlert();
  const [active, setActive] = useState(true);
  const [loading, setLoading] = useState(false);
  const handleChange = (event) => {
    setStatus(event.target.value);
    if (event.target.value === 1) setActive(true);
    else setActive(false);
  };
  const checkActive = () => {
    if (active) return "&is_ended=true";
    else return "&is_ended=false";
  };

  const handleChangePlacement = (event, newValue) => {
    if (newValue) setSelectedPlacement(newValue);
  };

  useEffect(() => {
    if (collegeId) {
      setLoading(true);
      sanchalanAxiosGetReq(
        `api/placement_cycles/placements/?single_placement=true&college_param=${collegeId}${checkActive()}`
      )
        .then((res) => {
          if (res?.data?.success) {
            setPlacementCycles(res?.data?.data);
            setSelectedPlacement(res?.data?.data[0]);
            setLoading(false);
          }
        })
        .catch((err) => {
          alert.error(err?.response?.data?.error);
          setLoading(false);
        });
    }
  }, [active, collegeId]);

  const BootstrapInput = withStyles((theme) => ({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #ced4da",
      fontSize: 14,
      padding: "10px 26px 10px 12px",
      display: "flex",
      alignItems: "center",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      color: status === 1 ? "#029E73" : status === 2 ? "#CD393E" : "",
      "&:focus": {
        borderRadius: 4,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
  }))(InputBase);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {loading || loadingReportCard ? (
        <Skeleton variant="rectangular" width={500} height={40} />
      ) : (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div>
              <Autocomplete
                value={selectedPlacement ?? {}}
                id="combo-box-demo"
                options={placementCycles ?? []}
                onChange={handleChangePlacement}
                getOptionLabel={(option) => option.name}
                style={{ width: 350 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </div>
            <div style={{ marginLeft: 10 }}>
              <FormControl className={classes.margin}>
                <Select
                  labelId="demo-customized-select-label"
                  id="demo-customized-select"
                  value={status}
                  onChange={handleChange}
                  input={<BootstrapInput />}
                >
                  <MenuItem value={1}>
                    <FiberManualRecordIcon
                      style={{ fontSize: 14, marginRight: 5 }}
                    />
                    Active
                  </MenuItem>
                  <MenuItem value={2}>
                    {" "}
                    <BlockIcon style={{ fontSize: 14, marginRight: 5 }} />{" "}
                    InActive
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div>
            <Button
              variant="outlined"
              style={{
                backgroundColor: "#2E87AD",
                height: 40,
                position: "relative",
              }}
              onClick={() => location.push("download-report")}
            >
              <div style={{ position: "absolute", top: "-12px" }}>
                <img src={DownloadSvg} />
              </div>
              <span style={{ color: "#fff", paddingLeft: 58 }}>
                Download Reports
              </span>
            </Button>
          </div>
        </>
      )}
    </div>
  );
}

export default CampusReportActionbar;
