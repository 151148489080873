import {
  Checkbox,
  CircularProgress,
  ClickAwayListener,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import { makeStyles } from "@material-ui/core/styles";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "../Accordian/CustomAccordian";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import SearchRoundedIcon from "@material-ui/icons/SearchRounded";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  typography: {
    padding: theme.spacing(2),
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  checkedIcon: {
    color: theme.palette.primary.main,
    fontSize: 14,
  },

  expansionPanelSummaryContent: {
    margin: 0,
  },
}));

export default function DegreeSpecializationSingleCollege({
  setCoursesArray,
  coursesArray,
  errorMessage,
  loading,
  disabled = false,
  allCollegeCoursesSet,
  setallCollegeCoursesSet,
}) {
  const [open, setOpen] = React.useState(false);
  useEffect(() => console.log("open", open), [open]);
  const classes = useStyles();
  const [displayArray, setDisplayArray] = useState([]);
  useEffect(() => console.log("displayArray", displayArray), [displayArray]);

  const [search, setSearch] = useState("");
  useEffect(() => console.log("search", search), [search]);
  const anchorRef = useRef(null);

  const handleClickAndOnChange = (event) => {
    console.log("trigger 1", event.target.value, event.currentTarget);

    if (search) setOpen(true);

    setSearch(event.target.value);

    var courses = [...coursesArray];
    var arr = [];
    courses.map((items) => {
      new_arr = items.degrees.map((element) => {
        return {
          ...element,
          specialization: element.specialization.filter((subElement) =>
            subElement.specialization_name
              .toLowerCase()
              .startsWith(event.target.value.toLowerCase())
          ),
        };
      });

      if (new_arr.every((item) => item.specialization.length > 0)) {
        new_arr.map((element) => {
          if (element.specialization.length > 0)
            return {
              ...element,
              specialization: element.specialization.filter((subElement) =>
                subElement.specialization_name
                  .toLowerCase()
                  .startsWith(event.target.value.toLowerCase())
              ),
            };
        });
      }

      if (new_arr.every((item) => item.specialization.length == 0)) {
        var new_arr = items.degrees.filter((item) =>
          item.degree_name
            .toLowerCase()
            .startsWith(event.target.value.toLowerCase())
        );
      }

      var nnn = new_arr.filter((item) => item.specialization.length > 0);
      new_arr = nnn;

      var obj = { ...items };
      obj["degrees"] = new_arr;

      arr.push(obj);
    });

    setDisplayArray(arr);
  };

  const handleClickWithSearchEmpty = (event) => {
    console.log("trigger 2", event.target.value);

    setOpen((prev) => !prev);

    setDisplayArray(coursesArray);
  };

  const icon = <CheckBoxOutlineBlankIcon style={{ fontSize: 14 }} />;
  const checkedIcon = <CheckBoxIcon className={classes.checkedIcon} />;

  const handleSelectAllSpecializations = (e, item) => {
    const isSelectAll = e.target.checked;

    const updatedDisplayArray = displayArray.map((education) => {
      if (education.education_id == item.education_id) {
        return {
          ...education,
          degrees: education.degrees.map((degree) => {
            if (degree.degree_id == item.degree_id) {
              const updatedSpecializations = degree.specialization.map(
                (spec) => ({
                  ...spec,
                  selected: isSelectAll,
                })
              );

              return {
                ...degree,
                select_all: isSelectAll,
                specialization: updatedSpecializations,
              };
            }
            return degree;
          }),
        };
      }
      return education;
    });

    setDisplayArray(updatedDisplayArray);

    const updatedCoursesArray = coursesArray.map((education) => {
      if (education.education_id == item.education_id) {
        return {
          ...education,
          degrees: education.degrees.map((degree) => {
            if (degree.degree_id == item.degree_id) {
              const updatedSpecializations = degree.specialization.map(
                (spec) => ({
                  ...spec,
                  selected: isSelectAll,
                })
              );

              const isAllSelected = updatedSpecializations.every(
                (spec) => spec.selected
              );

              return {
                ...degree,
                select_all: isSelectAll,
                specialization: updatedSpecializations,
              };
            }
            return degree;
          }),
        };
      }
      return education;
    });

    setCoursesArray(updatedCoursesArray);

    const updatedAllCollegeCoursesSet = Object.keys(
      allCollegeCoursesSet
    ).reduce((acc, collegeId) => {
      acc[collegeId] = allCollegeCoursesSet[collegeId].map((education) => {
        return {
          ...education,
          degrees: education.degrees.map((degree) => {
            if (degree.degree_id == item.degree_id) {
              const updatedSpecializations = degree.specialization.map(
                (spec) => ({
                  ...spec,
                  selected: isSelectAll,
                })
              );

              const isAllSelected = updatedSpecializations.every(
                (spec) => spec.selected
              );

              return {
                ...degree,
                select_all: isSelectAll,
                specialization: updatedSpecializations,
              };
            }
            return degree;
          }),
        };
      });
      return acc;
    }, {});

    setallCollegeCoursesSet(updatedAllCollegeCoursesSet);

    console.log(
      "handleSelectAllSpecializations",
      updatedCoursesArray,
      updatedDisplayArray,
      updatedAllCollegeCoursesSet
    );
  };

  const handleSelectSingleSpecialization = (e, item) => {
    const isSelected = e.target.checked;

    const updatedDisplayArray = displayArray.map((education) => {
      if (education.education_id == item.education_id) {
        return {
          ...education,
          degrees: education.degrees.map((degree) => {
            if (degree.degree_id == item.degree_id) {
              const updatedSpecializations = degree.specialization.map(
                (spec) => {
                  if (spec.specialization_id == item.specialization_id) {
                    return { ...spec, selected: isSelected };
                  }
                  return spec;
                }
              );

              const courseDegree = coursesArray
                .find((edu) => edu.education_id == item.education_id)
                ?.degrees.find((deg) => deg.degree_id == item.degree_id);

              const areAllCourseSpecializationsSelected =
                courseDegree.specialization.every((courseSpec) => {
                  const matchingDisplaySpec = updatedSpecializations.find(
                    (displaySpec) =>
                      displaySpec.specialization_id ==
                      courseSpec.specialization_id
                  );

                  if (matchingDisplaySpec) {
                    return matchingDisplaySpec.selected;
                  }

                  return courseSpec.selected;
                });

              return {
                ...degree,
                select_all: areAllCourseSpecializationsSelected,
                specialization: updatedSpecializations,
              };
            }
            return degree;
          }),
        };
      }
      return education;
    });

    setDisplayArray(updatedDisplayArray);

    const updatedCoursesArray = coursesArray.map((education) => {
      if (education.education_id == item.education_id) {
        return {
          ...education,
          degrees: education.degrees.map((degree) => {
            if (degree.degree_id == item.degree_id) {
              const updatedSpecializations = degree.specialization.map(
                (spec) => {
                  if (spec.specialization_id == item.specialization_id) {
                    return { ...spec, selected: isSelected };
                  }
                  return spec;
                }
              );

              const isAllSelected = updatedSpecializations.every(
                (spec) => spec.selected
              );

              return {
                ...degree,
                select_all: isAllSelected,
                specialization: updatedSpecializations,
              };
            }
            return degree;
          }),
        };
      }
      return education;
    });

    setCoursesArray(updatedCoursesArray);

    const updatedAllCollegeCoursesSet = Object.keys(
      allCollegeCoursesSet
    ).reduce((acc, collegeId) => {
      acc[collegeId] = allCollegeCoursesSet[collegeId].map((education) => {
        return {
          ...education,
          degrees: education.degrees.map((degree) => {
            if (degree.degree_id == item.degree_id) {
              const updatedSpecializations = degree.specialization.map(
                (spec) => {
                  if (spec.specialization_id == item.specialization_id) {
                    return { ...spec, selected: isSelected };
                  }
                  return spec;
                }
              );

              const isAllSelected = updatedSpecializations.every(
                (spec) => spec.selected
              );

              return {
                ...degree,
                select_all: isAllSelected,
                specialization: updatedSpecializations,
              };
            }
            return degree;
          }),
        };
      });
      return acc;
    }, {});

    setallCollegeCoursesSet(updatedAllCollegeCoursesSet);

    console.log(
      "handleSelectSingleSpecializations",
      updatedCoursesArray,
      updatedDisplayArray,
      updatedAllCollegeCoursesSet
    );
  };

  const [expandedIndex, setExpandedIndex] = useState(null);

  return (
    <>
      <ClickAwayListener
        onClickAway={() => {
          setOpen(false);
        }}
      >
        <div className={classes.root}>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            placement={"bottom-start"}
            transition
            style={{ top: 6, zIndex: 10000 }}
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={10}>
                <Paper
                  style={{
                    width: 785,
                    borderRadius: 8,
                    maxHeight: 255,
                    overflowY: "scroll",
                  }}
                >
                  {!loading ? (
                    displayArray.map((items, parentIndex) =>
                      items?.degrees?.length ? (
                        <div key={parentIndex}>
                          <FormLabel
                            component="legend"
                            style={{
                              fontSize: 14,
                              color: "grey",
                              marginBottom: 5,
                              marginLeft: 20,
                              marginTop: 10,
                            }}
                          >
                            {items.education_name}
                          </FormLabel>
                          {items.degrees.map((item, childIndex) => {
                            const uniqueIndex = `${parentIndex}-${childIndex}`;
                            return (
                              <Accordion
                                key={childIndex}
                                expanded={
                                  expandedIndex == uniqueIndex || search !== ""
                                }
                                onChange={() =>
                                  setExpandedIndex(
                                    expandedIndex == uniqueIndex
                                      ? null
                                      : uniqueIndex
                                  )
                                }
                              >
                                <AccordionSummary
                                  expandIcon={<ArrowDropDownIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                  classes={{
                                    content:
                                      classes.expansionPanelSummaryContent,
                                    expanded: "expanded",
                                  }}
                                >
                                  <FormGroup>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          icon={icon}
                                          checkedIcon={checkedIcon}
                                          checked={
                                            item.select_all ? true : false
                                          }
                                          onChange={(e) => {
                                            handleSelectAllSpecializations(e, {
                                              ...item,
                                              education_id: items?.education_id,
                                            });
                                          }}
                                          name="gilad"
                                          disabled={search != ""}
                                        />
                                      }
                                      label={
                                        <span style={{ fontSize: 13 }}>
                                          {item.degree_name + " "}Courses
                                        </span>
                                      }
                                    />
                                  </FormGroup>{" "}
                                </AccordionSummary>

                                <AccordionDetails>
                                  <FormControl
                                    component="fieldset"
                                    className={classes.formControl}
                                  >
                                    <FormLabel
                                      component="legend"
                                      style={{
                                        fontSize: 14,
                                        color: "grey",
                                        marginBottom: 5,
                                      }}
                                    >
                                      Specialization
                                    </FormLabel>
                                    <FormGroup>
                                      {item.specialization.map((i, index) => {
                                        console.log(item.degree_name, i);
                                        return (
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                checked={
                                                  i?.selected ? true : false
                                                }
                                                onChange={(e) => {
                                                  handleSelectSingleSpecialization(
                                                    e,
                                                    {
                                                      ...i,
                                                      education_id:
                                                        items?.education_id,
                                                    },
                                                    item
                                                  );
                                                }}
                                                name="gilad"
                                              />
                                            }
                                            label={
                                              <span style={{ fontSize: 13 }}>
                                                {i.specialization_name}
                                              </span>
                                            }
                                          />
                                        );
                                      })}
                                    </FormGroup>
                                  </FormControl>
                                </AccordionDetails>
                              </Accordion>
                            );
                          })}
                        </div>
                      ) : null
                    )
                  ) : (
                    <CircularProgress size={17} style={{ color: "black" }} />
                  )}
                </Paper>
              </Fade>
            )}
          </Popper>

          <TextField
            disabled={disabled}
            variant="outlined"
            style={{ width: "100%" }}
            fullWidth
            className={clsx(classes.margin, classes.textField)}
            InputLabelProps={{
              className: classes.floatingLabelFocusStyle,
            }}
            inputProps={{ style: { padding: "16px" } }}
            ref={anchorRef}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchRoundedIcon />
                </InputAdornment>
              ),
            }}
            onClick={
              search ? handleClickAndOnChange : handleClickWithSearchEmpty
            }
            placeholder="Search Degree & Specialization"
            onChange={handleClickAndOnChange}
            error={errorMessage ? true : false}
            helperText={<span>{errorMessage}</span>}
          />
        </div>
      </ClickAwayListener>
    </>
  );
}
